import React from "react";
import profilePic from "../../assets/image/profilePic.png";
import MediaSlider from "../Tepars/MediaSlider";
import { useSelector } from "react-redux";

import "../../assets/css/socialMediaAdvertisementPost.scss";

function SocialMediaAdevertisementPost() {
  const theme = useSelector((state) => state.theme);

  return (
    <div className="post-wrapper">
      <article className="advertisementPost">
        <div className="header">
          <div className="profile">
            <div className="profile-image" tabIndex="0">
              <img
                className="profile-avatar"
                src={profilePic}
                alt="Profile Avatar"
              />
            </div>

            <div className="profile-info">
              <div className="profile-info-wrapper">
                <div className="profile-info-name">
                  <span className="name" tabIndex="0">
                    Facebook Advertiser
                  </span>
                </div>

                <div className="profile-details">
                  <p className="profile-details-type">Sponsored</p>

                  {/* For Google */}
                  {/* <svg width="21" height="16" viewBox="0 0 21 16" fill="none">
                    <rect
                      x="1"
                      y="1"
                      width="19"
                      height="14"
                      rx="1.5"
                      fill="#FCB41C"
                    />
                    <path
                      d="M7.14844 4.22461L4.32422 12H3.16992L6.42188 3.46875H7.16602L7.14844 4.22461ZM9.51562 12L6.68555 4.22461L6.66797 3.46875H7.41211L10.6758 12H9.51562ZM9.36914 8.8418V9.76758H4.57617V8.8418H9.36914ZM15.6504 10.7695V3H16.7402V12H15.7441L15.6504 10.7695ZM11.3848 8.90039V8.77734C11.3848 8.29297 11.4434 7.85352 11.5605 7.45898C11.6816 7.06055 11.8516 6.71875 12.0703 6.43359C12.293 6.14844 12.5566 5.92969 12.8613 5.77734C13.1699 5.62109 13.5137 5.54297 13.8926 5.54297C14.291 5.54297 14.6387 5.61328 14.9355 5.75391C15.2363 5.89062 15.4902 6.0918 15.6973 6.35742C15.9082 6.61914 16.0742 6.93555 16.1953 7.30664C16.3164 7.67773 16.4004 8.09766 16.4473 8.56641V9.10547C16.4043 9.57031 16.3203 9.98828 16.1953 10.3594C16.0742 10.7305 15.9082 11.0469 15.6973 11.3086C15.4902 11.5703 15.2363 11.7715 14.9355 11.9121C14.6348 12.0488 14.2832 12.1172 13.8809 12.1172C13.5098 12.1172 13.1699 12.0371 12.8613 11.877C12.5566 11.7168 12.293 11.4922 12.0703 11.2031C11.8516 10.9141 11.6816 10.5742 11.5605 10.1836C11.4434 9.78906 11.3848 9.36133 11.3848 8.90039ZM12.4746 8.77734V8.90039C12.4746 9.2168 12.5059 9.51367 12.5684 9.79102C12.6348 10.0684 12.7363 10.3125 12.873 10.5234C13.0098 10.7344 13.1836 10.9004 13.3945 11.0215C13.6055 11.1387 13.8574 11.1973 14.1504 11.1973C14.5098 11.1973 14.8047 11.1211 15.0352 10.9688C15.2695 10.8164 15.457 10.6152 15.5977 10.3652C15.7383 10.1152 15.8477 9.84375 15.9258 9.55078V8.13867C15.8789 7.92383 15.8105 7.7168 15.7207 7.51758C15.6348 7.31445 15.5215 7.13477 15.3809 6.97852C15.2441 6.81836 15.0742 6.69141 14.8711 6.59766C14.6719 6.50391 14.4355 6.45703 14.1621 6.45703C13.8652 6.45703 13.6094 6.51953 13.3945 6.64453C13.1836 6.76562 13.0098 6.93359 12.873 7.14844C12.7363 7.35938 12.6348 7.60547 12.5684 7.88672C12.5059 8.16406 12.4746 8.46094 12.4746 8.77734Z"
                      fill="white"
                    />
                  </svg> */}
                </div>
              </div>

              {/* For Facebook */}
              <div className="advertisement-cta">
                <a
                  href="https://tepnot.com/sponsored-guidelines/"
                  target="blank"
                >
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <g clip-path="url(#clip0_7686_2262)">
                      <path
                        d="M10.0028 5.63839C9.41106 5.63839 8.93136 6.11809 8.93136 6.70982C8.93136 7.30156 9.41106 7.78125 10.0028 7.78125C10.5945 7.78125 11.0742 7.30156 11.0742 6.70982C11.0742 6.11809 10.5945 5.63839 10.0028 5.63839Z"
                        fill="#838383"
                      />
                      <path
                        d="M11.0742 16.3594H8.93136V8.85937H11.0742V16.3594Z"
                        fill="#838383"
                      />
                      <circle
                        cx="10"
                        cy="11"
                        r="9.0625"
                        stroke="#838383"
                        stroke-width="1.875"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7686_2262">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>

                <a
                  href="https://tepnot.com/sponsored-guidelines/"
                  target="blank"
                >
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <g clip-path="url(#clip0_7686_2262)">
                      <path
                        d="M10.0028 5.63839C9.41106 5.63839 8.93136 6.11809 8.93136 6.70982C8.93136 7.30156 9.41106 7.78125 10.0028 7.78125C10.5945 7.78125 11.0742 7.30156 11.0742 6.70982C11.0742 6.11809 10.5945 5.63839 10.0028 5.63839Z"
                        fill="#838383"
                      />
                      <path
                        d="M11.0742 16.3594H8.93136V8.85937H11.0742V16.3594Z"
                        fill="#838383"
                      />
                      <circle
                        cx="10"
                        cy="11"
                        r="9.0625"
                        stroke="#838383"
                        stroke-width="1.875"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7686_2262">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="post-info-wrapper">
          <div className="post-info">
            <p className="info">Your ad integration works. Woohoo!</p>
          </div>

          {/* Call MediaSlider here */}
          <div className="post-media-wrapper">
            <img
              src="https://study.com/cimages/multimages/16/burgerad15179945781952220614.png"
              alt="lyken hypersport"
            />
          </div>

          <div className="post-media-cta">
            <button className="btn btn-clickHere">Install Now</button>
          </div>
        </div>
      </article>
    </div>
  );
}

export default SocialMediaAdevertisementPost;
