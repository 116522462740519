import React from "react";
import { useSelector } from "react-redux";

import "../../assets/css/socialMediaAdvertisementPost.scss";

function DefaultAdvertisementPost({
  setPostMenu,
  setSelectedPost,
  advertisement,
}) {
  const theme = useSelector((state) => state.theme);

  const handleButtonClick = () => {
    if (advertisement?.link) {
      window.open(advertisement.link, "_blank");
    }
  };

  return (
    <div className="post-wrapper">
      <article className="post advertisementPost">
        <div className="header">
          <div className="profile">
            <div className="profile-image" tabIndex="0">
              <img
                className="profile-avatar"
                src={advertisement?.profilePhoto}
                alt="Profile Avatar"
              />
            </div>

            <div className="profile-info">
              <div className="profile-info-wrapper">
                <div className="profile-info-name">
                  <span className="name" tabIndex="0">
                    {advertisement?.title}
                  </span>
                </div>

                <div className="profile-details">
                  <p className="profile-details-time">Sponcered</p>
                </div>
              </div>

              <button
                className="btn profile-cta"
                title="More options"
                onClick={() => {
                  setPostMenu(true);
                  setSelectedPost(advertisement);
                }}
              >
                <svg width="6" height="16" viewBox="0 0 6 24" fill="none">
                  <path
                    d="M3 0.75C1.625 0.75 0.5 1.875 0.5 3.25C0.5 4.625 1.625 5.75 3 5.75C4.375 5.75 5.5 4.625 5.5 3.25C5.5 1.875 4.375 0.75 3 0.75ZM3 18.25C1.625 18.25 0.5 19.375 0.5 20.75C0.5 22.125 1.625 23.25 3 23.25C4.375 23.25 5.5 22.125 5.5 20.75C5.5 19.375 4.375 18.25 3 18.25ZM3 9.5C1.625 9.5 0.5 10.625 0.5 12C0.5 13.375 1.625 14.5 3 14.5C4.375 14.5 5.5 13.375 5.5 12C5.5 10.625 4.375 9.5 3 9.5Z"
                    fill={
                      theme === "Light"
                        ? "#2a2a2a"
                        : theme === "Dark"
                        ? "#ffffff"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? "#2a2a2a"
                        : "#ffffff"
                    }
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="post-info-wrapper">
          <div className="post-info">
            <p className="info">{advertisement.description}</p>
          </div>

          {/* Call MediaSlider here */}
          <div>
            <img src={advertisement?.banner} alt="chainsaw" />
          </div>

          <div className="post-media-cta">
            <button className="btn btn-clickHere" onClick={handleButtonClick}>
              {advertisement?.buttonTitle}
            </button>
          </div>
        </div>
      </article>
    </div>
  );
}

export default DefaultAdvertisementPost;
