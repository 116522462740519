import React, { useEffect, useRef, useState } from "react";
import Tags from "./Tags";
import ProfileMenu from "./ProfileMenu";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import TakeProfilePhoto from "./TakeProfilePhoto";
import ProfileAvatarView from "./ProfileAvatarView";
import AdjustProfileImage from "./AdjustProfileImage";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import {
  handleToast,
  setSelfContact,
  setSettingOptions,
  setSelectedSetting,
  setLeftRightSide,
} from "../../Redux/actions";
import "../../assets/css/updateProfile.scss";

// Import crypto-js
import CryptoJS from "crypto-js";

function UpdateProfile({
  setAskPermission,
  setcloseAlert,
  setCloseCamera,
  setlocalstream,
  localstream,
}) {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const selfUser = useSelector((state) => state.selfContact);
  const animationDirection = useSelector((state) => state.animationDirection);

  const descMaxLength = 130;
  const nameMaxLength = 25;
  const phoneNumber = `${selfUser?.countryCode ?? ""} ${selfUser?.phone ?? ""}`;

  const [link, setLink] = useState(selfUser?.link);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [profileTag, setProfileTag] = useState("");
  const [checked, setChecked] = useState(selfUser?.tags);
  const [tempChecked, setTempChecked] = useState(selfUser?.tags);
  const [profilePhoto, setProfilePhoto] = useState();
  const [profileMenu, setProfileMenu] = useState(false);
  const [userName, setUserName] = useState(selfUser?.name);
  const [settingProfile, setSettingProfile] = useState(true);
  const [profilePhotoMenu, setProfilePhotoMenu] = useState(null);
  const [takeOrUploadPhoto, setTakeOrUploadPhoto] = useState(null);
  const [description, setDescription] = useState(selfUser?.description);
  const [haveCamaraPermissions, setHaveCamaraPermissions] = useState({});
  const [isNameEmpty, setIsNameEmpty] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const escapeMenu = (e) => {
    if (e.key === "Escape") {
      setProfileMenu(false);
    }
  };

  const secretKey = "secretKey123secretKey"; // Replace with your secure secret key

  // const encryptData = (data) => {
  //   return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  // };

  // const decryptData = (encryptedData) => {
  //   const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  //   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // };

  // Function to encrypt data with an IV
  // const encryptData = (data) => {
  //   const iv = CryptoJS.lib.WordArray.random(16); // Generate a random 16-byte IV
  //   const encryptedData = CryptoJS.AES.encrypt(
  //     JSON.stringify(data),
  //     secretKey,
  //     { iv }
  //   ).toString();

  //   // Combine the IV and the encrypted data to store/sent together
  //   const encryptedDataWithIV = {
  //     iv: iv.toString(CryptoJS.enc.Base64), // Convert IV to Base64 for easy storage
  //     encryptedData,
  //   };

  //   return encryptedDataWithIV;
  // };

  // // Function to decrypt data with an IV
  // const decryptData = (encryptedDataWithIV) => {
  //   const { iv, encryptedData } = encryptedDataWithIV;

  //   const ivParsed = CryptoJS.enc.Base64.parse(iv); // Parse the Base64 encoded IV
  //   const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey, {
  //     iv: ivParsed,
  //   });

  //   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); // Decrypt and parse the result
  // };

  // function encryptData(data) {
  //   const iv = CryptoJS.lib.WordArray.random(16);
  //   const encrypted = CryptoJS.AES.encrypt(
  //     data,
  //     CryptoJS.enc.Utf8.parse(encryptionKey),
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   return (
  //     iv.toString(CryptoJS.enc.Hex) +
  //     ":" +
  //     encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  //   );
  // }

  const encryptionKey = "yTSTh8NrzIbLdvkhxU8LayeajKOYQ1Wh"; // 32 bytes key
  const decryptionKey = "xLYjv0gI0NQwRipYqkYuhCQGp6aMvdsS"; // 32 bytes key

  function encryptData(data) {
    try {
      if (typeof data !== "string") {
        data = JSON.stringify(data); // Ensure data is a string
      }

      const iv = CryptoJS.lib.WordArray.random(16);
      const encrypted = CryptoJS.AES.encrypt(
        data,
        CryptoJS.enc.Utf8.parse(encryptionKey),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return (
        iv.toString(CryptoJS.enc.Hex) +
        "@" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    } catch (error) {
      console.error("Error encrypting data:", error);
      throw error; // Re-throw to handle the error in the calling function
    }
  }

  function decryptData(data) {
    try {
      const [ivHex, encryptedData] = data.split("@");
      const iv = CryptoJS.enc.Hex.parse(ivHex);
      const encrypted = CryptoJS.enc.Hex.parse(encryptedData);
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encrypted },
        CryptoJS.enc.Utf8.parse(decryptionKey),
        { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
      );
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedString); // Parse JSON string to object
    } catch (error) {
      console.error("Error decrypting data:", error);
      throw error;
    }
  }

  const updateUser = (profile) => {
    const bufferingTime = new Date(Date.now() + 10000).toISOString();
    setLoading(true);
    const data = {
      name: userName,
      description,
      tags: checked,
      link: link,
      profile,
      bufferingTime: bufferingTime,
    };

    const encryptedData = encryptData(data);

    axiosRequest
      .put(`user`, { JjqNhTO1: encryptedData })
      .then((res) => {
        const decryptedResponse = decryptData(res?.data?.success); // Assuming API returns encrypted data

        const updatedUser = {
          post: selfUser?.post,
          Teps: selfUser?.Teps,
          ...decryptedResponse?.data,
        };
        dispatch(setLeftRightSide("open-left"));
        setIsOpen(false);
        dispatch(setSelfContact(updatedUser));

        setLoading(false);
        setTimeout(() => {
          dispatch(setSelectedSetting(""));
        }, 250);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
      });
  };

  const handlebackButton = () => {
    setIsOpen(false);
    dispatch(setLeftRightSide("open-left"));
    setTimeout(() => {
      dispatch(setSelectedSetting(""));
    }, 250);
  };

  const tagOpen = () => {
    setProfileTag("tag");
    setSettingProfile(false);
    dispatch(setSettingOptions(false));
  };

  const handleChange = (input, e) => {
    const inputText = e.target.value;
    if (input === "name") {
      setUserName(inputText);
      setIsNameEmpty(inputText.trim() === "");
      if (inputText.length <= descMaxLength) setUserName(inputText);
    } else if (input === "description") {
      setDescription(inputText.length);
      if (inputText.length <= descMaxLength) setDescription(inputText);
    }
  };

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event?.target)) {
      setProfileMenu(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
      setProfileMenu(false);
      closeDropdown();
    }
  };

  useEffect(() => {
    if (profileMenu) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [profileMenu]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);

  const handleTagsUpdate = (newTags) => {
    setChecked(newTags);
    setTempChecked(newTags);
  };

  return (
    <>
      {settingProfile === true && (
        <div className={`updateProfile ${isOpen ? "open" : "close"}`}>
          <div className="updateProfile-wrapper">
            <header className="header">
              <div className="back">
                <div
                  className="btn btn-back"
                  aria-label="Back"
                  role="button"
                  tabIndex="0"
                  onClick={() => handlebackButton()}
                >
                  <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                    <title>Back</title>
                    <g id="Group 18522">
                      <g id="Group 18522_2">
                        <path
                          id="Vector"
                          d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                          stroke="white"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="text">Profile</span>
              </div>
              <div
                className={`done ${isNameEmpty ? "disabled" : ""}`}
                tabIndex="1"
                onClick={() => {
                  if (!isNameEmpty) updateUser();
                }}
                style={{ pointerEvents: isNameEmpty ? "none" : "auto" }}
              >
                <span className="text">Done</span>
              </div>
            </header>
            <div className="updateProfile-info">
              <div className="profile-image-change">
                <div className="profile-change">
                  <img
                    src={getUserProfilePic(selfUser?.profile)}
                    alt="profile avatar"
                  />
                  <button
                    className="edit-image"
                    aria-label="Change profile image"
                    tabIndex="0"
                    onClick={() => setProfileMenu(true)}
                    onKeyDown={escapeMenu}
                  >
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                      <title>Change Profile Image</title>
                      <g clipPath="url(#clip0_311_7635)">
                        <path
                          d="M7.49346 14.9042H16.4265V16.4238H2.75V13.2L10.2721 5.6779L13.4951 8.90251L7.4927 14.9042H7.49346ZM11.3457 4.6043L12.958 2.99199C13.1004 2.84955 13.2937 2.76953 13.4951 2.76953C13.6966 2.76953 13.8898 2.84955 14.0323 2.99199L16.1818 5.14148C16.3243 5.28396 16.4043 5.47719 16.4043 5.67866C16.4043 5.88013 16.3243 6.07336 16.1818 6.21584L14.5695 7.82738L11.3464 4.6043H11.3457Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_311_7635">
                          <rect
                            width="18.2353"
                            height="18.2353"
                            fill="white"
                            transform="translate(0.46875 0.46875)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
                {profileMenu && (
                  <ProfileMenu
                    ddref={dropdownRef}
                    user={selfUser}
                    updateUser={updateUser}
                    setcloseAlert={setcloseAlert}
                    setCloseCamera={setCloseCamera}
                    setlocalstream={setlocalstream}
                    setProfileMenu={setProfileMenu}
                    setProfilePhoto={setProfilePhoto}
                    setTakeOrUploadPhoto={setTakeOrUploadPhoto}
                    setAskPermission={setAskPermission}
                    setProfilePhotoMenu={setProfilePhotoMenu}
                    setHaveCamaraPermissions={setHaveCamaraPermissions}
                  />
                )}
              </div>
              <div className="updateProfile-details">
                <div className="name-details">
                  <label htmlFor="name">Name</label>
                  <div className="name-container">
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={userName}
                      maxLength={nameMaxLength}
                      onChange={(e) => handleChange("name", e)}
                      onKeyDown={handleKeyDown}
                      aria-describedby="editInstructions"
                    />
                    <div className="letters-allowed">
                      <span>
                        {userName.length}/{nameMaxLength}
                      </span>
                    </div>
                  </div>
                  {isNameEmpty && (
                    <p className="error">Your profile name is Empty</p>
                  )}
                  <p className="name-details-note">
                    This is not your username or pin. This name will be visible
                    to your Tepnot Contacts.
                  </p>
                </div>
                <div className="about-details">
                  <label htmlFor="about">Bio</label>
                  <div className="about-details-info">
                    <textarea
                      id="about"
                      name="about"
                      placeholder="Add bio here"
                      value={description}
                      maxLength={descMaxLength}
                      onChange={(e) => handleChange("description", e)}
                    />
                    <div className="letters-allowed">
                      <span>
                        {description.length}/{descMaxLength}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="tags-details" onClick={() => tagOpen()}>
                  <span className="tag-heading">Tags</span>
                  <div className="tags-container">
                    <span className="tag-name">
                      {checked ? checked : "Select tag"}
                    </span>
                    <div className="more" tabIndex="0">
                      <svg
                        width="8"
                        height="15"
                        viewBox="0 0 10 16"
                        fill="none"
                      >
                        <title>Click to See More Tags</title>
                        <path
                          id="Vector"
                          d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                          fill="#C5C5C5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="link-details">
                  <label htmlFor="link">Link</label>
                  <div className="link-container">
                    <input
                      id="link"
                      type="text"
                      name="link"
                      placeholder="Add link here"
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      onKeyDown={handleKeyDown}
                      aria-describedby="editInstructions"
                    />
                  </div>
                </div>
                <div className="phone-details">
                  <label htmlFor="phoneNumber">Phone</label>
                  <div className="phone-container">
                    <input
                      type="tel"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={phoneNumber}
                      readOnly
                    />
                  </div>
                </div>

                {/* <div className="birthDate-details">
                  <label htmlFor="birthDate">Birthdate</label>
                  <div className="birthDate-container">
                    <input
                      type="tel"
                      name="birthDate"
                      id="birthDate"
                      value="12/11/1999"
                      readOnly
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {profileTag === "tag" && (
        <Tags
          setSettingProfile={setSettingProfile}
          setProfileTag={setProfileTag}
          initialChecked={tempChecked}
          onTagsUpdate={handleTagsUpdate}
        />
      )}
      {profilePhotoMenu === "view" && (
        <ProfileAvatarView
          user={selfUser}
          close={() => setProfilePhotoMenu(false)}
        />
      )}
      {profilePhotoMenu === "take" && haveCamaraPermissions === true && (
        <TakeProfilePhoto
          localstream={localstream}
          setProfilePhoto={setProfilePhoto}
          setTakeOrUploadPhoto={setTakeOrUploadPhoto}
          setProfilePhotoMenu={setProfilePhotoMenu}
        />
      )}
      {profilePhotoMenu === "adjust" && (
        <AdjustProfileImage
          updateUser={updateUser}
          profilePhoto={profilePhoto}
          setProfilePhoto={setProfilePhoto}
          setlocalstream={setlocalstream}
          setProfilePhotoMenu={setProfilePhotoMenu}
          takeOrUploadPhoto={takeOrUploadPhoto}
          setTakeOrUploadPhoto={setTakeOrUploadPhoto}
          setHaveCamaraPermissions={setHaveCamaraPermissions}
        />
      )}
    </>
  );
}

export default UpdateProfile;
