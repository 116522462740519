import React, { useEffect, useRef, useState } from "react";
import {
  setSettings,
  handleToast,
  setSelectedSetting,
  setExceptContactType,
} from "../../../Redux/actions";
import Spinner from "../../Common/Spinner";
import { ERROR } from "../../../Utils/enums";
import axiosRequest from "../../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfilePic } from "../../../Utils/helpers";
import "../../../assets/css/exceptContact.scss";

function ExceptContacts() {
  const containerRef = useRef(null);
  const searchInputRef = useRef(null);

  const dispatch = useDispatch();

  const userId = localStorage.getItem("user_id");
  const theme = useSelector((state) => state.theme);

  const settings = useSelector((state) => state.settings);
  const exceptContactType = useSelector((state) => state.exceptContactType);

  const exceptContact =
    exceptContactType === "lastseen"
      ? settings?.LastContactExcept
      : exceptContactType === "profile"
      ? settings?.ProfileContactExcept
      : "";

  const [isMounted, setIsMounted] = useState(true);
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTotalPages, setSearchTotalPages] = useState(0);
  const [count, setCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [appendContacts, setAppendContacts] = useState([]);
  const [selectedContactIds, setSelectedContactIds] = useState(new Set());
  const [localChanges, setLocalChanges] = useState(new Map());
  const [allContactsLoaded, setAllContactsLoaded] = useState(false);
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [masterContactList, setMasterContactList] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleBackButton = () => {
    if (exceptContactType === "lastseen") {
      dispatch(setSelectedSetting("lastseenandonline"));
    } else if (exceptContactType === "profile") {
      dispatch(setSelectedSetting("profilephoto"));
    } else {
      dispatch(setSelectedSetting("privacy"));
    }
    setIsMounted(false);
  };

  useEffect(() => {
    if (contacts) {
      setAppendContacts(contacts);
    }
  }, [contacts]);

  useEffect(() => {
    const initialSelectedIds = new Set(
      exceptContact.map((contact) => contact._id)
    );
    setSelectedContactIds(initialSelectedIds);
    setSelectedContacts(exceptContact);
  }, [exceptContact]);

  const fetchContacts = (pageNumber, isSearch = false) => {
    setLoading(true);
    const endpoint = isSearch
      ? `user/contact?search=${searchValue}&page=${pageNumber}`
      : `user/contact?page=${pageNumber}`;

    axiosRequest
      .get(endpoint)
      .then((res) => {
        setCount(res?.data?.totalCount);
        let fetchedContacts = res?.data?.data
          ?.map((contact) => ({
            ...contact,
            isChecked: localChanges.has(contact._id)
              ? localChanges.get(contact._id)
              : selectedContactIds.has(contact._id) ||
                exceptContact.some(
                  (contactExcept) => contactExcept?._id === contact?._id
                ),
          }))
          .filter(
            (contact) =>
              contact?.userType !== "Admin" && contact?._id !== userId
          );

        if (isSearch) {
          setSearchTotalPages(res?.data?.totalPages);
          setSearchedContacts((prevContacts) =>
            pageNumber === 1
              ? fetchedContacts
              : [...prevContacts, ...fetchedContacts]
          );
          setSearchPage(pageNumber + 1);
        } else {
          setTotalCount(res?.data?.totalCount);
          setTotalPages(res?.data?.totalPages);
          setMasterContactList((prevContacts) => [
            ...prevContacts,
            ...fetchedContacts,
          ]);
          setContacts((prevContacts) => [...prevContacts, ...fetchedContacts]);
          setPage(pageNumber + 1);
        }

        if (pageNumber >= res?.data?.totalPages) {
          setAllContactsLoaded(true);
        }

        setLoading(false);
        setSearchLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSearchLoading(false);
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  useEffect(() => {
    fetchContacts(1);
  }, []);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      count !== appendContacts.length
    ) {
      if (searchValue) {
        if (searchPage <= searchTotalPages) {
          fetchContacts(searchPage, true);
        }
      } else {
        if (page <= totalPages) {
          fetchContacts(page);
        }
      }
    }
  };

  useEffect(() => {
    if (searchValue !== "") {
      var timer = setTimeout(() => {
        setSearchLoading(true);
        setSearchPage(1);
        fetchContacts(1, true);
        setIsSearchActive(true);

        if (containerRef.current) {
          containerRef.current.scrollTo(0, 0);
        }
      }, 1000);
    } else {
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
      setSearchLoading(false);
      setIsSearchActive(false);
      setSearchedContacts([]);
      setSearchPage(1);
      setContacts(masterContactList);
      setAppendContacts(masterContactList);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue]);

  const handleSearchInputChange = (e) => {
    const keyword = e.target.value;
    setSearchValue(keyword);
    setSearchLoading(true);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setIsSearchActive(false);
    setSearchedContacts([]);
    setSearchPage(1);
    setContacts(masterContactList);
    setAppendContacts(masterContactList);

    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
    searchInputRef.current.focus();
  };

  const handleCheckmarkClick = (e, selectedContact) => {
    const newIsChecked = !selectedContact.isChecked;

    setLocalChanges((prevChanges) => {
      const newChanges = new Map(prevChanges);
      newChanges.set(selectedContact._id, newIsChecked);
      return newChanges;
    });

    setSelectedContactIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(selectedContact._id)) {
        newIds.delete(selectedContact._id);
      } else {
        newIds.add(selectedContact._id);
      }
      return newIds;
    });

    const updateContacts = (contactsList) =>
      contactsList.map((contact) =>
        contact._id === selectedContact._id
          ? { ...contact, isChecked: newIsChecked }
          : contact
      );

    if (isSearchActive) {
      setSearchedContacts(updateContacts);
    } else {
      setContacts(updateContacts);
    }
    setMasterContactList(updateContacts(masterContactList));

    setSelectedContacts((prevSelected) => {
      if (newIsChecked) {
        return [...prevSelected, selectedContact];
      } else {
        return prevSelected.filter(
          (contact) => contact._id !== selectedContact._id
        );
      }
    });
  };

  const removeContact = (contactId) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.filter((contact) => contact._id !== contactId)
    );
    setSelectedContactIds((prevIds) => {
      const newIds = new Set(prevIds);
      newIds.delete(contactId);
      return newIds;
    });
    setLocalChanges((prevChanges) => {
      const newChanges = new Map(prevChanges);
      newChanges.set(contactId, false);
      return newChanges;
    });

    const updateContacts = (contactsList) =>
      contactsList.map((contact) =>
        contact._id === contactId ? { ...contact, isChecked: false } : contact
      );

    setContacts(updateContacts);
    setSearchedContacts(updateContacts);
    setMasterContactList(updateContacts(masterContactList));
  };

  const loadAllContacts = () => {
    if (!allContactsLoaded) {
      let currentPage = page;
      while (currentPage <= totalPages) {
        fetchContacts(currentPage);
        currentPage++;
      }
    }
  };

  const contactExcept = () => {
    loadAllContacts();

    const ExceptContact = [...selectedContactIds];
    const Ids = ExceptContact.map((id) => id.toString());
    const key =
      exceptContactType === "lastseen"
        ? "LastContactExcept"
        : exceptContactType === "profile"
        ? "ProfileContactExcept"
        : "";
    const data = { [key]: Ids };

    axiosRequest
      .put("user/setting/privacycontactexcept", data)
      .then((response) => {
        setIsMounted(false);
        dispatch(setExceptContactType(""));
        if (exceptContactType === "lastseen") {
          dispatch(setSelectedSetting("lastseenandonline"));
        } else if (exceptContactType === "profile") {
          dispatch(setSelectedSetting("profilephoto"));
        } else {
          dispatch(setSelectedSetting("privacy"));
        }
        dispatch(setSettings(response?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };
  return (
    <div className="exceptContact">
      <div className="exceptContact-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Select Contact</span>
          </div>

          <div className="done" tabIndex="1" onClick={() => contactExcept()}>
            <span className="text">Done</span>
          </div>
        </header>

        <div className="shareContact-wrapper">
          <div className="shareContact-selected">
            <span className="selected-number">
              {selectedContactIds.size} of {totalCount} selected
            </span>
          </div>

          <div className="shareContact-header">
            <div className="search-bar">
              <div className="search-bar-wrapper">
                <svg width="12" height="12" viewBox="0 0 18 18" fill="none">
                  <title>Search</title>
                  <path
                    id="Vector"
                    d="M14.2049 12.952L18 16.7462L16.7462 18L12.952 14.2049C11.5402 15.3366 9.78419 15.9522 7.9748 15.9496C3.57271 15.9496 0 12.3769 0 7.9748C0 3.57271 3.57271 0 7.9748 0C12.3769 0 15.9496 3.57271 15.9496 7.9748C15.9522 9.78419 15.3366 11.5402 14.2049 12.952ZM12.4274 12.2945C13.5519 11.138 14.18 9.58786 14.1774 7.9748C14.1774 4.54741 11.4013 1.77218 7.9748 1.77218C4.54741 1.77218 1.77218 4.54741 1.77218 7.9748C1.77218 11.4013 4.54741 14.1774 7.9748 14.1774C9.58786 14.18 11.138 13.5519 12.2945 12.4274L12.4274 12.2945Z"
                    fill="#999999"
                  />
                </svg>

                <input
                  type="search"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearchInputChange}
                  ref={searchInputRef}
                />
                {searchValue.length > 0 && (
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 25 25"
                    fill="none"
                    style={{
                      overflow: "visible",
                      pointerEvents: "auto",
                      cursor: "pointer",
                    }}
                    onClick={handleClearSearch}
                  >
                    <title>Close</title>
                    <g id="iconoir:cancel">
                      <path
                        id="Vector"
                        d="M3.75 22.5L13.125 13.125L22.5 22.5M22.5 3.75L13.1232 13.125L3.75 3.75"
                        stroke="#999999"
                        strokeWidth="2.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                )}
              </div>
            </div>
            {selectedContacts && selectedContacts?.length > 0 && (
              <div className="selected-contacts-wrapper">
                <div className="selected-contacts">
                  {selectedContacts?.map((contact, index) => (
                    <div key={index} className="selected-contact-wrapper">
                      <div className="selected-contact">
                        <img
                          src={getUserProfilePic(contact?.profile)}
                          alt={contact?.name}
                        />
                        <div
                          className="delete"
                          onClick={() => removeContact(contact?._id)}
                        >
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <title>Unselect contact</title>
                            <g id="Frame">
                              <g clipPath="url(#clip0_974_4249)">
                                <path
                                  id="Vector"
                                  d="M9.00665 7.98493L12.0374 4.95422C12.3163 4.67531 12.7685 4.67531 13.0474 4.95422C13.3263 5.23312 13.3263 5.68531 13.0474 5.96422L10.0167 8.99493L13.0474 12.0256C13.3263 12.3046 13.3263 12.7567 13.0474 13.0356C12.7685 13.3146 12.3163 13.3146 12.0374 13.0356L9.00665 10.0049L5.97594 13.0356C5.69703 13.3146 5.24484 13.3146 4.96594 13.0356C4.68703 12.7567 4.68703 12.3046 4.96594 12.0256L7.99665 8.99493L4.96594 5.96422C4.68703 5.68531 4.68703 5.23312 4.96594 4.95422C5.24484 4.67531 5.69703 4.67531 5.97594 4.95422L9.00665 7.98493Z"
                                  fill="#8E8E93"
                                />
                              </g>
                            </g>
                            <defs>
                              <clipPath id="clip0_974_4249">
                                <rect
                                  x="0.4375"
                                  y="0.421875"
                                  width="17.1429"
                                  height="17.1429"
                                  fill="white"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <span className="selected-contact-name ">
                        {contact?.name?.length > 9
                          ? `${contact.name.substring(0, 9)}...`
                          : contact?.name}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {searchLoading && <Spinner />}
          <div
            ref={containerRef}
            onScroll={handleScroll}
            className="shareContact-list"
          >
            {loading && page === 1 ? (
              <div className="no-data">
                <Spinner />
              </div>
            ) : isSearchActive ? (
              searchedContacts && searchedContacts.length > 0 ? (
                searchedContacts?.map((contact, i) => (
                  <div
                    key={i}
                    className="profile"
                    tabIndex="0"
                    onClick={(e) => handleCheckmarkClick(e, contact)}
                  >
                    <div className="profile-image">
                      <img
                        className="profile-avatar"
                        src={getUserProfilePic(contact?.profile)}
                        alt="Profile Avatar"
                      />
                    </div>
                    <div className="profile-info-container">
                      <div className="profile-info">
                        <span className="name">{contact?.name}</span>
                        <p className="profile-status-info">
                          {contact?.description}
                        </p>
                      </div>
                      <div className="group-check">
                        <input
                          type="checkbox"
                          id={`select-contact-${i}`}
                          aria-label="Check this box"
                          checked={contact?.isChecked}
                          // onChange={(e) => handleCheckmarkClick(e, contact)}
                          readOnly
                        />
                        <span
                          className="checkmark"
                          checked={contact?.isChecked}
                          // onClick={(e) => handleCheckmarkClick(e, contact)}
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                searchValue &&
                !searchLoading && (
                  <div className="no-contact-waraper">
                    <div className="no-contact">
                      <span className="text">No contacts found</span>
                    </div>
                  </div>
                )
              )
            ) : contacts && contacts.length > 0 ? (
              contacts?.map((contact, i) => (
                <div
                  key={i}
                  className="profile"
                  tabIndex="0"
                  onClick={(e) => handleCheckmarkClick(e, contact)}
                >
                  <div className="profile-image">
                    <img
                      className="profile-avatar"
                      src={getUserProfilePic(contact?.profile)}
                      alt="Profile Avatar"
                    />
                  </div>
                  <div className="profile-info-container">
                    <div className="profile-info">
                      <span className="name">{contact?.name}</span>
                      <p className="profile-status-info">
                        {contact?.description}
                      </p>
                    </div>
                    <div className="group-check">
                      <input
                        type="checkbox"
                        id={`select-contact-${i}`}
                        aria-label="Check this box"
                        checked={contact?.isChecked}
                        // onChange={(e) => handleCheckmarkClick(e, contact)}
                        readOnly
                      />
                      <span
                        className="checkmark"
                        checked={contact?.isChecked}
                        // onClick={(e) => handleCheckmarkClick(e, contact)}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-contact-waraper">
                <div className="no-contact">
                  <div className="no-contact-img">
                    <svg width="45" height="45" viewBox="0 0 56 56" fill="none">
                      <g clipPath="url(#clip0_2399_591)">
                        <path
                          d="M7.02828 51.7659L7.03442 51.7351L7.03861 51.704C8.52261 40.6773 18.0238 32.6176 29.0238 32.6176C40.0019 32.6176 49.4872 40.6453 51 51.6383V51.8824C51 52.5916 50.7688 53.1044 50.4376 53.437C50.1068 53.7692 49.5981 54 48.8951 54H9.15244H8.84196C8.26736 54 7.76952 53.716 7.42527 53.264C7.07121 52.7991 6.93616 52.2285 7.02828 51.7659ZM40.4434 14.4706C40.4434 20.781 35.2984 25.9412 29.0238 25.9412C22.7491 25.9412 17.6042 20.781 17.6042 14.4706C17.6042 8.16018 22.7491 3 29.0238 3C35.2984 3 40.4434 8.16018 40.4434 14.4706Z"
                          stroke={
                            theme === "Light"
                              ? "#2A2A2A"
                              : theme === "Dark"
                              ? "#ffffff"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#2A2A2A"
                              : "#ffffff"
                          }
                          strokeWidth="2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2399_591">
                          <rect width="56" height="56" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <span className="text">No contact yet</span>
                </div>
              </div>
            )}
            {!searchLoading && loading && page >= 2 && <Spinner />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExceptContacts;
