import { React, useState } from "react";
import Nudity from "./Report/Nudity";
import Suicide from "./Report/Suicide";
import ThankYou from "./Report/ThankYou";
import Violence from "./Report/Violence";
import Bullying from "./Report/Bullying";
import BlockUser from "./Report/BlockUser";
import HateSpeech from "./Report/HateSpeech";
import axiosRequest from "../../Middleware/api";
import Intellectual from "./Report/Intellectual";
import { REPORT_OPTIONS } from "../../Utils/enums";
import SaleOfIllegal from "./Report/SaleOfIllegal";
import { useDispatch, useSelector } from "react-redux";
import EatingDisorders from "./Report/EatingDisorders";
import FalseInformation from "./Report/FalseInformation";
import { setTepars, setUserTepars } from "../../Redux/actions";
import "../../assets/css/reportPost.scss";

function ReportPost({
  post,
  reportMenu,
  setReportMenu,
  isCommentAndReplyId,
  setIsCommentAndReplyId,
  reportCommentAndReply,
  setReportCommentAndReply,
}) {
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const UserTepars = useSelector((state) => state.userTepars);

  const [subReportMenu, setSubReportMenu] = useState(false);
  const [reportBlockModal, setReportBlockModal] = useState(false);
  const [reportThanksModal, setReportThanksModal] = useState(false);
  const [selectedReportOption, setSelectedReportOption] = useState();

  const selectReportOption = (option) => {
    setReportMenu(option);
    if (option === "It's spam") {
      setSubReportMenu(false);
      reportPost(option);
    } else if (option === "Nudity or sexual activity") {
      setSubReportMenu(true);
    } else if (option === "Hate speech or symbols") {
      setSubReportMenu(true);
      setSelectedReportOption(option);
    } else if (option === "Violence or dangerous organizations") {
      setSubReportMenu(true);
    } else if (option === "Sale of illegal or regulated goods") {
      setSubReportMenu(true);
    } else if (option === "Bullying or harassment") {
      setSubReportMenu(true);
      setSelectedReportOption(option);
    } else if (option === "Intellectual property violation") {
      setSubReportMenu(true);
      setSelectedReportOption(option);
    } else if (option === "Suicide or self-injury") {
      setSubReportMenu(true);
      setSelectedReportOption(option);
    } else if (option === "Eating disorders") {
      setSubReportMenu(true);
      setSelectedReportOption(option);
    } else if (option === "Scam or fraud") {
      setSubReportMenu(false);
      reportPost(option);
    } else if (option === "False information") {
      setSubReportMenu(true);
    } else if (option === "I just don't like it") {
      setSubReportMenu(false);
      reportPost(option);
    }
  };

  const handleSubmitReport = () => {
    reportPost(selectedReportOption);
  };

  const getPostId = (item) => {
    return item.post?._id || item._id;
  };

  const reportPost = (type) => {
    if (!reportCommentAndReply) {
      const data = { text: type };
      axiosRequest
        .post(`/report?post=${post?._id}`, data)
        .then((res) => {
          if (res?.data?.status === "200") {
            setSubReportMenu(false);
            setReportThanksModal(true);

            const updatedTepars = Tepars.filter((item) => {
              const itemId = getPostId(item);
              const postId = getPostId(post);
              return itemId !== postId;
            });

            dispatch(setTepars(updatedTepars));

            const updatedUserTepars = UserTepars.filter(
              (tepars) => tepars?._id !== post?._id
            );
            dispatch(setUserTepars(updatedUserTepars));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const data = {
        text: type,
        comment: isCommentAndReplyId,
      };
      axiosRequest
        .post(`/report?comment=${post?._id}`, data)
        .then((res) => {
          if (res?.data?.status === "200") {
            setIsCommentAndReplyId();
            setReportCommentAndReply(false);
            setSubReportMenu(false);
            setReportThanksModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="reportPost">
      <div className="dialog">
        {reportMenu === true && (
          <div className="report-wrapper">
            <div className="heading">
              <div className="hidden" aria-hidden="true" />
              <h4 className="title">Report</h4>
              <button
                className="btn btn-close"
                onClick={() => {
                  isCommentAndReplyId && setIsCommentAndReplyId();
                  setReportMenu(false);
                }}
              >
                <svg width="24" height="24" viewBox="0 0 30 30" fill="none">
                  <title>Close</title>
                  <g id="Frame 2595">
                    <path
                      id="Shape"
                      d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                      fill={
                        theme === "Light"
                          ? "#1891a2"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#1891a2"
                          : "#ffffff"
                      }
                    />
                  </g>
                </svg>
              </button>
            </div>

            <div className="reportPost-info">
              <p className="title">Why are you reporting this post?</p>
              <p className="info">
                Your report is anonymous, except if you're reporting an
                intellectual property infringement. If someone is in immediate
                danger, call the local emergency services … don't wait.
              </p>
            </div>

            <div className="report-reasons-list">
              {REPORT_OPTIONS.map((option, i) => (
                <div
                  tabIndex="0"
                  className="report-reason"
                  onClick={() => selectReportOption(option)}
                >
                  <p className="report-reason-title">{option}</p>
                  <div className="more-info">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {subReportMenu === true && (
          <div className="reportNudity-wrapper">
            <div className="reportNudity">
              <div className="heading">
                <button
                  className="btn btn-back"
                  tabIndex="0"
                  onClick={() => {
                    setReportMenu(true);
                    setSubReportMenu(false);
                  }}
                >
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <title>Back</title>
                    <g id="Frame 2596">
                      <g id="Group 18522">
                        <g id="Group 18522_2">
                          <path
                            id="Vector"
                            d="M14.4805 5L5 14.9996L14.4805 25M25 14.9996H5.3"
                            stroke={
                              theme === "Light"
                                ? "#1891a2"
                                : theme === "Dark"
                                ? "#ffffff"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#1891a2"
                                : "#ffffff"
                            }
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
                <h4 className="title">Report</h4>
                <button
                  className="btn btn-close"
                  onClick={() => setReportMenu(false)}
                >
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <title>Close</title>
                    <g id="Frame 2595">
                      <path
                        id="Shape"
                        d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                        fill={
                          theme === "Light"
                            ? "#1891a2"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#1891a2"
                            : "#ffffff"
                        }
                      />
                    </g>
                  </svg>
                </button>
              </div>
              {reportMenu === "Nudity or sexual activity" && (
                <Nudity
                  selectedReportOption={selectedReportOption}
                  setSelectedReportOption={setSelectedReportOption}
                  SubmitReport={() => handleSubmitReport()}
                />
              )}
              {reportMenu === "Hate speech or symbols" && (
                <HateSpeech SubmitReport={() => handleSubmitReport()} />
              )}
              {reportMenu === "Violence or dangerous organizations" && (
                <Violence
                  selectedReportOption={selectedReportOption}
                  setSelectedReportOption={setSelectedReportOption}
                  SubmitReport={() => handleSubmitReport()}
                />
              )}
              {reportMenu === "Sale of illegal or regulated goods" && (
                <SaleOfIllegal
                  selectedReportOption={selectedReportOption}
                  setSelectedReportOption={setSelectedReportOption}
                  SubmitReport={() => handleSubmitReport()}
                />
              )}
              {reportMenu === "Bullying or harassment" && (
                <Bullying SubmitReport={() => handleSubmitReport()} />
              )}
              {reportMenu === "Intellectual property violation" && (
                <Intellectual SubmitReport={() => handleSubmitReport()} />
              )}
              {reportMenu === "Suicide or self-injury" && (
                <Suicide SubmitReport={() => handleSubmitReport()} />
              )}
              {reportMenu === "Eating disorders" && (
                <EatingDisorders SubmitReport={() => handleSubmitReport()} />
              )}
              {reportMenu === "False information" && (
                <FalseInformation
                  setSelectedReportOption={setSelectedReportOption}
                  SubmitReport={() => handleSubmitReport()}
                />
              )}
            </div>
          </div>
        )}
        {reportThanksModal && (
          <ThankYou
            setReportMenu={setReportMenu}
            setReportBlockModal={setReportBlockModal}
            setReportThanksModal={setReportThanksModal}
          />
        )}
        {reportBlockModal && (
          <BlockUser
            setReportMenu={setReportMenu}
            setReportBlockModal={setReportBlockModal}
          />
        )}
      </div>
    </div>
  );
}

export default ReportPost;
