import React, { useState, useEffect } from "react";
import SearchBox from "./SearchBox";
import { useSelector } from "react-redux";

function SelectMenu({
  selected,
  setSelectedMenu,
  setInboxContacts,
  setSelectedTeparsType,
  isSearchVisible,
  setIsSearchVisible,
  setComingSoon,
}) {
  const theme = useSelector((state) => state.theme);

  const [sliderStyle, setSliderStyle] = useState({});
  const showBadge = useSelector((state) => state.showBadge);

  useEffect(() => {
    const activeElement = document.querySelector(
      ".chat-channel .menu-selected"
    );
    if (activeElement) {
      const position = activeElement.offsetLeft;
      const width = activeElement.offsetWidth;
      setSliderStyle({
        left: position,
        width: width,
        backgroundColor:
          theme === "Light"
            ? "#f5f5f5"
            : theme === "Dark"
            ? "#99d7de"
            : window.matchMedia("(prefers-color-scheme: light)").matches
            ? "#f5f5f5"
            : "#99d7de",
      });
    }
  }, [selected, theme]);

  return (
    <>
      {isSearchVisible && (
        <SearchBox
          setInboxContacts={setInboxContacts}
          setIsSearchVisible={setIsSearchVisible}
        />
      )}

      {!isSearchVisible && (
        <div className="chat-channel">
          <div className="slider" style={sliderStyle} />
          <div
            className={`chat ${selected === "chat" ? "menu-selected" : ""}`}
            onClick={() => {
              setComingSoon(true);
              // setSelectedMenu("chat");
            }}
          >
            <p>Chats</p>
          </div>
          <div
            className={`channel ${
              selected === "tepars" ? "menu-selected" : ""
            }`}
            onClick={() => {
              setSelectedMenu("tepars");
              setSelectedTeparsType(null);
            }}
          >
            {showBadge && <div className="tild"></div>}
            <p>Tepars</p>
          </div>
          <div
            className={`channel ${
              selected === "profile" ? "menu-selected" : ""
            }`}
            onClick={() => {
              setSelectedMenu("profile");
            }}
          >
            <p>Profile</p>
          </div>
        </div>
      )}
    </>
  );
}

export default SelectMenu;
