import React, { useState, useEffect } from "react";
import "../../assets/css/sponsoredCircle.scss";
import { getSponsorProfilePic } from "../../Utils/helpers";
import { ERROR } from "../../Utils/enums";
import { handleToast } from "../../Redux/actions";
import { useDispatch } from "react-redux";
import axiosRequest from "../../Middleware/api";
import Spinner from "../Common/Spinner";

function SponsoredCircle({ sponsoredGroups: initialSponsoredGroups }) {
  const dispatch = useDispatch();

  const [sponsoredGroups, setSponsoredGroups] = useState(
    initialSponsoredGroups
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(2);
  const [loading, setLoading] = useState(false);

  const fetchSponsoredGroups = async (page) => {
    setLoading(true);
    try {
      const sponsorIds = sponsoredGroups.map((group) => group._id);
      const response = await axiosRequest.post(`post/newsponsor?page=${page}`, {
        sponsorId: sponsorIds,
      });

      const { data, totalPages: fetchedTotalPages } = response.data;
      setSponsoredGroups((prevGroups) => [...prevGroups, ...data]);
      setTotalPages(fetchedTotalPages);
      setCurrentPage(page);
    } catch (error) {
      if (error?.response?.status !== 401) {
        dispatch(handleToast(ERROR));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleShowMore = () => {
    if (totalPages > 1 && !loading) {
      fetchSponsoredGroups(currentPage + 1);
    }
  };

  const handleRemoveSponsor = (id) => {
    setSponsoredGroups((prevGroups) =>
      prevGroups.filter((group) => group._id !== id)
    );
  };

  return (
    <>
      {/* {sponsoredGroups?.length > 0 && (
        <div className="sponsoredCircle">
          <div className="sponsoredCircle-wrapper">
            <div className="sponsporedCircle-heading">
              <h3 className="title">Sponsored</h3>

              <div className="sponsoredCircle-info">
                <a
                  href="https://tepnot.com/sponsored-guidelines/"
                  target="blank"
                >
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <g clipPath="url(#clip0_7686_2262)">
                      <path
                        d="M10.0028 5.63839C9.41106 5.63839 8.93136 6.11809 8.93136 6.70982C8.93136 7.30156 9.41106 7.78125 10.0028 7.78125C10.5945 7.78125 11.0742 7.30156 11.0742 6.70982C11.0742 6.11809 10.5945 5.63839 10.0028 5.63839Z"
                        fill="#838383"
                      />
                      <path
                        d="M11.0742 16.3594H8.93136V8.85937H11.0742V16.3594Z"
                        fill="#838383"
                      />
                      <circle
                        cx="10"
                        cy="11"
                        r="9.0625"
                        stroke="#838383"
                        strokeWidth="1.875"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7686_2262">
                        <rect width="22" height="22" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>

            <div className="sponsoredCircle-circles">
              <div className="sponsoredCircle-circles-wrapper">
                {sponsoredGroups.map((group, index) => (
                  <div
                    key={group._id}
                    className="sponsoredCircle-circles-circleItem"
                  >
                    <div
                      className="btn btn-close"
                      role="button"
                      aria-label="Close"
                      tabIndex="0"
                      onClick={() => handleRemoveSponsor(group._id)}
                    >
                      <svg
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                      >
                        <title>Close</title>
                        <path
                          d="M1.61596 12.0352L0.709625 11.1298L5.78494 6.05903L0.707031 0.98343L1.61114 0.0832728C3.27102 1.74294 4.96501 3.43703 6.66679 5.13815L11.7707 0.0351562L12.6607 0.923469L7.60279 5.98019L12.707 11.0791L11.7836 12L6.71834 6.9355C4.99765 8.65364 3.30662 10.3448 1.61596 12.0352Z"
                          fill="#999999"
                        />
                      </svg>
                    </div>

                    <div className="circleItem-image">
                      <img
                        src={getSponsorProfilePic(group.groupProfile)}
                        alt={`${group.groupName} profile`}
                      />
                    </div>

                    <div className="circleItem-name">
                      <h5 className="name">{group.groupName}</h5>
                      <span className="total-participants">
                        {group.memberLength} participants
                      </span>
                    </div>

                    <button className="btn btn-join">Join</button>
                  </div>
                ))}
              </div>

              {totalPages > 1 && (
                <div className="showMore" onClick={handleShowMore}>
                  {loading ? (
                    <div className="no-data">
                      <Spinner />{" "}
                    </div>
                  ) : (
                    <span>Show More</span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default SponsoredCircle;
