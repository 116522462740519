import React, { useRef, useEffect } from "react";
import { useState } from "react";
import Privacy from "./Privacy";
import SeePost from "./SeePost";
import Location from "./Location";
import ShareGroup from "./ShareGroup";
import ExceptContact from "./ExceptContact";
import OnlyShareWith from "./OnlyShareWith";
import PostVisibility from "./PostVisibility";
import axiosRequest from "../../Middleware/api";
import SliderPopup from "./SliderPopup";
import { useSelector, useDispatch } from "react-redux";
import { useSwiperSlider } from "../../Utils/helpers";
import {
  setTepars,
  handleToast,
  setPostDetails,
  setTogglePostAction,
  setToggeleViewOtherProfile,
  setScrollToPost,
  setExceptContacts,
  setOnlyShareContacts,
  setSound,
  setPostCreateProgress,
} from "../../Redux/actions";
import {
  ManageTeparsPrivacy,
  ManageTeparsPrivacyValue,
} from "../../Utils/helpers";
import { ERROR, CONTACT_EXCEPT, ONLY_SHARE_WITH } from "../../Utils/enums";

import "../../assets/css/tepars.scss";

function EditPostPrivacy({ onClose, createThumbnail }) {
  const popupRef = useRef(null);
  const sliderRef = useRef(null);
  const sliderWrapperRef = useRef(null);

  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const sound = useSelector((state) => state.sound);
  const togglePost = useSelector((state) => state.togglePost);
  const postDetails = useSelector((state) => state.postDetails);
  const savedContacts = useSelector((state) => state.exceptContact);
  const onlyShareContacts = useSelector((state) => state.onlyShareContact);

  const media = postDetails?.media;
  const EditPrivacy = postDetails?.privacy?.split("-")[0];
  const EditPost = postDetails && togglePost === "EditPost";
  const GroupsId = postDetails?.group?.map((group) => group.GroupId._id);
  const ArrayContactIds = postDetails?.contact?.map((item) => item?.userId);
  const ContactIds = postDetails?.contact?.map((item) => item?.userId?._id);
  const [mediaIndexFinal, setMediaIndexFinal] = useState(0);
  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const [getSelectedLocation, setGetSelectedLocation] = useState("");
  const [mediaAudioShow, setMediaAudioShow] = useState(true);
  const [cropedimages, setCropdeImages] = useState([]);
  const [handlePrivacyOptions, setHandlePrivacyOptions] = useState("Privacy");
  const [privacy, setPrivacy] = useState(ManageTeparsPrivacyValue(EditPrivacy));
  const [tempPrivacy, setTempPrivacy] = useState(
    ManageTeparsPrivacyValue(EditPrivacy)
  );
  const [getDescription, setGetDescription] = useState(
    EditPost ? postDetails?.description : ""
  );
  const [getVisibility, setGetVisibility] = useState(
    EditPost ? postDetails?.expireAt.toString() : "60"
  );
  const [exceptContact, setExceptContact] = useState(
    EditPost && privacy === CONTACT_EXCEPT ? ContactIds ?? [] : savedContacts
  );
  const [onlyShareContact, setOnlyShareContact] = useState(
    EditPost && privacy === ONLY_SHARE_WITH
      ? ContactIds ?? []
      : onlyShareContacts
  );
  const [getContactArray, setGetContactArray] = useState(
    EditPost && privacy === CONTACT_EXCEPT ? ArrayContactIds ?? [] : []
  );
  const [getContactArrayOnly, setGetContactArrayOnly] = useState(
    EditPost && privacy === ONLY_SHARE_WITH ? ArrayContactIds ?? [] : []
  );
  const [tempExceptContact, setTempExceptContact] = useState(
    EditPost && privacy === CONTACT_EXCEPT ? ContactIds ?? [] : []
  );
  const [tempContactArray, setTempContactArray] = useState(
    EditPost && privacy === CONTACT_EXCEPT ? ArrayContactIds ?? [] : []
  );
  const [tempOnlyShareContact, setTempOnlyShareContact] = useState(
    EditPost && privacy === ONLY_SHARE_WITH ? ContactIds ?? [] : []
  );
  const [tempOnlyShareArray, setTempOnlyShareArray] = useState(
    EditPost && privacy === ONLY_SHARE_WITH ? ArrayContactIds ?? [] : []
  );
  const [getRePosting, setGetRePosting] = useState(
    EditPost ? postDetails?.settingRepost : true
  );
  const [getOffComment, setGetOffComment] = useState(
    EditPost ? postDetails?.turnOffComment : false
  );
  const [getCanReplyPost, setGetCanReplyPost] = useState(
    EditPost ? postDetails?.canReply : false
  );
  const [getCanSharePost, setGetCanSharePost] = useState(
    EditPost ? postDetails?.canShareCirclePost : true
  );
  const [sharedGroups, setSharedGroups] = useState(GroupsId ?? []);
  const [sharedGroupsList, setSharedGroupsList] = useState(
    EditPost ? postDetails?.group ?? [] : []
  );
  const [tempSharedGroups, setTempSharedGroups] = useState(GroupsId ?? []);
  const [tempSharedGroupsList, setTempSharedGroupsList] = useState(
    EditPost ? postDetails?.group ?? [] : []
  );
  const [originalContactExcept, setOriginalContactExcept] = useState([]);
  const [originalContactArray, setOriginalContactArray] = useState([]);
  const [originalOnlyShareWith, setOriginalOnlyShareWith] = useState([]);
  const [originalContactArrayOnly, setOriginaContactArrayOnly] = useState([]);

  var countGetContactExcept = exceptContact?.length ?? 0;
  var countGetOnlyShareWith = onlyShareContact?.length ?? 0;
  const imagesList = postDetails?.media;

  useEffect(() => {
    setCropdeImages(imagesList);
  }, []);

  const HandleDoneClick = () => {
    if (handlePrivacyOptions === "ShareGroup") {
      setSharedGroups(tempSharedGroups);
      setSharedGroupsList(tempSharedGroupsList);
      setHandlePrivacyOptions("Privacy");
    } else if (handlePrivacyOptions === "ContactExcept") {
      setExceptContact(tempExceptContact);
      setGetContactArray(tempContactArray);
      dispatch(setExceptContacts(tempContactArray));
      setOriginalContactExcept(tempExceptContact);
      setOriginalContactArray(tempContactArray);
      setHandlePrivacyOptions("SeePost");
    } else if (handlePrivacyOptions === "OnlyShareWith") {
      setOnlyShareContact(tempOnlyShareContact);
      setGetContactArrayOnly(tempOnlyShareArray);
      dispatch(setOnlyShareContacts(tempOnlyShareArray));
      setOriginalOnlyShareWith(tempOnlyShareContact);
      setOriginaContactArrayOnly(tempOnlyShareArray);
      setHandlePrivacyOptions("SeePost");
    } else {
      setPrivacy(tempPrivacy);
      setHandlePrivacyOptions("Privacy");
    }
  };

  useEffect(() => {
    if (handlePrivacyOptions === "ContactExcept") {
      setOriginalContactExcept([...exceptContact]);
      setOriginalContactArray([...savedContacts]);
    }
    if (handlePrivacyOptions === "OnlyShareWith") {
      setOriginalOnlyShareWith([...onlyShareContact]);
      setOriginaContactArrayOnly([...onlyShareContacts]);
    }
  }, [handlePrivacyOptions]);

  const HandleBackClick = () => {
    if (
      handlePrivacyOptions === "ContactExcept" ||
      handlePrivacyOptions === "OnlyShareWith"
    ) {
      setTempExceptContact(originalContactExcept);
      setTempContactArray(originalContactArray);
      setGetContactArray(originalContactArray);
      setTempOnlyShareContact(originalOnlyShareWith);
      setTempOnlyShareArray(originalContactArrayOnly);
      setGetContactArrayOnly(originalContactArrayOnly);
      setHandlePrivacyOptions("SeePost");
    } else if (
      handlePrivacyOptions === "SeePost" ||
      handlePrivacyOptions === "Location" ||
      handlePrivacyOptions === "ShareGroup" ||
      handlePrivacyOptions === "PostVisibility"
    ) {
      setTempSharedGroups(sharedGroups);
      setTempSharedGroupsList(sharedGroupsList);
      setHandlePrivacyOptions("Privacy");
      setTempPrivacy(privacy);
    } else {
      dispatch(setPostDetails(null));
      dispatch(setTogglePostAction(null));
    }
  };

  const HandleEditPostPrivacy = () => {
    // Update only description of post
    if (descriptionEdit) {
      const editData = {
        type: "edit",
        postId: [postDetails?._id],
        description: getDescription,
      };
      if (editData) {
        axiosRequest
          .post(`post/functionality`, editData)
          .then((res) => {
            setDescriptionEdit(false);
            dispatch(setPostDetails(null));
            dispatch(setTogglePostAction(null));
            onClose();
          })
          .catch((error) => {
            if (error?.response?.status !== 401) {
              dispatch(handleToast(ERROR));
            }
            onClose();
          });
      }
    }

    // Update all post details except description of post
    const editPrivacyData = {
      type: "editPrivacy",
      postId: [postDetails?._id],
      canReply: getCanReplyPost,
      contact:
        privacy === CONTACT_EXCEPT
          ? exceptContact
          : privacy === ONLY_SHARE_WITH
          ? onlyShareContact
          : [],
      expireAt: getVisibility,
      group: sharedGroups,
      privacy: ManageTeparsPrivacy(privacy, sharedGroups?.length, postDetails),
      settingRepost: getRePosting,
      turnOffComment: getOffComment,
      canShareCirclePost: getCanSharePost,
      description: getDescription,
      location: {
        longitude: getSelectedLocation?.geometry?.location?.lng,
        name: getSelectedLocation?.name,
        latitude: getSelectedLocation?.geometry?.location?.lat,
      },
    };

    axiosRequest
      .post(`post/functionality`, editPrivacyData)
      .then((res) => {
        dispatch(setPostDetails(null));
        dispatch(setTogglePostAction(null));
        const updatedTepars = Tepars?.map((post) =>
          post?._id === res?.data?.data[0]?._id ? res?.data?.data[0] : post
        );
        dispatch(setTepars(updatedTepars));
        onClose();
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        onClose();
      });
  };

  const HandleRePost = () => {
    createThumbnail(cropedimages);
    dispatch(setPostCreateProgress(true));
    dispatch(setTogglePostAction(null));
    const rePostData = {
      location: {},
      description: getDescription,
      privacy: ManageTeparsPrivacy(privacy, sharedGroups?.length, ""),
      group: sharedGroups,
      contact:
        privacy === CONTACT_EXCEPT
          ? exceptContact
          : privacy === ONLY_SHARE_WITH
          ? onlyShareContact
          : [],
      tagUser: [],
      media: postDetails.media,
      RepostPostId: postDetails?._id,
      expireAt: getVisibility,
      canReply: getCanReplyPost,
      turnOffComment: getOffComment,
      canShareCirclePost: getCanSharePost,
      settingRepost: getRePosting,
    };

    if (rePostData) {
      axiosRequest
        .post(`post/create`, rePostData)
        .then((res) => {
          if (res?.data?.status === "500") {
            dispatch(handleToast(res?.data?.message));
          } else {
            dispatch(setTepars([res?.data?.data, ...Tepars]));
            dispatch(setToggeleViewOtherProfile(false));
            dispatch(setScrollToPost(true));
            dispatch(setPostCreateProgress(false));
            // dispatch(setPostCreateProgress(true));
            // dispatch(setPostDetails(res?.data?.data));
          }
          onClose();
        })
        .catch((error) => {
          if (error?.response?.status !== 401) {
            dispatch(handleToast(ERROR));
            dispatch(setPostCreateProgress(false));
          }
          onClose();
          dispatch(setPostCreateProgress(false));
        })
        .finally(() => {
          dispatch(setPostCreateProgress(false));
        });
    }
  };

  let width = "43.75rem";
  let height = "85vh";

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  if (viewportWidth <= 1024 && viewportHeight <= 1380) {
    width = "40rem";
    height = "30rem";
  } else if (viewportWidth <= 1440 || viewportWidth < 1600) {
    width = "60rem";
  } else if (viewportWidth > 1600) {
    width = "60rem";
    height = "40rem";
  }

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
  };

  useSwiperSlider(sliderRef, sliderWrapperRef, media.length);

  const handleClickOutside = (event) => {
    if (popupRef.current === event.target) {
      HandleBackClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (postDetails?.media?.[mediaIndexFinal]?.type === "Video") {
      setMediaAudioShow(true);
    } else {
      setMediaAudioShow(false);
    }
  }, [postDetails, mediaIndexFinal]);

  return (
    <div className="uploadTepars" ref={popupRef}>
      <div
        className="uploadTepars-popup"
        // style={{ width, height }}
      >
        <main className="container" style={{ display: "flex" }}>
          <div
            className="upload-image-container"
            ref={sliderWrapperRef}
            style={{
              // width: "450px",
              // height: "562.5px",
              // width: "450px",
              // height: "500px",
              aspectRatio: postDetails.media[0].aspectRatio,
            }}
          >
            <SliderPopup
              sliderWrapperRef={sliderWrapperRef}
              post={postDetails}
              imagesList={imagesList}
              mediaIndexFinal={mediaIndexFinal}
              setMediaIndexFinal={setMediaIndexFinal}
            />

            {/* show post length */}
            {imagesList?.length > 1 && (
              <div className="image-number">
                <span className="number">
                  {`${mediaIndexFinal + 1}/${imagesList?.length}`}
                </span>
              </div>
            )}

            {mediaAudioShow && (
              <div
                className="image-sound"
                onClick={() => dispatch(setSound(!sound))}
              >
                {sound ? (
                  <svg viewBox="0 0 32 32" fill="none">
                    <title>Unmute</title>
                    <g id="wpf:mute">
                      <path
                        id="Vector"
                        d="M4.31237 2.46138C4.08714 2.50214 3.87773 2.6049 3.70768 2.75812C3.53764 2.91134 3.41369 3.10894 3.34976 3.32873C3.28583 3.54851 3.28446 3.78177 3.3458 4.00229C3.40713 4.22281 3.52875 4.42186 3.69699 4.57707L28.3124 29.1925C28.421 29.3264 28.5565 29.4361 28.7102 29.5143C28.8639 29.5925 29.0323 29.6375 29.2046 29.6465C29.3768 29.6554 29.549 29.6281 29.71 29.5662C29.8709 29.5043 30.0171 29.4093 30.139 29.2873C30.2609 29.1653 30.3558 29.0191 30.4176 28.8581C30.4793 28.6971 30.5065 28.5249 30.4975 28.3526C30.4884 28.1804 30.4433 28.012 30.3649 27.8584C30.2866 27.7047 30.1769 27.5693 30.0428 27.4608L20.9278 18.3469V4.07615C20.9278 2.51922 19.5678 2.12907 18.5044 3.19245L12.081 9.50015L5.42868 2.84538C5.30164 2.71007 5.14564 2.60524 4.97236 2.53874C4.79908 2.47224 4.61301 2.44579 4.42806 2.46138C4.38952 2.45956 4.35091 2.45956 4.31237 2.46138ZM6.15852 11.1543C5.48037 11.1543 4.92776 11.7057 4.92776 12.3851V19.7697C4.92776 20.4466 5.48037 21.0005 6.15852 21.0005H10.3899L18.4662 28.8835C19.697 30.1143 20.9278 29.4841 20.9278 27.8066V24.6521L7.42745 11.1543H6.15852Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg viewBox="0 0 32 32" fill="none">
                    <title>Mute</title>
                    <g clipPath="url(#clip0_1657_59)">
                      <g filter="url(#filter0_d_1657_59)">
                        <path
                          d="M2.9959 21.9013L2.97233 11.224H10.9391L16.2895 5.87351L16.3131 27.2517L10.9626 21.9013H2.9959ZM18.9058 28.1945V25.4604C20.9014 24.879 22.5121 23.7636 23.7377 22.1143C24.9634 20.4651 25.5762 18.6027 25.5762 16.5273C25.5762 14.4531 24.9634 12.5913 23.7377 10.9421C22.5121 9.29277 20.9014 8.1768 18.9058 7.59414L18.9058 4.85999C21.6557 5.47282 23.9027 6.86378 25.6469 9.03287C27.3911 11.202 28.2632 13.7001 28.2632 16.5273C28.2632 19.3557 27.3911 21.8544 25.6469 24.0235C23.9027 26.1926 21.6557 27.5829 18.9058 28.1945ZM18.9058 22.6319L18.9058 19.4264C19.2986 19.0336 19.6208 18.5939 19.8722 18.1074C20.1236 17.6209 20.2493 17.1099 20.2493 16.5744C20.2493 16.0087 20.1277 15.4785 19.8844 14.9839C19.6412 14.4892 19.315 14.0373 18.9058 13.6281L18.9058 10.4226C20.0686 11.0511 21.0271 11.8996 21.7814 12.9682C22.5356 14.0367 22.9127 15.2309 22.9127 16.5508C22.9127 17.8708 22.5356 19.0574 21.7814 20.1109C21.0271 21.1643 20.0686 22.0047 18.9058 22.6319Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_1657_59"
                        x="-1.02734"
                        y="2.85938"
                        width="33.291"
                        height="31.3359"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1657_59"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1657_59"
                          result="shape"
                        />
                      </filter>
                      <clipPath id="clip0_1657_59">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            )}

            {/* <div className="gradient" aria-hidden="true" /> */}
          </div>

          <div className="filters">
            {/* header */}
            <div className="header">
              <div
                className="btn btn-back"
                onClick={() => HandleBackClick()}
                tabIndex="0"
                aria-label="Back"
                role="button"
              >
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Back</title>
                  <path
                    d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                    stroke={
                      theme === "Light"
                        ? "#1891a2"
                        : theme === "Dark"
                        ? "#ffffff"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? "#1891a2"
                        : "#ffffff"
                    }
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="heading">
                <h1 className="title">
                  {togglePost === "EditPost" && "Edit post"}
                  {togglePost === "RePost" && "Repost"}
                </h1>
              </div>
              {handlePrivacyOptions !== "Privacy" &&
                handlePrivacyOptions !== "Location" && (
                  <div className="next" onClick={HandleDoneClick} tabIndex="0">
                    <span>Done</span>
                  </div>
                )}
              {togglePost === "EditPost" &&
                handlePrivacyOptions === "Privacy" && (
                  <div
                    className="next"
                    onClick={HandleEditPostPrivacy}
                    tabIndex="0"
                  >
                    <span>Done</span>
                  </div>
                )}
              {togglePost === "RePost" &&
                handlePrivacyOptions === "Privacy" && (
                  <div className="next" onClick={HandleRePost} tabIndex="0">
                    <span>Post</span>
                  </div>
                )}
            </div>
            {/* PRIVACY */}
            {handlePrivacyOptions === "Privacy" && (
              <div className="filters-wrapper">
                <Privacy
                  setHandlePrivacyOptions={setHandlePrivacyOptions}
                  getDescription={getDescription}
                  setGetDescription={setGetDescription}
                  privacy={privacy}
                  setPrivacy={setPrivacy}
                  getVisibility={getVisibility}
                  getRePosting={getRePosting}
                  setGetRePosting={setGetRePosting}
                  getOffComment={getOffComment}
                  setGetOffComment={setGetOffComment}
                  getCanSharePost={getCanSharePost}
                  setGetCanSharePost={setGetCanSharePost}
                  getCanReplyPost={getCanReplyPost}
                  setGetCanReplyPost={setGetCanReplyPost}
                  getSelectedLocation={getSelectedLocation}
                  sharedGroups={sharedGroups}
                  sharedGroupsList={sharedGroupsList}
                  setDescriptionEdit={setDescriptionEdit}
                />
              </div>
            )}
            {/* LOCATION */}
            {handlePrivacyOptions === "Location" && (
              <div className="filters-wrapper">
                <Location
                  getSelectedLocation={getSelectedLocation}
                  setGetSelectedLocation={setGetSelectedLocation}
                  setHandlePrivacyOptions={setHandlePrivacyOptions}
                />
              </div>
            )}
            {/* SEEPOST */}
            {handlePrivacyOptions === "SeePost" && (
              <div className="filters-wrapper">
                <SeePost
                  privacy={tempPrivacy}
                  setPrivacy={setTempPrivacy}
                  countContactExcept={countGetContactExcept}
                  countGetOnlyShareWith={countGetOnlyShareWith}
                  setHandlePrivacyOptions={setHandlePrivacyOptions}
                />
              </div>
            )}
            {/* SHARECONTACT */}
            {handlePrivacyOptions === "ContactExcept" && (
              <div className="filters-wrapper">
                <ExceptContact
                  getContactExcept={tempExceptContact}
                  setGetContactExcept={setTempExceptContact}
                  getContactArray={tempContactArray}
                  setGetContactArray={setTempContactArray}
                />
              </div>
            )}
            {/* ONLYSHAREWITH */}
            {handlePrivacyOptions === "OnlyShareWith" && (
              <div className="filters-wrapper">
                <OnlyShareWith
                  getOnlyShareWith={tempOnlyShareContact}
                  setGetOnlyShareWith={setTempOnlyShareContact}
                  getContactArray={tempOnlyShareArray}
                  setGetContactArray={setTempOnlyShareArray}
                />
              </div>
            )}
            {/* SHAREGROUP */}
            {handlePrivacyOptions === "ShareGroup" && (
              <div className="filters-wrapper">
                <ShareGroup
                  sharedGroups={tempSharedGroups}
                  setSharedGroups={setTempSharedGroups}
                  sharedGroupsList={tempSharedGroupsList}
                  setSharedGroupsList={setTempSharedGroupsList}
                />
              </div>
            )}
            {/* POSTVISIBILITY */}
            {handlePrivacyOptions === "PostVisibility" && (
              <div className="filters-wrapper">
                <PostVisibility
                  getVisibility={getVisibility}
                  setGetVisibility={setGetVisibility}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditPostPrivacy;
