//firebase/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDOPO3ycrfXCMlmmFf8MLqeBVaFC3vjZ20",
  authDomain: "tepnot-1441e.firebaseapp.com",
  projectId: "tepnot-1441e",
  storageBucket: "tepnot-1441e.appspot.com",
  messagingSenderId: "434498101903",
  appId: "1:434498101903:web:e9514192f8095b40a886fa",
  measurementId: "G-SF08JW9NGS"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
