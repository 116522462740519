import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSelectedSetting } from "../../Redux/actions";
import "../../assets/css/requestAccountInfo.scss";

function RequestAccountInfo() {
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    dispatch(setSelectedSetting(""));
    setIsMounted(false);
  };

  return (
    <div className="requestAccountInfo">
      <div className="requestAccountInfo-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Request account info</span>
          </div>
        </header>

        <div className="requestAccountInfo-info-wrapper">
          <div className="requestAccount-info">
            <div className="requestAccount-image">
              <svg width="38" height="50" viewBox="0 0 38 50" fill="none">
                <g clipPath="url(#clip0_6055_53514)">
                  <path
                    d="M21.875 13.2812V0H2.34375C1.04492 0 0 1.04492 0 2.34375V47.6562C0 48.9551 1.04492 50 2.34375 50H35.1562C36.4551 50 37.5 48.9551 37.5 47.6562V15.625H24.2188C22.9297 15.625 21.875 14.5703 21.875 13.2812ZM28.125 36.3281C28.125 36.9727 27.5977 37.5 26.9531 37.5H10.5469C9.90234 37.5 9.375 36.9727 9.375 36.3281V35.5469C9.375 34.9023 9.90234 34.375 10.5469 34.375H26.9531C27.5977 34.375 28.125 34.9023 28.125 35.5469V36.3281ZM28.125 30.0781C28.125 30.7227 27.5977 31.25 26.9531 31.25H10.5469C9.90234 31.25 9.375 30.7227 9.375 30.0781V29.2969C9.375 28.6523 9.90234 28.125 10.5469 28.125H26.9531C27.5977 28.125 28.125 28.6523 28.125 29.2969V30.0781ZM28.125 23.0469V23.8281C28.125 24.4727 27.5977 25 26.9531 25H10.5469C9.90234 25 9.375 24.4727 9.375 23.8281V23.0469C9.375 22.4023 9.90234 21.875 10.5469 21.875H26.9531C27.5977 21.875 28.125 22.4023 28.125 23.0469ZM37.5 11.9043V12.5H25V0H25.5957C26.2207 0 26.8164 0.244141 27.2559 0.683594L36.8164 10.2539C37.2559 10.6934 37.5 11.2891 37.5 11.9043Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6055_53514">
                    <rect width="37.5" height="50" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p className="info">
              Create a report of your WhatsApp account information and settings,
              which you can access or port to another app. This report does not
              include your messages.
              <span className="learnMore">
                <a href="/">Learn More...</a>
              </span>
            </p>
          </div>

          <div className="requestAccountInfo-cta-info ">
            <div
              className="requestAccount-settings textvoiceMessages-listItem"
              tabIndex="0"
              role="listitem"
            >
              <div className="requestAccount-listItem-image">
                <svg width="38" height="50" viewBox="0 0 38 50" fill="none">
                  <g clipPath="url(#clip0_6055_53514)">
                    <path
                      d="M21.875 13.2812V0H2.34375C1.04492 0 0 1.04492 0 2.34375V47.6562C0 48.9551 1.04492 50 2.34375 50H35.1562C36.4551 50 37.5 48.9551 37.5 47.6562V15.625H24.2188C22.9297 15.625 21.875 14.5703 21.875 13.2812ZM28.125 36.3281C28.125 36.9727 27.5977 37.5 26.9531 37.5H10.5469C9.90234 37.5 9.375 36.9727 9.375 36.3281V35.5469C9.375 34.9023 9.90234 34.375 10.5469 34.375H26.9531C27.5977 34.375 28.125 34.9023 28.125 35.5469V36.3281ZM28.125 30.0781C28.125 30.7227 27.5977 31.25 26.9531 31.25H10.5469C9.90234 31.25 9.375 30.7227 9.375 30.0781V29.2969C9.375 28.6523 9.90234 28.125 10.5469 28.125H26.9531C27.5977 28.125 28.125 28.6523 28.125 29.2969V30.0781ZM28.125 23.0469V23.8281C28.125 24.4727 27.5977 25 26.9531 25H10.5469C9.90234 25 9.375 24.4727 9.375 23.8281V23.0469C9.375 22.4023 9.90234 21.875 10.5469 21.875H26.9531C27.5977 21.875 28.125 22.4023 28.125 23.0469ZM37.5 11.9043V12.5H25V0H25.5957C26.2207 0 26.8164 0.244141 27.2559 0.683594L36.8164 10.2539C37.2559 10.6934 37.5 11.2891 37.5 11.9043Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6055_53514">
                      <rect width="37.5" height="50" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="requestAccount-listItem-info">
                <span className="requestAccount-listItem-name">
                  Request Report
                </span>
              </div>
            </div>
          </div>

          <div className="more-infos">
            <p className="info">
              Your report will be ready in about 3 days. You'll have a few weeks
              to download your report after it's available.
            </p>

            <p className="info">
              Your request will be canceled if you make changes to your account
              such as changing your number or deleting your account.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestAccountInfo;
