import { React, useState, useEffect, useRef } from "react";
import NoMediaYet from "./NoMediaYet";
import Spinner from "../Common/Spinner";
import SettingsMenu from "./SettingsMenu";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { getGridTepars } from "../../Utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  handleToast,
  setSelectedSetting,
  setTepars,
  setTeparsPage,
  setTeparsLoad,
  setTeparsTotalCount,
  setLeftRightSide,
} from "../../Redux/actions";
import "../../assets/css/savedSettings.scss";

function SavedSettings({ setSelectedMenu, handlePostSelect }) {
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);
  const savedContainerRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const teparsPage = useSelector((state) => state.teparsPage);
  const totalCount = useSelector((state) => state.totalCount);
  const teparsLoad = useSelector((state) => state.teparsLoad);

  const [loading, setLoading] = useState(false);
  const [settingMenu, setSettingMenu] = useState(false);
  const [selectedTepars, setSelectedTepars] = useState([]);
  const [settingMenuOption, setSettingMenuOption] = useState("select");
  const [imageErrors, setImageErrors] = useState({});
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const handleImageError = (index) => {
    setImageErrors((prev) => ({ ...prev, [index]: true }));
  };

  const handleBackButton = () => {
    setIsOpen(false);
    dispatch(setLeftRightSide("open-left"));
    setTimeout(() => {
      dispatch(setSelectedSetting(""));
    }, 250);
  };

  const getSavedTepars = (pageNumber) => {
    if (allDataLoaded) return;
    setLoading(true);

    if (pageNumber === 1) {
      dispatch(setTeparsLoad(true));
    }

    axiosRequest
      .get(`post/save?page=${pageNumber}`)
      .then((res) => {
        if (res?.data?.data?.length) {
          let updatedSavedTepars = [...Tepars, ...res?.data?.data];
          if (pageNumber === 1) {
            dispatch(setTepars(res.data.data));
          } else {
            dispatch(setTepars(updatedSavedTepars));
          }
          dispatch(setTeparsPage(pageNumber + 1));
        } else {
          if (pageNumber === 1) {
            dispatch(setTepars([]));
          } else {
            setAllDataLoaded(true);
          }
        }

        setLoading(false);
        dispatch(setTeparsLoad(false));
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setTeparsLoad(false));

        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  // useEffect(() => {
  //   getSavedTepars(1);
  // }, []);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = savedContainerRef.current;

    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      totalCount !== Tepars?.length &&
      !allDataLoaded
    ) {
      getSavedTepars(teparsPage);
    }
  };

  const handleSelectMedia = (e, tepars) => {
    e.stopPropagation();
    if (selectedTepars.includes(tepars)) {
      setSelectedTepars(selectedTepars.filter((item) => item !== tepars));
      if (selectedTepars?.length === 1) {
        setSettingMenuOption("select");
      }
    } else {
      setSelectedTepars([...selectedTepars, tepars]);
    }
  };

  const handleUnsaved = () => {
    const data = {
      type: "unsave",
      postId: selectedTepars,
    };

    axiosRequest
      .post(`post/functionality`, data)
      .then((res) => {
        const saveTepars = Tepars.filter(
          (tepars) => !res?.data?.data.some((item) => item._id === tepars._id)
        );
        dispatch(setTepars(saveTepars));
        dispatch(setTeparsTotalCount(totalCount - 1));
        setSettingMenu(false);
        setSelectedMenu("profile");
        setSettingMenuOption("select");
        setSelectedTepars([]);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setSelectedTepars([]);
      });
  };

  const closeDropdown = (event) => {
    if (!dropdownRef.current || !dropdownRef.current.contains(event?.target)) {
      setSettingMenu(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event?.target)) {
      setSettingMenu(false);
      closeDropdown();
    }
  };

  useEffect(() => {
    if (settingMenu) {
      document.addEventListener("mousedown", closeDropdown);
    } else {
      document.removeEventListener("mousedown", closeDropdown);
    }

    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, [settingMenu]);

  useEffect(() => {
    document.addEventListener("show", handleClickOutside);

    return () => {
      document.removeEventListener("show", handleClickOutside);
    };
  }, []);

  const keyDown = (e) => {
    if (e.key === "Escape") {
      setSettingMenu(false);
    }
  };

  return (
    <>
      <div className={`savedSettings ${isOpen ? "open" : "close"}`}>
        <div className="savedSettings-wrapper">
          <header className="header">
            <div className="back">
              <div
                className="btn btn-back"
                aria-label="Back"
                role="button"
                tabIndex="0"
                onClick={() => handleBackButton()}
              >
                <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                  <title>Back</title>
                  <g id="Group 18522">
                    <g id="Group 18522_2">
                      <path
                        id="Vector"
                        d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="text">Saved</span>
            </div>
            {Tepars?.length > 0 && (
              <button
                className="btn btn-saved-menu"
                onClick={() => setSettingMenu(true)}
                onKeyDown={keyDown}
              >
                <svg width="5" height="18" viewBox="0 0 5 18" fill="none">
                  <title>Menu</title>
                  <path
                    d="M2.25 13.5C2.84674 13.5 3.41903 13.7371 3.84099 14.159C4.26295 14.581 4.5 15.1533 4.5 15.75C4.5 16.3467 4.26295 16.919 3.84099 17.341C3.41903 17.7629 2.84674 18 2.25 18C1.65326 18 1.08097 17.7629 0.65901 17.341C0.237053 16.919 0 16.3467 0 15.75C0 15.1533 0.237053 14.581 0.65901 14.159C1.08097 13.7371 1.65326 13.5 2.25 13.5ZM2.25 6.75C2.84674 6.75 3.41903 6.98705 3.84099 7.40901C4.26295 7.83097 4.5 8.40326 4.5 9C4.5 9.59674 4.26295 10.169 3.84099 10.591C3.41903 11.0129 2.84674 11.25 2.25 11.25C1.65326 11.25 1.08097 11.0129 0.65901 10.591C0.237053 10.169 0 9.59674 0 9C0 8.40326 0.237053 7.83097 0.65901 7.40901C1.08097 6.98705 1.65326 6.75 2.25 6.75ZM2.25 0C2.84674 0 3.41903 0.237053 3.84099 0.65901C4.26295 1.08097 4.5 1.65326 4.5 2.25C4.5 2.84674 4.26295 3.41903 3.84099 3.84099C3.41903 4.26295 2.84674 4.5 2.25 4.5C1.65326 4.5 1.08097 4.26295 0.65901 3.84099C0.237053 3.41903 0 2.84674 0 2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
          </header>

          {teparsLoad ? (
            <div className="saved-posts">
              <div className="media-container" style={{ rowGap: "0px" }}>
                {Array.from({ length: 33 }).map((posts, index) => (
                  <SkeletonTheme
                    baseColor={
                      theme === "Light"
                        ? ""
                        : theme === "Dark"
                        ? "#202020"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? ""
                        : "#202020"
                    }
                    highlightColor={
                      theme === "Light"
                        ? ""
                        : theme === "Dark"
                        ? "#2A2A2A"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? ""
                        : "#2A2A2A"
                    }
                  >
                    <div className="media">
                      <Skeleton width={130} height={130} />
                    </div>
                  </SkeletonTheme>
                ))}
              </div>
            </div>
          ) : (
            <>
              {Tepars?.length > 0 ? (
                <div
                  className="saved-posts"
                  ref={savedContainerRef}
                  onScroll={handleScroll}
                >
                  <div className="media-container">
                    {Tepars &&
                      Tepars.map((tepars, i) => (
                        <div
                          tabIndex="0"
                          className="media"
                          role="listitem"
                          onClick={() => handlePostSelect(tepars?._id)}
                        >
                          {getGridTepars(tepars?.media[0]) &&
                          !imageErrors[i] ? (
                            <img
                              src={getGridTepars(tepars?.media[0])}
                              alt="post media"
                              onError={() => handleImageError(i)}
                            />
                          ) : (
                            <div className="blank-image"></div>
                          )}
                          {tepars?.media?.length > 1 && (
                            <div className="media-type">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M12.7 1.66406H9.455C7.985 1.66406 6.82 1.66406 5.90917 1.7874C4.97083 1.91406 4.21167 2.18073 3.61333 2.78156C3.01417 3.3824 2.74833 4.1449 2.6225 5.08656C2.5 6.00156 2.5 7.17073 2.5 8.64656V13.5116C2.5 14.7682 3.26667 15.8449 4.35583 16.2966C4.3 15.5382 4.3 14.4757 4.3 13.5907V9.41573C4.3 8.34823 4.3 7.4274 4.39833 6.69073C4.50417 5.90073 4.7425 5.14406 5.35417 4.5299C5.96583 3.91573 6.72 3.67656 7.50667 3.5699C8.24 3.47156 9.15667 3.47156 10.2208 3.47156H12.7792C13.8425 3.47156 14.7575 3.47156 15.4917 3.5699C15.2717 3.00854 14.8877 2.52647 14.3897 2.18653C13.8917 1.84658 13.3029 1.66451 12.7 1.66406Z"
                                  fill="white"
                                />
                                <path
                                  d="M5.5 9.49495C5.5 7.22328 5.5 6.08745 6.20333 5.38161C6.90583 4.67578 8.03667 4.67578 10.3 4.67578H12.7C14.9625 4.67578 16.0942 4.67578 16.7975 5.38161C17.5 6.08745 17.5 7.22328 17.5 9.49495V13.5116C17.5 15.7833 17.5 16.9191 16.7975 17.6249C16.0942 18.3308 14.9625 18.3308 12.7 18.3308H10.3C8.0375 18.3308 6.90583 18.3308 6.20333 17.6249C5.5 16.9191 5.5 15.7833 5.5 13.5116V9.49495Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          )}
                          {tepars?.media[0]?.type === "Video" && (
                            <div className="media-type">
                              <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_311_7573)">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.5134 4.29316L14.1693 7.11756V3.86756C14.1693 3.66233 14.0815 3.46551 13.9252 3.32039C13.7689 3.17528 13.557 3.09375 13.3359 3.09375H1.66927C1.44826 3.09375 1.2363 3.17528 1.08002 3.32039C0.923735 3.46551 0.835938 3.66233 0.835938 3.86756V14.7009C0.835938 14.9061 0.923735 15.1029 1.08002 15.2481C1.2363 15.3932 1.44826 15.4747 1.66927 15.4747H13.3359C13.557 15.4747 13.7689 15.3932 13.9252 15.2481C14.0815 15.1029 14.1693 14.9061 14.1693 14.7009V11.4509L18.5134 14.2753C18.5759 14.316 18.6492 14.3399 18.7253 14.3446C18.8014 14.3492 18.8775 14.3343 18.9451 14.3016C19.0128 14.2689 19.0694 14.2195 19.109 14.159C19.1485 14.0984 19.1694 14.0289 19.1693 13.958V4.61042C19.1694 4.53958 19.1485 4.47008 19.109 4.4095C19.0694 4.34891 19.0128 4.29957 18.9451 4.26684C18.8775 4.23411 18.8014 4.21926 18.7253 4.2239C18.6492 4.22854 18.5759 4.2525 18.5134 4.29316Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_311_7573">
                                    <rect
                                      width="20"
                                      height="18.5714"
                                      fill="white"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          )}
                          {/* <div className="saved-viewed-number">
                      <div className="viewIcon">
                        <img src={view} alt="view" />
                        <span className="viewed-number">12</span>
                      </div>
                    </div> */}
                          {settingMenuOption === "selected" && (
                            <div
                              className="select-post"
                              onClick={(e) => handleSelectMedia(e, tepars?._id)}
                            >
                              <div className="group-check">
                                <input
                                  type="radio"
                                  id={`myCheckbox${i}`}
                                  aria-label="Check this box"
                                  checked={selectedTepars?.includes(
                                    tepars?._id
                                  )}
                                  onChange={(e) =>
                                    handleSelectMedia(e, tepars?._id)
                                  }
                                />
                                <span
                                  className="checkmark"
                                  onClick={(e) =>
                                    handleSelectMedia(e, tepars?._id)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>

                  {loading && <Spinner />}
                </div>
              ) : (
                <NoMediaYet
                  text="No saved posts yet"
                  icon={
                    <svg width="54" height="70" viewBox="0 0 54 70" fill="none">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.09667 0C3.65143 0 0 3.33749 0 7.40055V67.9422C0.000192745 68.3092 0.107704 68.6695 0.311402 68.9857C0.5151 69.302 0.807581 69.5627 1.15856 69.7409C1.50954 69.9191 1.90626 70.0084 2.30765 69.9994C2.70903 69.9904 3.10049 69.8835 3.4415 69.6898L27 56.3323L50.5585 69.6898C50.8995 69.8835 51.291 69.9904 51.6924 69.9994C52.0937 70.0084 52.4905 69.9191 52.8414 69.7409C53.1924 69.5627 53.4849 69.302 53.6886 68.9857C53.8923 68.6694 53.9998 68.3092 54 67.9422V7.40055C54 3.33749 50.3486 0 45.9033 0H8.09667ZM8.09667 4.11817H45.9033C47.933 4.11817 49.4945 5.54542 49.4945 7.40055V64.2495L28.1804 52.1685C27.8254 51.9689 27.4168 51.8632 27 51.8632C26.5832 51.8632 26.1746 51.9689 25.8196 52.1685L4.50554 64.2495V7.40055C4.50554 5.54542 6.06705 4.11817 8.09667 4.11817Z"
                        fill={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                      />
                    </svg>
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
      {settingMenu && (
        <SettingsMenu
          ddref={dropdownRef}
          type="saved"
          selectedTepars={selectedTepars}
          setSettingMenu={setSettingMenu}
          settingMenuOption={settingMenuOption}
          setSettingMenuOption={setSettingMenuOption}
          handleSubmit={handleUnsaved}
        />
      )}
    </>
  );
}

export default SavedSettings;
