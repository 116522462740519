import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import LoadingPage from "./pages/LoadingPage";
import { MAP_API, MAP_API_KEY } from "./Utils/enums";
import { useDispatch, useSelector } from "react-redux";
import { SocketProvider } from "./Contexts/SocketContext";
import { setCurrentLocation, setTheme, setSound } from "./Redux/actions";
import { updateFavicon } from "./Utils/helpers";
import { MobileRedirect } from "./MobileRedirect";
import { setupAxiosInterceptors } from "./Middleware/api";
import "./assets/css/app.scss";

const queryClient = new QueryClient();

function App() {
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Listen for changes in localStorage
    const handleFaviconUpdate = (event) => {
      if (event.key === "favicon-update") {
        const { url } = JSON.parse(event.newValue);

        // Update the favicon in this tab
        updateFavicon("tepnot-fav-warning.png");
      }
    };

    // Attach the event listener
    window.addEventListener("storage", handleFaviconUpdate);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("storage", handleFaviconUpdate);
    };
  }, []);

  useEffect(() => {
    const disableContextMenu = (event) => {
      event.preventDefault();
    };
    document.addEventListener("contextmenu", disableContextMenu);
    return () => {
      document.removeEventListener("contextmenu", disableContextMenu);
    };
  }, []);

  useEffect(() => {
    setupAxiosInterceptors(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  const themeSet = () => {
    const body = document.body;
    const prefersLightTheme = window.matchMedia(
      "(prefers-color-scheme: light)"
    ).matches;
    const prefersDarkTheme = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    if (theme === "Light" && theme === "Light") {
      body.classList.remove("dark");
      body.classList.add("light");
    } else if (theme === "Dark" && theme === "Dark") {
      body.classList.remove("light");
      body.classList.add("dark");
    } else {
      if (prefersDarkTheme) {
        body.classList.remove("light");
        body.classList.add("dark");
      } else if (prefersLightTheme) {
        body.classList.remove("dark");
        body.classList.add("light");
      }
      dispatch(setTheme("Default"));
    }
  };

  useEffect(() => {
    dispatch(setSound(true));
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", () => {
      themeSet();
    });
    themeSet();
  }, []);

  const getLocation = async () => {
    try {
      const res = await fetch("https://ipapi.co/json/");
      const locationObj = await res.json();
      if (locationObj.error) {
        console.error("Error fetching location:", locationObj.reason);
        return;
      }
      const currentLocationObj = {
        latitude: locationObj?.latitude,
        longitude: locationObj?.longitude,
        city: locationObj?.city,
      };
      dispatch(setCurrentLocation(currentLocationObj));
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <SocketProvider>
        <MobileRedirect />
        <LoadingPage />
      </SocketProvider>
    </QueryClientProvider>
  );
}

export default App;
