import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSelectedSetting } from "../../Redux/actions";
import "../../assets/css/securitySettings.scss";

function SecuritySettings() {
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    dispatch(setSelectedSetting(""));
    setIsMounted(false);
  };


  const handleCheckmarkClick = () => {
    // Toggle the isChecked state
    setIsChecked(!isChecked);
  };
  return (
    <div className="securitySettings">
      <div className="securitySettings-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Security</span>
          </div>
        </header>

        <div className="securitySettings-info-wrapper">
          <div className="security-info">
            <div className="security-image">
              <svg width="49" height="49" viewBox="0 0 49 49" fill="none">
                <g clipPath="url(#clip0_6055_50241)">
                  <path
                    d="M16.1356 22.2955H33.79V15.6818C33.79 13.2476 32.9279 11.1694 31.2039 9.44709C29.4798 7.72479 27.3994 6.86364 24.9628 6.86364C22.5261 6.86364 20.4457 7.72479 18.7217 9.44709C16.9976 11.1694 16.1356 13.2476 16.1356 15.6818V22.2955ZM44.824 25.6023V45.4432C44.824 46.3617 44.5021 47.1425 43.8585 47.7855C43.2148 48.4285 42.4333 48.75 41.5138 48.75H8.41176C7.49226 48.75 6.71069 48.4285 6.06704 47.7855C5.42339 47.1425 5.10156 46.3617 5.10156 45.4432V25.6023C5.10156 24.6837 5.42339 23.9029 6.06704 23.2599C6.71069 22.617 7.49226 22.2955 8.41176 22.2955H9.51516V15.6818C9.51516 11.4564 11.0323 7.82812 14.0667 4.79688C17.101 1.76562 20.7331 0.25 24.9628 0.25C29.1925 0.25 32.8245 1.76562 35.8588 4.79688C38.8932 7.82812 40.4104 11.4564 40.4104 15.6818V22.2955H41.5138C42.4333 22.2955 43.2148 22.617 43.8585 23.2599C44.5021 23.9029 44.824 24.6837 44.824 25.6023Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6055_50241">
                    <rect
                      width="48.5"
                      height="48.5"
                      fill="white"
                      transform="translate(0.25 0.25)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p className="heading">Your chats and call are private</p>
            <p className="info">
              End-to-end encryption keeps your personal messages and calls
              between you and the people you choose. Not even WhatsApp can read
              or listen to them. This includes your:
            </p>
          </div>

          <div className="securitySettings-cta-info ">
            <div
              className="security-settings textvoiceMessages-listItem"
              role="listitem"
            >
              <div className="security-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18.4103 8.89575L22 6L3.02564 6C2.75362 6 2.49275 6.06848 2.3004 6.19038C2.10806 6.31228 2 6.47761 2 6.65L2 18.35C2 18.5224 2.10806 18.6877 2.3004 18.8096C2.49275 18.9315 2.75362 19 3.02564 19L17.3846 19C17.6566 19 17.9175 18.9315 18.1099 18.8096C18.3022 18.6877 18.4103 18.5224 18.4103 18.35L18.4103 8.89575Z"
                    fill="white"
                  />
                  <path
                    d="M5 11L14 11"
                    stroke="#1891A2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5 14H12"
                    stroke="#1891A2"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="security-listItem-info">
                <span className="security-listItem-name">
                  Text and voice messages
                </span>
              </div>
            </div>

            <div className="security-settings call-listItem" role="listitem">
              <div className="security-listItem-image">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                  <g id="Frame" clipPath="url(#clip0_7387_18304)">
                    <path
                      id="Vector"
                      d="M28 21.8933V26.608C28.0002 26.9456 27.8723 27.2706 27.6421 27.5176C27.412 27.7646 27.0967 27.915 26.76 27.9387C26.1773 27.9787 25.7013 28 25.3333 28C13.5507 28 4 18.4493 4 6.66667C4 6.29867 4.02 5.82267 4.06133 5.24C4.08496 4.90326 4.23544 4.58801 4.4824 4.35788C4.72937 4.12774 5.05443 3.99985 5.392 4H10.1067C10.2721 3.99983 10.4316 4.06115 10.5543 4.17203C10.677 4.28291 10.7541 4.43544 10.7707 4.6C10.8013 4.90667 10.8293 5.15067 10.856 5.336C11.121 7.18523 11.664 8.98378 12.4667 10.6707C12.5933 10.9373 12.5107 11.256 12.2707 11.4267L9.39333 13.4827C11.1526 17.5819 14.4194 20.8487 18.5187 22.608L20.572 19.736C20.6559 19.6187 20.7784 19.5345 20.918 19.4982C21.0576 19.4619 21.2055 19.4757 21.336 19.5373C23.0227 20.3385 24.8208 20.8802 26.6693 21.144C26.8547 21.1707 27.0987 21.2 27.4027 21.2293C27.567 21.2462 27.7192 21.3234 27.8298 21.4461C27.9404 21.5688 28.0002 21.7282 28 21.8933Z"
                      fill="white"
                    />
                    <path
                      id="Vector_2"
                      d="M20 4.80078V8.80078H16V11.4674H20V15.4674H22.6667V11.4674H26.6667V8.80078H22.6667V4.80078H20Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7387_18304">
                      <rect width="32" height="32" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="security-listItem-info">
                <span className="security-listItem-name">
                  Audio and video call
                </span>
              </div>
            </div>

            <div className="security-settings media-listItem" role="listitem">
              <div className="security-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M7.51402 4.99938C6.88518 4.99938 6.28142 5.25963 5.80022 5.74129C4.7333 6.81169 4.7333 8.55264 5.79998 9.62234L14.1193 18.4567C15.4173 19.7568 17.4056 19.64 18.8566 18.1872C19.5068 17.5358 19.8716 16.5969 19.8579 15.6099C19.8443 14.6334 19.4627 13.6988 18.8107 13.0456L12.5232 6.35203C12.3482 6.1645 12.0548 6.15594 11.868 6.33185C11.6816 6.50801 11.6726 6.80192 11.8483 6.98876L18.1453 13.6925C18.6378 14.186 18.9204 14.886 18.9308 15.6229C18.9412 16.3602 18.6749 17.0553 18.2011 17.5303C17.311 18.4222 15.8619 18.8893 14.7845 17.8094L6.46541 8.97497C5.75011 8.25783 5.75034 7.10606 6.45614 6.39842C6.78712 6.0672 7.18 5.90587 7.59838 5.93115C8.01235 5.95642 8.43792 6.16873 8.79673 6.52847L15.4161 13.5739C15.656 13.8143 16.1381 14.4035 15.6474 14.8953C15.3695 15.1737 15.1744 15.1566 15.1102 15.1512C14.9268 15.135 14.7127 15.0082 14.4904 14.7852L9.50808 9.48632C9.33216 9.29927 9.03848 9.29023 8.8528 9.46662C8.66621 9.64231 8.65764 9.93669 8.8331 10.123L13.8245 15.4319C14.2169 15.8262 14.6181 16.0399 15.0265 16.0765C15.3452 16.1048 15.8192 16.0369 16.3027 15.552C17.0204 14.833 16.9311 13.7783 16.0814 12.9268L9.46197 5.88179C8.93349 5.35146 8.29537 5.04342 7.65517 5.00403C7.60811 5.00078 7.56108 4.99938 7.51402 4.99938Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="security-listItem-info">
                <span className="security-listItem-name">
                  Photos, videos and documents
                </span>
              </div>
            </div>

            <div
              className="security-settings location-listItem"
              role="listitem"
            >
              <div className="security-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="security-listItem-info">
                <span className="security-listItem-name">Location sharing</span>
              </div>
            </div>

            <div className="security-settings status-listItem" role="listitem">
              <div className="security-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14.4052 6.89854C11.3604 5.87425 8.03791 7.44317 6.98416 10.4028C5.93041 13.3624 7.54445 16.5921 10.5892 17.6164C13.634 18.6407 16.9565 17.0717 18.0102 14.1121"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.99805 12.2562C2.99805 10.836 3.32535 9.49294 3.90958 8.29314L5.15362 9.1782C4.7316 10.1232 4.49805 11.1644 4.49805 12.2562C4.49805 16.0659 7.34187 19.2599 11.1141 19.8968V21.4145C6.53772 20.7623 2.99805 16.9266 2.99805 12.2562ZM13.8423 21.4203C18.4387 20.7854 21.9996 16.9406 21.9996 12.2562C21.9996 12.0623 21.9935 11.8699 21.9815 11.6792L20.4953 12.5132C20.3703 16.2226 17.5497 19.2972 13.8423 19.9037V21.4203ZM12.4988 4.5C10.186 4.5 8.11118 5.44817 6.6543 6.95957L5.42032 6.08167C7.16316 4.18778 9.69376 3 12.4988 3C14.6695 3 16.6758 3.71125 18.2799 4.91056L16.8226 5.72839C15.578 4.95165 14.0951 4.5 12.4988 4.5Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="security-listItem-info">
                <span className="security-listItem-name">Status updates</span>
              </div>
            </div>

            <span className="learnMore">
              <a href="/">Learn More...</a>
            </span>
          </div>

          <div className="security-notification" onClick={handleCheckmarkClick}>
            <div className="info">
              <span className="title">
                Show security notification on this computer
              </span>
              <p className="sub-title">
                Get notified when your security code changes for a contact's
                phone, If you have multiple devices, this setting must be
                enabled on each device where you want to get notifications.
                <span className="learnMore">
                  <a href="/ ">Learn More...</a>
                </span>
              </p>
            </div>

            <div className="group-check">
              <input
                type="checkbox"
                id="securityNotification-check"
                aria-label="Check this box"
                checked={isChecked}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecuritySettings;
