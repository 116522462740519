import { useCallback, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import {
  chatAudioFilePath,
  chatDocumentPath,
  chatImagePath,
  chatThumbImagePath,
  chatThumbVideoImagePath,
  chatVideoPath,
  chat_thumb_image_base_url,
  placeholderImage,
  profile_image_base_url,
  userProfileImage,
  view_profile_image_base_url,
  post_video_url,
  post_first_video_url,
  post_image__url,
  group_profile_image_base_url,
  groupProfilePath,
  storyImagePath,
  story_image_base_url,
  story_video_base_url,
  story_thumb_base_url,
  postImagePath,
  postVideoThumb,
  groupPlaceholderImage,
  post_video_thumb_url,
  post_image_grid_url,
  post_video_thumb_grid_url,
  story_video_thumb_base_url,
  storyThumbVideoImagePath,
} from "../ServerConfiguration/awsConfiguration";
import readyToSend from "../assets/image/ready_to_send.png";
import Sent from "../assets/image/sent.png";
import Read from "../assets/image/read.png";
import Delivered from "../assets/image/Delivered.png";
import {
  DAYS,
  DOCSTYPE,
  DOCUMENT,
  DOWNLOAD,
  IMAGE,
  RECEIVER_AUDIO,
  RECEIVER_CONTACT,
  RECEIVER_DELETED,
  RECEIVER_DOCUMENT,
  RECEIVER_IMAGE,
  RECEIVER_LOCATION,
  RECEIVER_TEXT,
  RECEIVER_TEXT_REPLY,
  RECEIVER_VIDEO,
  SENDER_AUDIO,
  SENDER_CONTACT,
  SENDER_DELETED,
  SENDER_DOCUMENT,
  SENDER_IMAGE,
  SENDER_LOCATION,
  SENDER_TEXT,
  SENDER_TEXT_REPLY,
  SENDER_VIDEO,
  VIDEO,
  GROUP,
  CONTACT_ALL,
  ONLY_WITH_ME,
  CONTACT_EXCEPT,
  ONLY_SHARE_WITH,
  CONTACT_ALL_AND_GROUP,
  ONLY_WITH_ME_AND_GROUP,
  CONTACT_EXCEPT_AND_GROUP,
  CONTACT_SHARE_WITH_AND_GROUP,
  SETTINGSPRIVACY,
  ERROR,
} from "./enums";
import pdf from "../assets/image/Documents/PDF.png";
import ai from "../assets/image/Documents/AI.png";
import doc from "../assets/image/Documents/DOC.png";
import docx from "../assets/image/Documents/DOCX.png";
import html from "../assets/image/Documents/HTML.png";
import jpg from "../assets/image/Documents/JPG.png";
import png from "../assets/image/Documents/PNG.png";
import psd from "../assets/image/Documents/PSD.png";
import txt from "../assets/image/Documents/TXT.png";
import zip from "../assets/image/Documents/ZIP.png";
import defaultImage from "../assets/image/SVG_Images/group-defaultImage.svg";
import {
  setNotification,
  setToggeleViewOtherProfile,
  setTogglePostAction,
  setPostDetails,
} from "../Redux/actions";

let globalSelfUser;

export const TN_DEFAULT_IMAGE = profile_image_base_url + placeholderImage;
export const TN_DEFAULT_GROUP_IMAGE =
  profile_image_base_url + groupPlaceholderImage;
export const TN_IMAGE_BASE_URL = profile_image_base_url + userProfileImage;
export const TN_GROUP_IMAGE_BASE_URL =
  group_profile_image_base_url + groupProfilePath;
export const TN_CHAT_IMAGE_BASE_URL = profile_image_base_url + chatImagePath;
export const TN_CHAT_THUMB_IMAGE_BASE_URL =
  chat_thumb_image_base_url + chatThumbImagePath;
export const VIEW_TN_CHAT_IMAGE_BASE_URL =
  view_profile_image_base_url + chatImagePath;
export const TN_CLOUD_FRONT_BUCKET_DOCUMENT_BASE_URL =
  process.env.REACT_APP_CLOUDFRONT_BUCKET_VIDEO + chatDocumentPath;
export const TN_CLOUD_FRONT_BUCKET_VIDEO_BASE_URL =
  process.env.REACT_APP_CLOUDFRONT_BUCKET_VIDEO + "/" + chatVideoPath;
export const TN_THUMB_VIDEO_IMAGE_BASE_URL =
  profile_image_base_url + chatThumbVideoImagePath;
export const TN_CLOUD_FRONT_BUCKET_AUDIO_BASE_URL =
  process.env.REACT_APP_CLOUDFRONT_BUCKET_VIDEO + "/" + chatAudioFilePath;

const defaultOptions = {
  // maxSizeMB: 1,
  useWebWorker: true,
  maxWidthOrHeight: 920,
  alwaysKeepResolution: true,
};

function generateSessionId() {
  const timestamp = new Date().getTime();
  const random = Math.random().toString(36).substr(2);
  return `${timestamp}-${random}`;
}

export const getSessionId = () => {
  let sessionId = Cookies.get("sessionId");
  if (!sessionId) {
    sessionId = generateSessionId();
    Cookies.set("sessionId", sessionId);
  }
  return sessionId;
};

export const getDeviceId = () => {
  let deviceId = Cookies.get("deviceId");
  if (!deviceId) {
    deviceId = uuidv4();
    Cookies.set("deviceId", deviceId);
  }
  return deviceId;
};

export function compressFile(imageFile, options = defaultOptions) {
  return imageCompression(imageFile, options);
}

// for manage message send/receive status
export const getMessageStatus = (message) => {
  if (message?.status === "ReadyToSent") {
    return readyToSend;
  } else if (message?.status === "Sent") {
    return Sent;
  } else if (message?.status === "Read") {
    return Read;
  } else if (message?.status === "Delivered") {
    return Delivered;
  } else {
    return null;
  }
};

// for get self user
export const getSelfUser = (user) => {
  const selfUserId = localStorage.getItem("user_id");
  if (user?._id === selfUserId) {
    return user?.webName;
  } else {
    if (user?.contactStatus) {
      return user?.name;
    } else {
      return <>~{user?.name}</>;
    }
  }
};

// for get Group Member List
export const getGroupMemberList = (MemberArray, MemberContact, MemberUser) => {
  const GroupMemberArray = [];
  MemberArray.forEach((member) => {
    const contactInfo = MemberContact.find(
      (contact) => contact._id === member.contact
    );
    const userInfo = MemberUser.find((user) => user._id === contactInfo.user);
    const modifiedContactInfo = {
      ...contactInfo,
      contactname: contactInfo.name,
    };
    delete modifiedContactInfo.name;
    GroupMemberArray.push({ ...member, ...modifiedContactInfo, ...userInfo });
  });
  const selfMember = GroupMemberArray.find(
    (member) => member._id === globalSelfUser
  );
  const adminMemberList = GroupMemberArray.filter(
    (member) =>
      (member.isAdmin || member.isOwner) && member._id !== globalSelfUser
  );
  const savedMemberList = GroupMemberArray.filter(
    (member) =>
      member.status === "ADD" &&
      !member.isAdmin &&
      !member.isOwner &&
      member._id !== globalSelfUser
  );
  const nonSavedMemberList = GroupMemberArray.filter(
    (member) =>
      member.status !== "ADD" &&
      !member.isAdmin &&
      !member.isOwner &&
      member._id !== globalSelfUser
  );
  let modifiedSelfMember;
  if (selfMember) {
    modifiedSelfMember = {
      ...selfMember,
      contactname: "You",
    };
  }
  const combinedMemberArray = [
    modifiedSelfMember,
    ...adminMemberList,
    ...savedMemberList,
    ...nonSavedMemberList,
  ];
  return combinedMemberArray;
};

// for get common Group List
export const getCommonGroupList = (groupList, selfMemberId) => {
  let commonGroupIdList = [];
  for (const groupItem of groupList) {
    const groupMemberuserList = groupItem?.groupMemberuserList;
    if (
      groupMemberuserList &&
      groupMemberuserList.some((member) => member._id === selfMemberId)
    ) {
      commonGroupIdList.push(groupItem.inboxContact);
    }
  }
  return commonGroupIdList;
};

// for get message send/receive time
export const getTimeFromDate = (message) => {
  const date = new Date(message?.timestamp * 1000);
  return date
    .toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .slice(-10)
    .toUpperCase();
};

// for get message send/receive time to day and day to date
export const getTimetoDaytoDate = (timestamp, type) => {
  const date = new Date(timestamp * 1000);
  const today = new Date();
  const YESTERDAY = new Date();
  const nextWeek = new Date(today);
  YESTERDAY.setDate(today.getDate() - 1);
  nextWeek.setDate(today.getDate() - 7);
  const isToday = date.toDateString() === today.toDateString();
  const isYESTERDAY =
    date.toDateString() ===
    new Date(today.getTime() - 24 * 60 * 60 * 1000).toDateString(); // For tomorrow
  const isWithinNextWeek = date <= today && date > nextWeek;

  if (isToday) {
    // Display time for today
    return type === "chat"
      ? "Today"
      : date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  } else if (isYESTERDAY) {
    return "Yeasterday";
  } else if (isWithinNextWeek) {
    const dayOfWeek = DAYS[date.getDay()];
    return `${dayOfWeek}`;
  } else {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear() % 100;
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;
  }
};

// for get message send/receive date with time
export const getDateWithTime = (timeStamp) => {
  // const date = new Date(timeStamp * 1000);
  const date = new Date(timeStamp);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();

  if (isToday) {
    // Display time for today
    return (
      "Today at " +
      date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
    );
  } else if (isYesterday) {
    // Display "Yesterday" with time
    return (
      "Yesterday at " +
      date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
    );
  } else {
    // Display date with time
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear() % 100;
    return (
      `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year.toString().padStart(2, "0")} at  ` +
      date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
    );
  }
};

// for trim video time show
export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
};

// for get message send/receive time duration
export const convertSecondsToTimeFormat = (message) => {
  const minutes = Math.floor(message?.mediaLength / 60);
  const remainingSeconds = message?.mediaLength % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

// for get message send/receive file size
export const calculateFileSize = (fileSize) => {
  if (fileSize) {
    if (fileSize < 1024) {
      return Math.floor(fileSize) + " kb";
    } else if (fileSize < 1024 * 1024) {
      return Math.floor(fileSize / 1024) + " kb";
    } else {
      return Math.floor(fileSize / (1024 * 1024)) + " mb";
    }
  }
};

// for get message text and handle if message is too long
export const getMessageText = (fullMessage, message) => {
  if (fullMessage) {
    return message?.message;
  } else {
    return message?.message?.slice(0, 650);
  }
};

// for get message type
export const getMessageType = (message) => {
  const messageType = message?.type;
  if (
    messageType === SENDER_TEXT ||
    messageType === RECEIVER_TEXT ||
    messageType === SENDER_DELETED ||
    messageType === RECEIVER_DELETED
  ) {
    return "Text";
  } else if (
    messageType === SENDER_LOCATION ||
    messageType === RECEIVER_LOCATION ||
    messageType === SENDER_DELETED ||
    messageType === RECEIVER_DELETED
  ) {
    return "Location";
  } else if (
    messageType === SENDER_IMAGE ||
    messageType === RECEIVER_IMAGE ||
    messageType === SENDER_DELETED ||
    messageType === RECEIVER_DELETED
  ) {
    return "Image";
  } else if (
    messageType === SENDER_VIDEO ||
    messageType === RECEIVER_VIDEO ||
    messageType === SENDER_DELETED ||
    messageType === RECEIVER_DELETED
  ) {
    return "Video";
  } else if (
    messageType === SENDER_AUDIO ||
    messageType === RECEIVER_AUDIO ||
    messageType === SENDER_DELETED ||
    messageType === RECEIVER_DELETED
  ) {
    return "Audio";
  } else if (
    messageType === SENDER_CONTACT ||
    messageType === RECEIVER_CONTACT ||
    messageType === SENDER_DELETED ||
    messageType === RECEIVER_DELETED
  ) {
    return "Contact";
  } else if (
    messageType === SENDER_DOCUMENT ||
    messageType === RECEIVER_DOCUMENT ||
    messageType === SENDER_DELETED ||
    messageType === RECEIVER_DELETED
  ) {
    return "document";
  } else if (
    messageType === SENDER_TEXT_REPLY ||
    messageType === RECEIVER_TEXT_REPLY ||
    messageType === SENDER_DELETED ||
    messageType === RECEIVER_DELETED
  ) {
    return "ReplyTextMessage";
  }
};

// for get replyed message type
export const getReplyType = (message, replyedMassage) => {
  if (
    message?.type === RECEIVER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_TEXT ||
      replyedMassage?.type === RECEIVER_TEXT)
  ) {
    return RECEIVER_TEXT_REPLY;
  } else if (
    message?.type === SENDER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_TEXT ||
      replyedMassage?.type === RECEIVER_TEXT)
  ) {
    return SENDER_TEXT_REPLY;
  } else if (
    message?.type === RECEIVER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_IMAGE ||
      replyedMassage?.type === RECEIVER_IMAGE)
  ) {
    return "ReceiverReplyImage";
  } else if (
    message?.type === SENDER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_IMAGE ||
      replyedMassage?.type === RECEIVER_IMAGE)
  ) {
    return "SenderReplyImage";
  } else if (
    message?.type === RECEIVER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_VIDEO ||
      replyedMassage?.type === RECEIVER_VIDEO)
  ) {
    return "ReceiverReplyVideo";
  } else if (
    message?.type === SENDER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_VIDEO ||
      replyedMassage?.type === RECEIVER_VIDEO)
  ) {
    return "SenderReplyVideo";
  } else if (
    message?.type === RECEIVER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_CONTACT ||
      replyedMassage?.type === RECEIVER_CONTACT)
  ) {
    return "ReceiverReplyContact";
  } else if (
    message?.type === SENDER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_CONTACT ||
      replyedMassage?.type === RECEIVER_CONTACT)
  ) {
    return "SenderReplyContact";
  } else if (
    message?.type === RECEIVER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_DOCUMENT ||
      replyedMassage?.type === RECEIVER_DOCUMENT)
  ) {
    return "ReceiverReplyDocument";
  } else if (
    message?.type === SENDER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_DOCUMENT ||
      replyedMassage?.type === RECEIVER_DOCUMENT)
  ) {
    return "SenderReplyDocument";
  } else if (
    message?.type === RECEIVER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_AUDIO ||
      replyedMassage?.type === RECEIVER_AUDIO)
  ) {
    return "ReceiverReplyAudio";
  } else if (
    message?.type === SENDER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_AUDIO ||
      replyedMassage?.type === RECEIVER_AUDIO)
  ) {
    return "SenderReplyAudio";
  } else if (
    message?.type === RECEIVER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_LOCATION ||
      replyedMassage?.type === RECEIVER_LOCATION)
  ) {
    return "ReceiverReplyLocation";
  } else if (
    message?.type === SENDER_TEXT_REPLY &&
    (replyedMassage?.type === SENDER_LOCATION ||
      replyedMassage?.type === RECEIVER_LOCATION)
  ) {
    return "SenderReplyLocation";
  }
};

// for get delete message type
export const getDeleteMessageType = (message) => {
  const messageType = message?.type;
  if (
    messageType === SENDER_TEXT ||
    messageType === SENDER_IMAGE ||
    messageType === SENDER_VIDEO ||
    messageType === SENDER_CONTACT ||
    messageType === SENDER_AUDIO ||
    messageType === SENDER_DOCUMENT ||
    messageType === SENDER_LOCATION
  ) {
    return SENDER_DELETED;
  } else if (
    messageType === RECEIVER_TEXT ||
    messageType === RECEIVER_IMAGE ||
    messageType === RECEIVER_VIDEO ||
    messageType === RECEIVER_CONTACT ||
    messageType === RECEIVER_AUDIO ||
    messageType === RECEIVER_DOCUMENT ||
    messageType === RECEIVER_LOCATION
  ) {
    return RECEIVER_DELETED;
  }
};

// for get media file type
export const getMediaType = (media) => {
  const fileNames = Array.from(media).map((file) => file?.name);
  const extension = fileNames[0]?.split(".");
  const fileType = Array.from(media).map((file) => file?.type);
  const types = fileType[0]?.split("/");
  if (extension?.length > 1) {
    if (types[0] === "image") {
      return IMAGE;
    } else if (types[0] === "video") {
      return VIDEO;
    } else if (types[0] === "application" || types[0] === "text") {
      const mediaType = DOCSTYPE.find((type) => {
        return type === extension[extension?.length - 1];
      });
      return [DOCUMENT, mediaType];
    }
  }
  return "";
};

// for get user profile picture
export const getUserProfilePic = (picture) => {
  return picture ? profile_image_base_url + picture : TN_DEFAULT_IMAGE;
};

//for get sponsors profile picture
export const getSponsorProfilePic = (picture) => {
  return picture ? profile_image_base_url + picture : defaultImage;
};

// for get user profile picture
export const getGroupProfilePic = (picture, setImageError) => {
  if (!picture || setImageError) {
    return TN_DEFAULT_GROUP_IMAGE;
  }
  return group_profile_image_base_url + picture;
};

//for format number
export const formatNumber = (number) => {
  let formattedNumber;

  if (number >= 1000000) {
    formattedNumber = (number / 1000000).toFixed(1);
  } else if (number >= 10000) {
    formattedNumber = (number / 1000).toFixed(1);
  } else {
    return number?.toString();
  }

  if (formattedNumber.endsWith(".0")) {
    formattedNumber = formattedNumber.slice(0, -2);
  }

  return formattedNumber + (number >= 1000000 ? "M" : "K");
};

// for get tepars image or video
// export const getTepars = (media, post) => {
//   const test = media?.url.split(".");
//   if (media?.type === "Image") {
//     return post_image__url + media?.url;
//   } else if (media?.type === "Video") {
//     if (post?.isFirstTime) {
//       return post_first_video_url + media?.url;
//     } else {
//       // return post_video_url + media?.url;
//       return post_video_url + test[0] + "/MP4/" + test[0] + ".mp4";
//     }
//   }
// };

export const getTepars = (media, post) => {
  const test = media?.url.split(".");
  const formate = media?.url.split(".")[1];
  if (media?.type === "Image") {
    return post_image__url + media?.url;
  } else if (media?.type === "Video") {
    if (formate === "m3u8") {
      return post_first_video_url + test[0] + "/" + media?.url;
    } else {
      return post_video_url + test[0] + "/MP4/" + test[0] + ".mp4";
    }
  }
};

// for get stories image or video
export const getStories = (currentMedia) => {
  if (currentMedia?.mediaType === "Image") {
    return story_image_base_url + storyImagePath + currentMedia?.media;
  } else if (currentMedia?.mediaType === "Video") {
    return story_video_base_url + currentMedia?.media?.split(".")[0] + ".mp4";
  }
};

// for get tepars image or video thumb for grid view
export const getGridTepars = (media) => {
  if (media?.type === "Image" && media?.url) {
    return `${post_image_grid_url}${media?.url}`;
  } else if (media?.type === "Video" && media?.thumbnail) {
    return `${post_video_thumb_grid_url}${media?.thumbnail}`;
  } else {
    return null;
  }
};

// for get stories image or video
export const getStory = (media) => {
  const test = media.media.split(".");
  if (media?.mediaType === "Image") {
    return story_image_base_url + storyImagePath + media?.media;
  } else if (media?.mediaType === "Video") {
    return story_video_base_url + test[0] + "/MP4/" + test[0] + ".mp4";
  }
};

// for split user id
export const splitUserID = (id) => {
  if (id) {
    const _id = id.split("||");
    return _id[0].trim();
  }
};

// for split user id
export const joinUserID = (id, ownerId) => {
  if (id && ownerId) {
    return id + "||" + ownerId;
  }
};

// for get today's date in "YYYY-MM-DD" format
export const getDate = (ts) => {
  if (ts) {
    const today = new Date(ts);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = today.getDate().toString().padStart(2, "0");
    const toDate = `${year}-${month}-${day}`;
    return toDate.toString();
  } else {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = today.getDate().toString().padStart(2, "0");
    const toDate = `${year}-${month}-${day}`;
    return toDate.toString();
  }
};

// for get today's date in "DD-MM-YYYY" format
export const getDateForward = (ts) => {
  if (ts) {
    const today = new Date(ts);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = today.getDate().toString().padStart(2, "0");
    const toDate = `${day}/${month}/${year}`;
    return toDate.toString();
  } else {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = today.getDate().toString().padStart(2, "0");
    const toDate = `${day}/${month}/${year}`;
    return toDate.toString();
  }
};

// for create user in realm mongodb cloud
export const RUserModel = (ownerId, user) => {
  return {
    _id: joinUserID(user?.userId, ownerId),
    owner_id: ownerId,
    cId: "",
    countryCode: user?.countryCode,
    createAt: "",
    email: "",
    isUserVerified: true,
    name: "",
    onlineStatus: 0,
    phone: user?.phone,
    profilePic: user?.profilePic,
    profilePicData: null,
    typingStatus: "paused",
    updateAt: "",
    lastSeenAt: 0,
  };
};

// for create message in realm mongodb cloud
export const RInboxModel = (inboxId, ownerId, contactObj, lastMessage) => {
  return {
    _id: inboxId.toString(),
    owner_id: ownerId,
    contact: contactObj,
    deleted: false,
    lastMessage: lastMessage,
    lastMessageTimeStamp: lastMessage?.timestamp,
    type: "user",
    unreadCount: 0,
    isArchived: false,
    isPinned: false,
  };
};

// for create message in realm mongodb cloud
export const RMessageModel = (
  loggedInUser,
  ownerId,
  msg,
  msgType,
  toId,
  contact,
  media,
  ids
) => {
  const { v4 } = require("uuid");
  // const messageId = v4();
  const msgId = "";
  // const _id = joinUserID(msgId, ownerId);
  const d = new Date();
  const n = d.getTime() / 1000;
  return {
    _id: joinUserID(msgId, ownerId),
    owner_id: ownerId,
    date: getDate(),
    from: loggedInUser,
    message: msg,
    messageParentType: "user",
    senderID: loggedInUser,
    status: "ReadyToSent",
    timestamp: n,
    to: toId,
    type: msgType,
    contact: contact,
    media: media ? media : null,
    messageReferance: ids,
    isMessageDeleted: false,
    isStarMessage: false,
    messageReceivedTimestamp: n,
    forwardedIds: null,
  };
  // status: online && connected ? "Sent" : "ReadyToSent",
};

// for create message contact in realm mongodb cloud
export const RMessageContactModel = (
  ownerId,
  name,
  phone,
  countryCode,
  profile,
  userId,
  messageId
) => {
  // const { v4 } = require("uuid");
  // const uuid = v4();
  // const rMsgContactId = new Realm.BSON.ObjectId().toString();
  const rMsgContactId = "";
  return {
    _id: joinUserID(rMsgContactId, ownerId),
    countryCode: countryCode,
    messageId: messageId,
    name: name,
    number: phone,
    owner_id: ownerId,
    profilePic: profile,
    userId: userId,
  };
};

// for create message Media in realm mongodb cloud
export const RMessageMediaModel = (
  ownerId,
  fileSize,
  mediaLength,
  mediaUrl,
  _id,
  thumbUrl
) => {
  const { v4 } = require("uuid");
  const uuid = v4();
  const rMsgMediaId = "";
  return {
    _id: joinUserID(rMsgMediaId, ownerId),
    owner_id: ownerId,
    messageId: _id,
    fileSize: fileSize,
    mediaLength: ~~mediaLength,
    mediaStatus: DOWNLOAD,
    mediaUrl: mediaUrl,
    oldProgress: 0.0,
    progress: 0.0,
    thumbUrl: thumbUrl,
    videoTrimEndTime: 0.0,
    videoTrimStartTime: 0.0,
  };
};

// for create message location in realm  mongodb cloud
export const RMessageLocationModel = (_id, ownerId, msgObj) => {
  const { v4 } = require("uuid");
  const uuid = v4();
  const rMsgLocationId = "";
  return {
    _id: joinUserID(rMsgLocationId, ownerId),
    owner_id: ownerId,
    address: msgObj?.address,
    latitude: msgObj?.latitude,
    longitude: msgObj?.longitude,
    mediaUrl: msgObj?.mediaUrl,
    messageId: _id,
  };
};

// for create contact in realm mongodb cloud
export const RContactModel = (ownerId, phone, countryCode, userObj) => {
  return {
    _id: joinUserID(phone, ownerId),
    owner_id: ownerId,
    countryCode: countryCode,
    groupNameBgColor: "GroupNameBG1",
    groupNameColor: "GroupName3",
    name: "",
    status: "",
    user: userObj,
  };
};

// for toggle status
export const toggleUserStatus = (status, typingStatus) => {
  return typingStatus === "composing"
    ? "Typing..."
    : status === 1
    ? "Online"
    : status === 0
    ? "Offline"
    : "";
};

// for process the image
export const processImage = async (file, maxWidth = 800, maxHeight = 600) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const img = await createImageBitmap(new Blob([e.target.result]));

        let width = img.width;
        let height = img.height;

        // Resize the image if it's larger than the specified maxWidth or maxHeight
        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;

          if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
          }

          if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const modifiedFile = new File([blob], file.name, { type: file.type });
          resolve(modifiedFile);
        }, file.type);
      } catch (error) {
        console.error("Error processing image:", error);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

// for compress file
export const handleCompressFile = async (file) => {
  return new Promise((resolve, reject) => {
    if (file) {
      try {
        const compressedImageFile = compressFile(file);
        resolve(compressedImageFile);
      } catch (error) {}
    } else {
      reject("No media provided");
    }
  });
};

// for compress thumb file
export const handleCompressThumbFile = async (imageFile) => {
  // Convert compressed file to data URL
  const reader = new FileReader();
  reader.readAsDataURL(imageFile);

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const dataURL = reader.result;
      const image = new Image();

      image.onload = () => {
        // Resize to 1:1 aspect ratio
        const resizedCanvas = document.createElement("canvas");
        const ctx = resizedCanvas.getContext("2d");

        const imageSize = 5; // Adjust the size as needed
        resizedCanvas.width = imageSize;
        resizedCanvas.height = imageSize;
        ctx.drawImage(image, 0, 0, imageSize, imageSize);

        // Convert resized canvas to file
        resizedCanvas.toBlob((blob) => {
          const resizedFile = new File([blob], imageFile.name, {
            type: imageFile.type,
          });
          resolve(resizedFile);
        }, imageFile.type);
      };
      image.src = dataURL;
    };
    reader.onerror = (error) => reject(error);
  });
};

// for get video duration
export const getVideoDuration = async (media) => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(media);
    if (media) {
      const video = document.createElement("video");
      video.src = url;
      video.preload = "metadata";

      video.addEventListener("loadedmetadata", function () {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        // const width = video.videoWidth;
        // const height = video.videoHeight;
        resolve(duration);
        // resolve({duration,height,width});
      });
    } else {
      reject("No media provided");
    }
  });
};

// for capture thumb from video
export const captureVideoThumbnail = async (media) => {
  return new Promise((resolve, reject) => {
    let video = document.createElement("video");

    const captureThumbnail = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const src = canvas.toDataURL("image/png");
      video.pause();
      resolve(src); // Resolve the promise when thumbnail is set
    };

    if (media) {
      if (!video) {
        video = document.createElement("video");
      }
      video.src = URL.createObjectURL(media);
      video.muted = true;
      video.onloadedmetadata = () => {
        setTimeout(() => {
          captureThumbnail();
        }, 500);
        video.play();
      };

      return () => {
        video.removeEventListener("loadedmetadata", captureThumbnail);
        URL.revokeObjectURL(video.src);
        video.pause();
        video = null;
      };
    } else {
      reject("No media provided");
    }
  });
};

// for thumbnail video 5 thumb make
// export const captureThumbnails = (interval, onlyVideoRef) => {
//     let time = 0;
//     let captureCount = 0;
//     const newThumbnails = [];
//     const canvasRef = document.createElement("canvas");
//     // const canvasRef = document.createElement("canvas");
//     // let video = document.createElement("video");

//     const capture = () => {
//       onlyVideoRef.current.currentTime = time;
//       onlyVideoRef.current.onseeked = () => {
//         // Set the size of the thumbnail
//         const width = 200;
//         const height = 250;

//         // Ensure the canvas is the correct size
//         canvasRef.width = width;
//         canvasRef.height = height;
//         const ctx = canvasRef.getContext('2d');
//         ctx.drawImage(onlyVideoRef.current, 0, 0, width, height);

//         const image = canvasRef.toDataURL('image/jpeg', 0.7); // Adjust the quality to reduce size
//         newThumbnails.push(image);

//         time += interval;
//         captureCount += 1;
//         if (time <= onlyVideoRef.current.duration) {
//           capture();
//         } else {
//           // setThumbnails(newThumbnails);
//           return  newThumbnails;
//         }
//       };
//     };

//     capture();
//   };

// for display file type icon in document message
export const getFileTypeIcon = (fileTypes) => {
  switch (fileTypes) {
    case "png":
      return png;
    case "pdf":
      return pdf;
    case "ai":
      return ai;
    case "doc":
      return doc;
    case "docx":
      return docx;
    case "psd":
      return psd;
    case "txt":
      return txt;
    case "html":
      return html;
    case "JPG":
      return jpg;
    case "zip":
      return zip;
    default:
      return doc;
  }
};

// const timestamp = message.timeStamp;
// const date = new Date(timestamp * 1000);
// const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
//   .toString()
//   .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
//   .getHours()
//   .toString()
//   .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
//   .getSeconds()
//   .toString()
//   .padStart(2, "0")}`;
// console.log(formattedDate, 28);

// const options = { year: "numeric", month: "short", day: "2-digit" };
// // Calculate the difference in days between currentDate and messageDate
// let formattedDate;
// if (d.toDateString() === date.toDateString()) {
//   // Display "today" for the current date
//   formattedDate = "today";
// } else {
//   // Display "8 July 2023" for any other date
//   formattedDate = date.toLocaleDateString("en-US", options);
// }

// for create thumbnail of stories
export const generateThumbnail = (storyData) => {
  const test = storyData.media.split(".");
  if (storyData?.mediaType === "Image") {
    storyData.thumbnail =
      story_thumb_base_url + storyImagePath + storyData?.media;
  } else if (storyData?.mediaType === "Video") {
    storyData.thumbnail =
      story_video_thumb_base_url + storyThumbVideoImagePath + test[0] + ".jpg";
  }

  return storyData;
};

// for create thumbnail of stories
export const generatePostThumbnail = (postData) => {
  if (postData?.type === "Image") {
    return post_image__url + postData?.thumbnail;
  } else if (postData?.type === "Video") {
    return post_video_thumb_url + postData?.thumbnail;
  }
};

// for convart timestamp to particular time format
export const convertToAgoFormat = (timestamp) => {
  const currentDate = new Date();
  const timestampDate = new Date(timestamp);
  const timeDifference = currentDate - timestampDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years >= 1) {
    return `${years} years ago`;
  } else if (months >= 1) {
    return `${months} months ago`;
  } else if (weeks >= 1) {
    return `${weeks} weeks ago`;
  } else if (days >= 1) {
    // return `${days} days ago`;
    // return `${days} ${days = 1 && 'day'} ${days > 1 && 'days'} ago`;
    return `${days} ${days > 1 ? "Days" : "Day"} ago`;
  } else if (hours >= 1) {
    return `${hours} hours ago`;
  } else if (minutes >= 1) {
    return `${minutes} minutes ago`;
  } else if (seconds >= 1) {
    return `${seconds} seconds ago`;
  } else {
    return "Just now";
  }
};

// for video constratints at live capture image
export const VideoConstraints = {
  width: 550,
  height: 390,
  facingMode: "user",
};

// for convert base64 string to particular file format
export function base64StringToFile(fileValue, name) {
  const fileName = name;
  const fileType = "image/jpg";
  const base64Image = fileValue.split(",")[1];
  const byteCharacters = atob(base64Image);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: fileType });
  const lastModified = new Date().getTime();
  const file = new File([blob], fileName, {
    lastModified: lastModified,
    lastModifiedDate: new Date(lastModified),
    name: fileName,
    size: blob.size,
    type: fileType,
    webkitRelativePath: "",
  });

  return file;
}

// for openfire contact object
export const XmppContactObject = (contacts) => {
  const contactsObj = [];
  contacts.forEach((contact) =>
    contactsObj.push({
      contactName: contact?.name,
      contactNumber: contact?.number,
      countryCode: contact?.countryCode,
      profilePic: contact?.profilePic,
      contactUserId: contact?.userId,
    })
  );
  return contactsObj;
};

// for openfire media object
export const XmppMediaObject = (media) => {
  return {
    fileSize: media?.fileSize,
    mediaLength: media.mediaLength,
    mediaUrl: media.mediaUrl,
  };
};

// for display message date toggle
export const MessageDateDisplay = (date) => {
  let currentDate = null;
  const messageDate = new Date(date).toDateString();
  const renderDateHeader = currentDate !== messageDate;
  currentDate = messageDate;
  return renderDateHeader;
};

// for manage tepars privacy
export const ManageTeparsPrivacy = (privacy, group, editPostData) => {
  if (privacy === ONLY_WITH_ME && group > 0) {
    return ONLY_WITH_ME_AND_GROUP;
  } else if (privacy === ONLY_SHARE_WITH && group > 0) {
    return CONTACT_SHARE_WITH_AND_GROUP;
  } else if (privacy === CONTACT_EXCEPT && group > 0) {
    return CONTACT_EXCEPT_AND_GROUP;
  } else if (privacy === CONTACT_ALL && group > 0) {
    return CONTACT_ALL_AND_GROUP;
  } else if (privacy === ONLY_SHARE_WITH) {
    return ONLY_SHARE_WITH;
  } else if (privacy === CONTACT_EXCEPT) {
    return CONTACT_EXCEPT;
  } else if (privacy === ONLY_WITH_ME) {
    return ONLY_WITH_ME;
  } else if (privacy === GROUP) {
    return GROUP;
  } else if (privacy === CONTACT_ALL) {
    return CONTACT_ALL;
  } else if (editPostData) {
    return editPostData?.privacy;
  } else {
    return "";
  }
};

// for manage tepars privacy value
export const ManageTeparsPrivacyValue = (EditPrivacy) => {
  if (EditPrivacy === "ContactAll") {
    return CONTACT_ALL;
  } else if (
    EditPrivacy === "ContactExcept" ||
    EditPrivacy === "Contact Except"
  ) {
    return CONTACT_EXCEPT;
  } else if (EditPrivacy === "onlyWithMe" || EditPrivacy === "Only With Me") {
    return ONLY_WITH_ME;
  } else if (
    EditPrivacy === "ContactShareWith" ||
    EditPrivacy === "Only Share With"
  ) {
    return ONLY_SHARE_WITH;
  } else {
    return null;
  }
};

// for get selected last seen and online value
export const getLastseenNOnlineValue = (settings) => {
  if (settings?.OnlineSeen === settings?.lastSeen) {
    return SETTINGSPRIVACY[settings?.lastSeen];
  }
  if (settings?.OnlineSeen === 1) {
    if (settings?.LastContactExcept?.length > 0) {
      return settings?.lastSeen === 2
        ? `${settings?.LastContactExcept?.length} ${
            SETTINGSPRIVACY[settings?.lastSeen]
          }`
        : SETTINGSPRIVACY[settings?.lastSeen];
    } else {
      return settings?.lastSeen === 2
        ? ` ${SETTINGSPRIVACY[1]}`
        : SETTINGSPRIVACY[settings?.lastSeen];
    }
  } else {
    if (settings?.LastContactExcept?.length > 0) {
      return settings?.lastSeen === 2
        ? `${settings?.LastContactExcept?.length} ${
            SETTINGSPRIVACY[settings?.lastSeen]
          }` +
            ", " +
            SETTINGSPRIVACY[settings?.OnlineSeen]
        : SETTINGSPRIVACY[settings?.lastSeen] +
            ", " +
            SETTINGSPRIVACY[settings?.OnlineSeen];
    } else {
      return settings?.lastSeen === 2
        ? `${SETTINGSPRIVACY[1]}` + ", " + SETTINGSPRIVACY[settings?.OnlineSeen]
        : SETTINGSPRIVACY[settings?.lastSeen] +
            ", " +
            SETTINGSPRIVACY[settings?.OnlineSeen];
    }
  }
};

// for get selected profile photo value
export const getProfilePhotoValue = (settings) => {
  if (settings?.ProfilePhoto === 2) {
    if (settings?.ProfileContactExcept?.length > 0) {
      return `${settings?.ProfileContactExcept?.length} ${
        SETTINGSPRIVACY[settings?.ProfilePhoto]
      }`;
    } else {
      return `${SETTINGSPRIVACY[1]}`;
    }
  } else {
    return SETTINGSPRIVACY[settings?.ProfilePhoto];
  }
};

// for get selected defailt message timer value
export const getDefaultMessageTimerValue = (settings) => {
  if (settings?.disappearingMessages === 24) {
    return "24 hours";
  } else if (settings?.disappearingMessages === 168) {
    return " 7 days";
  } else if (settings?.disappearingMessages === 2160) {
    return "90 days";
  } else if (settings?.disappearingMessages === 0) {
    return "Off";
  }
};

// for get commnet in notification
export const getComment = (message) => {
  const regex = /commented:\s*(.*)/;
  const match = message.match(regex);
  if (match) {
    const comment = match[1];
    return comment;
  }
  return "";
};

// for get mention commnet in notification
export const getTagComment = (message) => {
  const parts = message?.split(/\|+|##/);
  return parts[parts?.length - 1];
};

// for get browser name
export const getBrowserName = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  let myArray = [];

  if (navigator.brave || /Brave/i.test(userAgent)) {
    myArray = userAgent.split("Chrome/");
    myArray = myArray[1].split(" ");
    return { browser: "Brave", version: myArray[0] };
  }

  if (/Chrome/i.test(userAgent) && !/Edg/i.test(userAgent)) {
    myArray = userAgent.split("Chrome/");
    myArray = myArray[1].split(" ");
    return { browser: "Google Chrome", version: myArray[0] };
  }
  if (/Firefox/i.test(userAgent)) {
    myArray = userAgent.split("Firefox/");
    myArray = myArray[1].split(" ");
    return { browser: "Mozilla Firefox", version: myArray[0] };
  }
  if (/Safari/i.test(userAgent) && !/Edg/i.test(userAgent)) {
    myArray = userAgent.split("Safari/");
    myArray = myArray[1].split(" ");
    return { browser: "Safari", version: myArray[0] };
  }
  if (/Edg/i.test(userAgent)) {
    myArray = userAgent.split("Edg/");
    myArray = myArray[1].split(" ");
    return { browser: "Microsoft Edge", version: myArray[0] };
  }
  if (/MSIE|Trident/i.test(userAgent)) {
    if (/MSIE/i.test(userAgent)) {
      myArray = userAgent.split("MSIE/");
      myArray = myArray[1].split(" ");
      return { browser: "Internet Explorer", version: myArray[0] };
    }
    if (/Trident/i.test(userAgent)) {
      myArray = userAgent.split("Trident/");
      myArray = myArray[1].split(" ");
      return { browser: "Internet Explorer", version: myArray[0] };
    }
  }
  if (/Opera/i.test(userAgent)) {
    myArray = userAgent.split("Opera/");
    myArray = myArray[1].split(" ");
    return { browser: "Opera", version: myArray[0] };
  }

  return { browser: "unknown", version: 0 };
};

// for get device os
export const getDeviceOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/Windows/.test(userAgent)) {
    return "Windows";
  } else if (/Macintosh|Mac OS/.test(userAgent)) {
    return "macOS";
  } else if (/Linux/.test(userAgent)) {
    return "Linux";
  } else {
    return "unknown";
  }
};

// for get group members name

// export const getGroupMembersName = (group) => {
//   return group?.memberList.map((member) => member.name).join(", ");
// };

export const getGroupMembersName = (memberList) => {
  const userId = localStorage.getItem("user_id");
  if (!memberList || !userId) return "";

  const currentUser = memberList?.find((member) => member?._id === userId);
  const otherMembers = memberList?.filter((member) => member?._id !== userId);

  const formattedMembers = currentUser
    ? ["You", ...otherMembers?.map((member) => member?.name)]
    : otherMembers?.map((member) => member?.name);

  return formattedMembers?.join(", ");
};

// for render no data text in notifications type
export const getNotifyNoDataText = (type) => {
  if (type === "repost") {
    return "No reposted posts notification yet";
  } else if (type === "newUser") {
    return "No new user notification yet";
  } else if (type === "comments") {
    return " No comments notification yet";
  } else if (type === "like") {
    return "No Teps notification yet";
  } else if (type === "all") {
    return "No notification yet";
  }
};

// for make link accessible
export const convertToFullURL = (url) => {
  if (url?.startsWith("http")) {
    return url;
  } else {
    return "http://" + url;
  }
};

// for get user details and fetch according data
export const getSelectedUserDetails = async (
  userId,
  dispatch,
  setSelectedMenu
) => {
  const user_Id = localStorage.getItem("user_id");

  if (user_Id === userId) {
    setSelectedMenu("profile");
    dispatch(setNotification(false));
    dispatch(setTogglePostAction(null));
    dispatch(setPostDetails(null));
  } else {
    dispatch(setToggeleViewOtherProfile(true));
    localStorage.setItem("other_user_id", userId);
  }
};

// Working
export const useSwiperSlider = (sliderRef, sliderWrapperRef, postLength) => {
  // Variables to track touch positions
  let startX = 0;
  let startY = 0;
  let currentX = 0;
  let currentY = 0;

  const wheelDelta = useRef(0);
  const isWheeling = useRef(false);

  const handleWheel = useCallback(
    (e) => {
      if (postLength <= 1) return;

      const horizontalSwipe = Math.abs(e.deltaX) > Math.abs(e.deltaY);

      if (!horizontalSwipe) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      if (!isWheeling.current) {
        isWheeling.current = true;
        wheelDelta.current = 0;
      }

      wheelDelta.current += e.deltaX;

      const swipeThreshold = 120;

      if (Math.abs(wheelDelta.current) > swipeThreshold) {
        if (wheelDelta.current > 0 && sliderRef.current) {
          sliderRef.current.slickNext();
        } else if (wheelDelta.current < 0 && sliderRef.current) {
          sliderRef.current.slickPrev();
        }
        wheelDelta.current = 0;
      }
    },
    [postLength]
  );

  const handleTouchStart = useCallback((e) => {
    const touch = e.touches[0];
    startX = touch.clientX;
    startY = touch.clientY;
  }, []);

  const handleTouchMove = useCallback(
    (e) => {
      const touch = e.touches[0];
      currentX = touch.clientX;
      currentY = touch.clientY;

      const deltaX = currentX - startX;
      const deltaY = currentY - startY;

      const horizontalSwipe = Math.abs(deltaX) > Math.abs(deltaY);

      if (!horizontalSwipe) return; // Ignore vertical swipes (for scrolling)

      e.preventDefault();

      const swipeThreshold = 50; // Adjust this threshold as needed

      if (Math.abs(deltaX) > swipeThreshold) {
        if (deltaX > 0 && sliderRef.current) {
          sliderRef.current.slickPrev();
        } else if (deltaX < 0 && sliderRef.current) {
          sliderRef.current.slickNext();
        }
        startX = currentX; // Reset startX to avoid multiple slides at once
      }
    },
    [postLength]
  );

  const handleTouchEnd = useCallback(() => {
    startX = 0;
    startY = 0;
  }, []);

  const handleWheelEnd = useCallback(() => {
    isWheeling.current = false;
    wheelDelta.current = 0;
  }, []);

  useEffect(() => {
    const sliderElement = sliderWrapperRef.current
      ? sliderWrapperRef.current.querySelector(".slick-list")
      : null;

    if (sliderElement && postLength > 1) {
      // Wheel event for desktop devices
      sliderElement.addEventListener("wheel", handleWheel, { passive: false });
      sliderElement.addEventListener("mouseleave", handleWheelEnd);

      // Touch events for mobile and tablet devices
      sliderElement.addEventListener("touchstart", handleTouchStart, {
        passive: true,
      });
      sliderElement.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
      sliderElement.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener("wheel", handleWheel);
        sliderElement.removeEventListener("mouseleave", handleWheelEnd);

        sliderElement.removeEventListener("touchstart", handleTouchStart);
        sliderElement.removeEventListener("touchmove", handleTouchMove);
        sliderElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, [
    handleWheel,
    handleWheelEnd,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    postLength,
  ]);
};

//Pinch to Zoom
// export const useSwiperSlider = (sliderRef, sliderWrapperRef, postLength) => {
//   let startX = 0,
//     startY = 0,
//     currentX = 0,
//     currentY = 0;
//   let pinchStartDistance = 0; // To track the initial distance between two fingers
//   let scale = 1; // To track the zoom level
//   let lastScale = 1; // Track the last scale to avoid cumulative zooming

//   const wheelDelta = useRef(0);
//   const isWheeling = useRef(false);

//   const handleWheel = useCallback(
//     (e) => {
//       if (postLength <= 1) return;
//       const horizontalSwipe = Math.abs(e.deltaX) > Math.abs(e.deltaY);
//       if (!horizontalSwipe) return;
//       e.preventDefault();
//       e.stopPropagation();
//       if (!isWheeling.current) {
//         isWheeling.current = true;
//         wheelDelta.current = 0;
//       }
//       wheelDelta.current += e.deltaX;
//       const swipeThreshold = 120;
//       if (Math.abs(wheelDelta.current) > swipeThreshold) {
//         if (wheelDelta.current > 0 && sliderRef.current) {
//           sliderRef.current.slickNext();
//         } else if (wheelDelta.current < 0 && sliderRef.current) {
//           sliderRef.current.slickPrev();
//         }
//         wheelDelta.current = 0;
//       }
//     },
//     [postLength]
//   );

//   const handleTouchStart = useCallback(
//     (e) => {
//       if (e.touches.length === 2) {
//         // Two-finger pinch start
//         const [touch1, touch2] = e.touches;
//         pinchStartDistance = Math.sqrt(
//           Math.pow(touch2.clientX - touch1.clientX, 2) +
//             Math.pow(touch2.clientY - touch1.clientY, 2)
//         );
//         lastScale = scale;
//       } else {
//         // Single touch for swipe
//         const touch = e.touches[0];
//         startX = touch.clientX;
//         startY = touch.clientY;
//       }
//     },
//     [scale]
//   );

//   const handleTouchMove = useCallback(
//     (e) => {
//       if (e.touches.length === 2) {
//         // Pinch-to-zoom handling
//         const [touch1, touch2] = e.touches;
//         const currentPinchDistance = Math.sqrt(
//           Math.pow(touch2.clientX - touch1.clientX, 2) +
//             Math.pow(touch2.clientY - touch1.clientY, 2)
//         );
//         const pinchRatio = currentPinchDistance / pinchStartDistance;
//         scale = Math.max(1, Math.min(3, lastScale * pinchRatio)); // Limit zoom between 1x and 3x
//         sliderWrapperRef.current.style.transform = `scale(${scale})`;
//       } else if (e.touches.length === 1) {
//         // Handle swiping
//         const touch = e.touches[0];
//         currentX = touch.clientX;
//         currentY = touch.clientY;
//         const deltaX = currentX - startX;
//         const deltaY = currentY - startY;
//         const horizontalSwipe = Math.abs(deltaX) > Math.abs(deltaY);
//         if (!horizontalSwipe) return;
//         e.preventDefault();
//         const swipeThreshold = 50;
//         if (Math.abs(deltaX) > swipeThreshold) {
//           if (deltaX > 0 && sliderRef.current) {
//             sliderRef.current.slickPrev();
//           } else if (deltaX < 0 && sliderRef.current) {
//             sliderRef.current.slickNext();
//           }
//           startX = currentX; // Reset startX to avoid multiple slides at once
//         }
//       }
//     },
//     [pinchStartDistance, scale, lastScale, postLength]
//   );

//   const handleTouchEnd = useCallback(() => {
//     startX = 0;
//     startY = 0;
//   }, []);

//   const handleWheelEnd = useCallback(() => {
//     isWheeling.current = false;
//     wheelDelta.current = 0;
//   }, []);

//   useEffect(() => {
//     const sliderElement = sliderWrapperRef.current
//       ? sliderWrapperRef.current.querySelector(".slick-list")
//       : null;

//     if (sliderElement && postLength > 1) {
//       // Wheel event for desktop devices
//       sliderElement.addEventListener("wheel", handleWheel, { passive: false });
//       sliderElement.addEventListener("mouseleave", handleWheelEnd);

//       // Touch events for mobile and tablet devices
//       sliderElement.addEventListener("touchstart", handleTouchStart, {
//         passive: true,
//       });
//       sliderElement.addEventListener("touchmove", handleTouchMove, {
//         passive: false,
//       });
//       sliderElement.addEventListener("touchend", handleTouchEnd);
//     }

//     return () => {
//       if (sliderElement) {
//         sliderElement.removeEventListener("wheel", handleWheel);
//         sliderElement.removeEventListener("mouseleave", handleWheelEnd);

//         sliderElement.removeEventListener("touchstart", handleTouchStart);
//         sliderElement.removeEventListener("touchmove", handleTouchMove);
//         sliderElement.removeEventListener("touchend", handleTouchEnd);
//       }
//     };
//   }, [
//     handleWheel,
//     handleWheelEnd,
//     handleTouchStart,
//     handleTouchMove,
//     handleTouchEnd,
//     postLength,
//   ]);
// };

// utils/updateFavicon.js
export const updateFavicon = (iconUrl) => {
  const favicon = document.querySelector('link[rel="shortcut icon"]');
  if (favicon) {
    favicon.href = iconUrl;
  } else {
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = iconUrl;
    document.head.appendChild(link);
  }
};
