import React, { useEffect, useRef, useState } from 'react'
import { getTepars } from "../../Utils/helpers";
import { IMAGE, VIDEO } from "../../Utils/enums";
import Hls from 'hls.js';

function SliderPopupVideo({
    index,
    mediaIndexFinal,
    media,
    sound,
    handleLoadedMetadata,
    handlePlayPauseToggle,
    disableRightClick,
    setIsPaused,
}) {

    const videoRef = useRef(null);
    const hlsRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [objectFit, setObjectFit] = useState('contain');
    const src = getTepars(media);


    const handleLoadedData = () => {
        setIsLoading(false);
        if (videoRef.current) {
            const { videoWidth, videoHeight } = videoRef.current;
            setObjectFit(getObjectFit(videoWidth, videoHeight));
        }
    };

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement || !src) return;
    
        // Function to initialize HLS
        const initializeHLS = () => {
          if (hlsRef.current) {
            hlsRef.current.destroy();
          }
    
          const hls = new Hls({
            enableWorker: true,
            startLevel: -1,
            maxFragLookUpTolerance: 0.5,
            maxBufferSize: 3 * 1000 * 1000, // 60MB
            maxBufferLength: 5, // 30 seconds
            maxMaxBufferLength: 600, // 10 minutes
            abrEwmaDefaultEstimate: 500000,
            manifestLoadingMaxRetry: 4,
            manifestLoadingRetryDelay: 1000,
            levelLoadingMaxRetry: 4,
            levelLoadingRetryDelay: 1000,
          });
    
          hls.attachMedia(videoElement);
          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(src);
          });
    
          hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  hls.recoverMediaError();
                  break;
                default:
                  initializeHLS();
                  break;
              }
            }
          });
    
          hlsRef.current = hls;
        };
    
        // Check if it's an M3U8 file
        const isHLS = src.includes('.m3u8');
    
        if (isHLS) {
          if (Hls.isSupported()) {
            // Use HLS.js
            initializeHLS();
          } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
            // Native HLS support (Safari)
            videoElement.src = src;
          }
        } else {
          // Regular MP4 video
          videoElement.src = src;
        }
    
        // Play video if it's the current index
        if (index === mediaIndexFinal) {
          videoElement.play().catch(console.warn);
        }
    
        // Cleanup
        return () => {
          if (hlsRef.current) {
            hlsRef.current.destroy();
          }
        };
      }, [src, index, mediaIndexFinal]);

    const getObjectFit = (width, height) => {
        if (width === height) {
            return "contain";
        } else if (width < height) {
            return "cover";
        } else {
            return "contain";
        }
    };

    useEffect(() => {
        if (videoRef.current && videoRef.current.readyState === 4) {
            setIsLoading(false);
            videoRef.current.play().catch(error => console.error("Autoplay failed:", error));
        }
    }, []);

    return (
        <>
            <video
                ref={videoRef}
                loop={true}
                muted={sound}
                id={`${media._id}-${index}`}
                className="upload-video"
                // src={getTepars(media)}
                autoPlay={index === mediaIndexFinal}
                controls={false}
                width="100%"
                height="100%"
                onLoadedData={handleLoadedData}
                onLoadedMetadata={(e) => handleLoadedMetadata(e, media, index)}
                onPlay={() => setIsPaused(prev => ({ ...prev, [media._id]: false }))}
                onPause={() => setIsPaused(prev => ({ ...prev, [media._id]: true }))}
                onClick={() => handlePlayPauseToggle(media._id, index)}
                onContextMenu={disableRightClick}
                style={{
                    opacity: isLoading ? 0 : 1,
                    transition: 'opacity 150ms ease-in-out',
                    objectFit: objectFit
                }}
            />
        </>
    )
}
export default SliderPopupVideo;