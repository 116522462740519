export const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
export const OPENFIRE_IP = process.env.REACT_APP_OPENFIRE_IP;
export const OPENFIRE_CONNECTION = process.env.REACT_APP_OPENFIRE_CONNECTION;
export const AWS_S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_COGNITO_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;
export const AWS_SECRET_ACCESS_KEY =
  process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const REALM_DB_NAME = "todo";
export const REALM_DB_USER_PASSWORD = "fe5e6927";
export const REALM_DB_CONTACT_MODEL = "RContactModel";
export const REALM_DB_INBOX_MODEL = "RInboxModel";
export const REALM_DB_USER_MODEL = "RUserModel";
export const REALM_DB_MESSAGE_MODEL = "RMessageModel";
export const REALM_DB_R_MESSAGE_CONTACT_MODEL = "RMessageContactModel";
export const REALM_DB_GROUP_MODEL = "RGroupModel";
export const REALM_DB_R_MESSAGE_MEDIA_MODEL = "RMessageMediaModel";
export const REALM_DB_R_MESSAGE_LOCATION_MODEL = "RMessageLocationModel";
export const EMAIL_REGX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const LINK_REGX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const CONTACT_REGX = /^\+91\s?\d{10,15}$|^\d{10,15}$/;
export const URL_REGX = /^(http|https):\/\/[\w\W]+/;
export const MAP_API = "https://maps.googleapis.com/maps/api/";
export const MAP_UI = "&zoom=15&size=400x316&markers=color:red%7C";
export const MAP_API_KEY = "&key=AIzaSyD411EtreduCQZ14azJpb8MtMQwBxWzNho";
export const SOCIAL_API = "https://jsonlink.io/api/extract?url=";
export const SOCIAL_API_KEY =
  "&api_key=pk_9192667ca96cda7d972b9a332cbc2f1637cbff24";
export const TEXT = "Text";
export const IMAGE = "Image";
export const VIDEO = "Video";
export const AUDIO = "Audio";
export const LOCATION = "Location";
export const CONTACT = "Contact";
export const LIVEIMAGE = "LiveImage";
export const THUMBNAIL = "Thumbnail";
export const DOCLINK = "-F-DOCUMENT-F-";
export const PAGES = "-Pages";
export const ZIP = "zip";
export const PDF = "pdf";
export const DOC = "doc";
export const DOCUMENT = "document";
export const SENDER_TEXT = "SenderText";
export const RECEIVER_TEXT = "ReceiverText";
export const SENDER_CONTACT = "SenderContact";
export const RECEIVER_CONTACT = "ReceiverContact";
export const SENDER_IMAGE = "SenderImage";
export const RECEIVER_IMAGE = "ReceiverImage";
export const SENDER_VIDEO = "SenderVideo";
export const RECEIVER_VIDEO = "ReceiverVideo";
export const SENDER_DOCUMENT = "SenderDocument";
export const RECEIVER_DOCUMENT = "ReceiverDocument";
export const SENDER_LOCATION = "SenderLocation";
export const RECEIVER_LOCATION = "ReceiverLocation";
export const SENDER_AUDIO = "SenderAudio";
export const RECEIVER_AUDIO = "ReceiverAudio";
export const REPLY_TEXT = "ReplyTextMessage";
export const SENDER_TEXT_REPLY = "SenderReplyTextMessage";
export const RECEIVER_TEXT_REPLY = "ReceiverReplyTextMessage";
export const SENDER_DELETED = "SenderDeleted";
export const RECEIVER_DELETED = "ReceiverDeleted";
export const DOWNLOAD = "Download";
export const DOWNLOADING = "Downloding";
export const DOWNLOADFAILED = "DownloadFailed";
export const DOWNLOADED = "Downloaded";
export const UPLOAD = "Upload";
export const UPLOADING = "Uploading";
export const UPLOADFAILED = "UploadFailed";
export const UPLOADED = "Uploaded";
export const DOCSTYPE = [
  "pdf",
  "zip",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "txt",
  "html",
];
export const MESSAGE_TYPE = [
  "SenderText",
  "SenderVideo",
  "SenderImage",
  "SenderAudio",
  "ReceiverText",
  "ReceiverAudio",
  "ReceiverVideo",
  "ReceiverImage",
  "SenderContact",
  "ReceiverContact",
  "SenderDocument",
  "SenderLocation",
  "ReceiverDocument",
  "ReceiverLocation",
  "SenderReplyTextMessage",
  "ReceiverReplyTextMessage",
];
export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const SEND_MESSAGE_TYPE = [
  "SenderText",
  "SenderVideo",
  "SenderAudio",
  "SenderImage",
  "SenderDocument",
  "SenderLocation",
  "SenderContact",
];
export const RECEIVE_MESSAGE_TYPE = [
  "ReceiverText",
  "ReceiverVideo",
  "ReceiverImage",
  "ReceiverAudio",
  "ReceiverContact",
  "ReceiverLocation",
  "ReceiverDocument",
];
export const CONTACT_ALL = "ContactAll";
export const GROUP = "Group";
export const ONLY_WITH_ME = "Only With Me";
export const CONTACT_EXCEPT = "Contact Except";
export const ONLY_SHARE_WITH = "Only Share With";
export const CONTACT_ALL_AND_GROUP = "ContactAll-Group";
export const CONTACT_EXCEPT_AND_GROUP = "ContactExcept-Group";
export const CONTACT_SHARE_WITH_AND_GROUP = "ContactShareWith-Group";
export const ONLY_WITH_ME_AND_GROUP = "onlyWithMe-Group";
export const RE_POSTING = "rePosting";
export const OFF_COMMENT = "offComment";
export const CAN_SHARE_POST = "canSharePost";
export const CAN_REPLY_POST = "canReplyPost";
export const MOST_RELEVANT = "MostRelevant";
export const ALL_COMMENT = "AllComment";
export const REPORT_OPTIONS = [
  "I just don't like it",
  "It's spam",
  "Nudity or sexual activity",
  "Hate speech or symbols",
  "Violence or dangerous organizations",
  "Sale of illegal or regulated goods",
  "Bullying or harassment",
  "Intellectual property violation",
  "Suicide or self-injury",
  "Eating disorders",
  "Scam or fraud",
  "False information",
];
export const REPORT_NUDITY_OPTION = [
  "Nudity or pornography",
  "Sexual explotation or solicitation",
  "Sharing private images",
  "Involves a child",
];
export const REPORT_VIOLENT_OPTION = [
  "Violent threat",
  "Animal abose",
  "Death or severe injury",
  "Dangerous organisation or individuals",
];
export const REPORT_SALE_OPTION = [
  "Fake health documents",
  "Drugs, alcohol or tobacco",
  "Firearms",
  "Weight loss products or cosmetic procedures",
  "Animals",
];
export const REPORT_FALSE_OPTION = [
  "Health",
  "Politice",
  "Social issue",
  "Something else",
];
export const WALLPAPER = [
  "#F4FEFF",
  "#BBE4E5",
  "#AED8C7",
  "#7ACBA5",
  "#CBDAEC",
  "#66D2D5",
  "#63BDCF",
  "#D6D0F0",
  "#CECECE",
  "#D1DABE",
  "#D1DABE",
  "#E6E1B1",
  "#FEEFA9",
  "#FED297",
  "#FD9A9B",
  "#FD6769",
  "#FB4668",
  "#922040",
  "#DC6E4F",
  "#644D52",
  "#517E7E",
  "#3190BB",
  "#35558A",
  "#55626F",
  "#1D2326",
  "#301E34",
  "#ECF0F1",
  "#FFFEA2",
  "#E7E8D2",
  "#1891A2",
];
export const THEME_OPTIONS = ["Light", "Dark", "Default"];
export const ContactStatus = {
  add: "ADD",
  update: "Update",
  delete: "Delete",
};
export const ERROR = "Something went wrong..";
export const  POST_UPLOAD= "Another post is uploading. Please wait.";

export const COMMENT_ERROR =
  "This content may have been deleted by its owner or hidden by their privacy settings";
export const SETTINGSPRIVACY = [
  "Everyone",
  "My contacts",
  "contact excluded",
  "Nobody",
];
export const HELPCENTERURL = "https://tepnot.com/help-center/";
export const PRIVACYPOLICYURL = "https://tepnot.com/tepnot-terms-of-service/";
export const LIKED = "Post liked successfully";
export const UNLIKE = "Post unliked successfully";
export const NO_PROFILE = "No profile photo";
