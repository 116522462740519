import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { WALLPAPER } from "../../Utils/enums";
import { setSelectedSetting } from "../../Redux/actions";
import "../../assets/css/chatWallpaper.scss";
import FocusTrapping from "./FocusTrapping";

function ChatWallpaper({ setWallpaper }) {
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    dispatch(setSelectedSetting(""));
    setIsMounted(false);
  };

  const handleCheckmarkClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
    <FocusTrapping onClose={handleBackButton}>
    <div className="chatWallpaper">
      <div className="chatWallpaper-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Set chat wallpaper</span>
          </div>
        </header>

        <div className="chatWallpaper-info-wrapper">
          {/* <div className="colors-style">
           
            </div> */}

          <div className="tepnot-doodles">
            <div className="group-check">
              <input
                type="checkbox"
                id="doodle-check"
                aria-label="Check this box"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <span className="checkmark" onClick={handleCheckmarkClick} />
            </div>
            {/* <span className="title">Add tepnot doodles</span> */}
            <label htmlFor="doodle-check" className="title">
              Add tepnot doodles
            </label>
          </div>

          <div className="colors-options">
            {WALLPAPER.map((color, i) => (
              <div
                key={i}
                className={`color-listItem ${selectedIndex === i && "active"}`}
                style={{ backgroundColor: color }}
                onClick={() => {
                  setWallpaper(color);
                  setSelectedIndex(i);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    </FocusTrapping>
    </>
  );
}

export default ChatWallpaper;
