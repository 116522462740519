import { React, useEffect, useRef, useState } from "react";
import Spinner from "../Common/Spinner";
import { ERROR, COMMENT_ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import like from "../../assets/image/SVG_Images/like.svg";
import unlike from "../../assets/image/SVG_Images/unlike.svg";
import unlikeGrey from "../../assets/image/SVG_Images/unlikeGrey.svg";
import { handleToast, incrementPostComments } from "../../Redux/actions";
import {
  getSelfUser,
  getUserProfilePic,
  convertToAgoFormat,
  getSelectedUserDetails,
} from "../../Utils/helpers";
import "../../assets/css/commentView.scss";

const CommentLikeAndCount = ({
  postData,
  comment,
  toggleComment,
  handleReplyClick,
  commentActions,
}) => {
  const UserID = localStorage.getItem("user_id");
  const theme = useSelector((state) => state.theme);

  const [selfLike, setSelfLike] = useState(comment?.selfLike);
  const [likeCount, setLikeCount] = useState(comment?.likeUser);

  return (
    <div className="profile-cta">
      <div className="profile-cta-likes">
        <div
          className="likes-image"
          role="button"
          tabIndex="0"
          onClick={() => {
            setSelfLike(!selfLike);
            setLikeCount(selfLike ? likeCount - 1 : likeCount + 1);
            toggleComment(postData?._id, comment?.commentId);
          }}
        >
          <img
            src={
              selfLike
                ? like
                : theme === "Light" ||
                  (theme !== "Dark" &&
                    window.matchMedia("(prefers-color-scheme: light)").matches)
                ? unlike
                : unlikeGrey
            }
            className={selfLike ? "animate" : ""}
            title={selfLike ? "Unteps" : "Teps"}
            alt="Teps"
          />
        </div>
        {likeCount > 0 && <span className="comments-likes">{likeCount}</span>}
      </div>
      {comment?.replayStatus || comment?.userId?._id === UserID ? (
        <div
          className="comment-reply"
          tabIndex="0"
          onClick={() =>
            handleReplyClick(
              comment?.commentId,
              comment?.userId?.name,
              comment?.userId?._id
            )
          }
        >
          <span>Reply</span>
        </div>
      ) : null}

      <div
        className="comment-cta"
        title="Comment options"
        tabIndex="0"
        aria-label="comment options"
        onClick={() => commentActions(comment, postData)}
      >
        <svg width="11" height="3" viewBox="0 0 11 3" fill="none">
          <title>Comment options</title>
          <circle cx="1.5" cy="1.5" r="1.5" fill="#999999" />
          <circle cx="5.5" cy="1.5" r="1.5" fill="#999999" />
          <circle cx="9.5" cy="1.5" r="1.5" fill="#999999" />
        </svg>
      </div>
    </div>
  );
};

const RepliesLikeAndCount = ({
  index,
  postData,
  comment,
  reply,
  handleReplyClick,
  toggleCommentReply,
  replyActions,
  handleViewMoreReplies,
}) => {
  const UserID = localStorage.getItem("user_id");
  const theme = useSelector((state) => state.theme);

  const [selfReplyLike, setSelfReplyLike] = useState(reply.selfLike);
  const [replyLikeCount, setReplyLikeCount] = useState(reply?.likeUser);

  return (
    <div className="profile-cta" key={index}>
      <div className="profile-cta-likes">
        <div
          className="likes-image"
          role="button"
          tabIndex="0"
          onClick={() => {
            setSelfReplyLike(!selfReplyLike);
            setReplyLikeCount(
              selfReplyLike ? replyLikeCount - 1 : replyLikeCount + 1
            );
            toggleCommentReply(postData?._id, comment?.commentId, reply?._id);
          }}
        >
          <img
            src={
              selfReplyLike
                ? like
                : theme === "Light" ||
                  (theme !== "Dark" &&
                    window.matchMedia("(prefers-color-scheme: light)").matches)
                ? unlike
                : unlikeGrey
            }
            className={selfReplyLike ? "animate" : ""}
            title={selfReplyLike ? "Unteps" : "Teps"}
            alt="Teps"
          />
        </div>
        {replyLikeCount > 0 && (
          <span className="comments-likes">{replyLikeCount}</span>
        )}
      </div>

      {reply?.replayStatus || reply?.userId?._id === UserID ? (
        <div
          className="comment-reply"
          tabIndex="0"
          onClick={() =>
            handleReplyClick(
              comment?.commentId,
              reply?.taguser?.name,
              reply?.userId?._id,
              reply?.userId?.name
            )
          }
        >
          <span>Reply</span>
        </div>
      ) : null}

      <div
        className="reply-cta"
        tabIndex="0"
        aria-label="Reply options"
        onClick={() => {
          replyActions(postData, comment, reply);
          if (comment?.replies?.length === 1 && comment?.replyCount !== 1) {
            // handleViewMoreReplies(comment?.commentId);
          }
        }}
      >
        <svg width="11" height="3" viewBox="0 0 11 3" fill="none">
          <title>Comment options</title>
          <circle cx="1.5" cy="1.5" r="1.5" fill="#999999" />
          <circle cx="5.5" cy="1.5" r="1.5" fill="#999999" />
          <circle cx="9.5" cy="1.5" r="1.5" fill="#999999" />
        </svg>
      </div>
    </div>
  );
};

function CommentView({
  postData,
  setPassPostData,
  setIsCommentOrReply,
  isCommentId,
  setIsCommentId,
  isReplyId,
  setIsReplyId,
  setIsUserPost,
  isCommentDelete,
  setIsCommentDelete,
  isReplyDelete,
  setIsReplyDelete,
  setSelectedMenu,
  notificationComment,
  toggleSelfLike,
}) {
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const maxLength = 1500;

  const theme = useSelector((state) => state.theme);
  const User = useSelector((state) => state.selfContact);
  const UserData = useSelector((state) => state.selfContact);
  const UserID = useSelector((state) => state.selfContact._id);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [toReplyId, setToReplyId] = useState();
  const [toReply, setToReply] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toReplyName, setToReplyName] = useState();
  const [toTagUserId, setToTagUserId] = useState();
  const [repliesPage, setRepliesPage] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [writeComment, setWriteComment] = useState("");
  const [toTagUserName, setToTagUserName] = useState();
  const [postComments, setPostComments] = useState([]);
  const [repliesPageCount, setRepliesPageCount] = useState(0);
  const [commentLoading, setCommentLoading] = useState(false);
  const [postLike, setPostLike] = useState(postData?.selfLike);
  const [newReplyComments, setNewReplyComments] = useState([]);
  const [appendPostComments, setAppendPostComments] = useState();
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
    if (postComments) {
      setAppendPostComments(postComments);
    }
  }, [postComments]);

  const handleInputChange = (e) => {
    const text = e.target.textContent;
    if (text.length <= maxLength) {
      setWriteComment(text);
    } else {
      // Truncate the text to maxLength
      e.preventDefault();
      e.target.textContent = text.slice(0, maxLength);
      setWriteComment(e.target.textContent);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (writeComment) commentAndRepliesDataAppend();
    }
  };

  const commentAndRepliesDataAppend = async () => {
    if (toReply) {
      const sendComment = {
        text: writeComment,
        commentId: toReplyId,
        taguser: toTagUserId ? toTagUserId : UserData?._id,
        type: "message",
      };

      axiosRequest
        .put(`post/comment/reply/${postData?._id}`, sendComment)
        .then((res) => {
          let onlyNewReplyData = [res?.data?.data, ...newReplyComments];
          setNewReplyComments(onlyNewReplyData);
          const foundComment = appendPostComments.find(
            (comment) => comment.commentId === toReplyId
          );

          if (foundComment.replies.length > 0) {
            var OnlyNewReplyData = [res?.data?.data, ...foundComment?.replies];
          } else {
            OnlyNewReplyData = [res?.data?.data];
          }

          const updatedComments = appendPostComments.map((comment) => {
            if (comment.commentId === toReplyId) {
              return {
                ...comment,
                replies: OnlyNewReplyData,
              };
            }
            return comment;
          });
          setAppendPostComments(updatedComments);
        })
        .catch((error) => {
          if (error?.response?.status !== 401) {
            dispatch(handleToast(ERROR));
          }
        });

      setToReplyId();
      setToReplyName();
      setToTagUserId();
      setToReply(false);
      setWriteComment("");
    } else {
      const sendComment = {
        text: writeComment,
        type: "message",
      };

      axiosRequest
        .put(`post/comment/${postData?._id}`, sendComment)
        .then((res) => {
          if (res?.data?.data) {
            let getAppended = [res.data.data, ...appendPostComments];
            setAppendPostComments(getAppended);

            dispatch(incrementPostComments(postData?._id));
          } else {
            dispatch(handleToast(res?.data?.message));
          }
        })
        .catch((error) => {
          if (error?.response?.status !== 401) {
            dispatch(handleToast(ERROR));
          }
        });
    }
    fileInputRef.current.textContent = "";
    setWriteComment("");
  };

  const fetchComments = (pageNumber) => {
    if (allDataLoaded) return;
    setLoading(true);
    if (pageNumber === 1) {
      setCommentLoading(true);
    }
    axiosRequest
      .get(`post/web/maincomment/${postData?._id}?page=${pageNumber}`)
      .then((res) => {
        setCount(res.data.totalCount);
        if (res.data.data?.length) {
          setPostComments((prevTepars) => [...prevTepars, ...res.data.data]);
          setPage(pageNumber + 1);
        } else {
          if (pageNumber === 1) {
            setPostComments([]);
          } else {
            setAllDataLoaded(true);
          }
        }
        setLoading(false);
        setCommentLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(COMMENT_ERROR));
        }
        setLoading(false);
        setCommentLoading(false);
      });
  };

  const fetchNotificationComments = (pageNumber) => {
    if (allDataLoaded) return;
    setLoading(true);
    if (pageNumber === 1) {
      setCommentLoading(true);
    }
    axiosRequest
      .get(
        `post/notificationmaincomment/${postData?._id}/${notificationComment?.comment}?page=${pageNumber}`
      )
      .then((res) => {
        setCount(res.data.totalCount);
        if (res.data.data?.length) {
          setPostComments((prevTepars) => [...prevTepars, ...res.data.data]);
          setPage(pageNumber + 1);
        } else {
          if (pageNumber === 1) {
            setPostComments([]);
          } else {
            setAllDataLoaded(true);
          }
        }
        setLoading(false);
        setCommentLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        setCommentLoading(false);
      });
  };

  useEffect(() => {
    if (notificationComment?.comment) {
      fetchNotificationComments(1);
    } else {
      fetchComments(1);
    }
  }, [postData]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 15 &&
      !loading &&
      count !== appendPostComments.length &&
      !allDataLoaded
    ) {
      if (notificationComment?.comment) {
        fetchNotificationComments(page);
      } else {
        fetchComments(page);
      }
    }
  };

  const handleReplyClick = (id, name, tagUserIdData, tagUserName) => {
    setToReplyId(id);
    setToReplyName(name);
    setToTagUserId(tagUserIdData);
    setToTagUserName(tagUserName);
    setToReply(true);
  };

  const handleCancelReply = () => {
    setToReply(false);
    setToReplyId();
    setToReplyName();
  };

  const handleViewMoreReplies = (commentId) => {
    let pageNumber = 0;
    if (repliesPage.length) {
      let data = repliesPage.find((reply) => reply.id === commentId);

      if (data) {
        setRepliesPage(
          repliesPage.map((reply) => {
            if (reply.id === commentId) {
              return {
                id: commentId,
                pageNumber: data.pageNumber + 1,
              };
            } else {
              return reply;
            }
          })
        );
        pageNumber = data.pageNumber + 1;
      } else {
        setRepliesPage([
          ...repliesPage,
          {
            id: commentId,
            pageNumber: 1,
          },
        ]);
        pageNumber += 1;
      }
    } else {
      setRepliesPage([
        ...repliesPage,
        {
          id: commentId,
          pageNumber: 1,
        },
      ]);
      pageNumber += 1;
    }
    axiosRequest
      .get(`post/getreplycomment/${commentId}?page=${pageNumber}`)
      .then((res) => {
        setTotalPage(res.data.totalPages);
        var replies_append = appendPostComments.map((post_comment) => {
          if (post_comment.commentId === commentId) {
            post_comment.replies = removeDuplicatesIdData([
              ...post_comment.replies,
              ...res.data.data,
            ]);
            return post_comment;
          }
          return post_comment;
        });
        setAppendPostComments(replies_append);
        if (repliesPageCount < 0) {
          setRepliesPageCount(res.data.totalCount + repliesPageCount);
        } else if (repliesPageCount !== 0) {
          setRepliesPageCount(repliesPageCount - res.data.data.length);
        } else {
          setRepliesPageCount(res.data.totalCount - res.data.data.length);
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const removeDuplicatesIdData = (arrData) => {
    let uniqueCommentIds = new Set();
    let arrayWithUniqueComments = arrData.filter((obj) => {
      if (!uniqueCommentIds.has(obj._id)) {
        uniqueCommentIds.add(obj._id);
        return true;
      }
      return false;
    });
    return arrayWithUniqueComments;
  };

  const togglePostLike = (postId) => {
    toggleSelfLike(postId);
  };

  const toggleComment = (postId, cmtId) => {
    const data = {
      commentId: cmtId,
    };
    axiosRequest
      .put(`post/comment/like/${postId}`, data)
      .then((res) => {})
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const toggleCommentReply = (postId, cmtId, replyId) => {
    const data = {
      commentId: cmtId,
      replyId: replyId,
    };
    axiosRequest
      .put(`post/comment/reply/like/${postId}`, data)
      .then((res) => {})
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const commentActions = (comment, postData) => {
    setIsCommentOrReply(true);
    setPassPostData(postData);
    setIsCommentId(comment?.commentId);
    if (postData?.userId?._id === User?._id) {
      setIsUserPost(true);
    } else if (
      postData?.userId?._id !== User?._id &&
      comment?.userId?._id === User?._id
    ) {
      setIsUserPost(true);
    } else if (
      postData?.userId?._id !== User?._id &&
      comment?.userId?._id !== User?._id
    ) {
      setIsUserPost(false);
    }
  };

  useEffect(() => {
    const newCmtData = appendPostComments?.filter(
      (items) => items?.commentId !== isCommentId
    );
    setAppendPostComments(newCmtData);
    setIsCommentDelete(false);
  }, [isCommentDelete]);

  const replyActions = (postData, comment, reply) => {
    setIsCommentOrReply(true);
    setPassPostData(postData);
    setIsCommentId(comment?.commentId);
    setIsReplyId(reply?._id);
    if (postData?.userId?._id === User?._id) {
      setIsUserPost(true);
    } else if (
      postData?.userId?._id !== User?._id &&
      reply?.userId?._id === User?._id
    ) {
      setIsUserPost(true);
    } else if (
      postData?.userId?._id !== User?._id &&
      reply?.userId?._id !== User?._id
    ) {
      setIsUserPost(false);
    }
  };

  useEffect(() => {
    if (isReplyDelete) {
      const newReplyData = appendPostComments?.map((comment) => {
        if (comment?.commentId === isCommentId) {
          const filterReply = comment?.replies?.filter(
            (reply) => reply?._id !== isReplyId
          );
          return { ...comment, replies: filterReply };
        }
        return comment;
      });
      setAppendPostComments(newReplyData);
      setIsReplyDelete(false);
    }
  }, [isReplyDelete]);

  useEffect(() => {
    fileInputRef.current.focus();
    if (toReplyId !== undefined) {
      fileInputRef.current.focus();
    }
  }, [toReplyId]);

  const renderLikeInfo = () => {
    if (
      postData?.userId?.userType === "Admin" ||
      (postData?.group &&
        postData?.group[0]?.GroupId?.userRole &&
        postData?.group[0]?.GroupId?.userRole === "Member")
    ) {
      if (postData?.TotalLike > 0) {
        return (
          <p className="users-liked-info">
            <span>{postData?.TotalLike}</span> Teps
          </p>
        );
      }
    } else if (postData?.TotalLike === 1) {
      if (postData?.likeUser[0]?.userId?.contactStatus) {
        return (
          <p className="users-liked-info">
            <span>{postData?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      } else if (postData?.likeUser[0]?.userId._id === UserID) {
        return (
          <p className="users-liked-info">
            <span>{postData?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      } else {
        return (
          <p className="users-liked-info">
            <span>~{postData?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      }
    } else if (postData?.TotalLike > 0) {
      const userLiked = postData?.likeUser?.some(
        (likes) => likes?.userId?._id === UserID
      );
      const otherLikes = postData?.TotalLike - 1;

      if (userLiked) {
        return (
          <p className="users-liked-info">
            <span>You</span> and{" "}
            <span>
              {postData?.hideLikeCount !== true && otherLikes}
              {otherLikes > 1 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      }
      if (postData?.likeUser[0]?.userId?.contactStatus) {
        return (
          <p className="users-liked-info">
            <span>{postData?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {postData?.TotalLike - 1}
              {postData?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      } else if (postData?.likeUser[0]?.userId._id === UserID) {
        return (
          <p className="users-liked-info">
            <span>{postData?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {postData?.TotalLike - 1}
              {postData?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      } else {
        return (
          <p className="users-liked-info">
            <span>~{postData?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {postData?.TotalLike - 1}{" "}
              {postData?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      }
    } else {
      return <span>No one Teps this post</span>;
    }
  };

  return (
    <div className="commentView">
      <div className="commentView-wrapper">
        <div className="heading-likes">
          <div className="liked-users">
            <div className="liked-image">
              <img src={like} alt="Teps" />
            </div>
            <span className="users-names">
              {renderLikeInfo()}
              <span className="name" />
            </span>
          </div>
          <button
            className="btn btn-likepost"
            onClick={() => togglePostLike(postData?._id)}
          >
            <img
              src={
                postData?.selfLike
                  ? like
                  : theme === "Light" ||
                    (theme !== "Dark" &&
                      window.matchMedia("(prefers-color-scheme: light)")
                        .matches)
                  ? unlike
                  : unlikeGrey
              }
              className={postData?.selfLike ? "animate" : ""}
              title={postData?.selfLike ? "Unteps" : "Teps"}
              alt="Teps"
            />
          </button>
        </div>
        <div
          className="comment-section-wrapper"
          onScroll={handleScroll}
          ref={containerRef}
        >
          <div className="post-comments">
            {commentLoading ? (
              <div className="no-data">
                <Spinner />
              </div>
            ) : appendPostComments && appendPostComments.length > 0 ? (
              appendPostComments.map((comment, index) => (
                <div className="commented-user" key={index}>
                  <div className="comment-user-profile">
                    <div className="profile-image">
                      <img
                        src={getUserProfilePic(comment?.userId.profile)}
                        alt="comment user profile"
                      />
                    </div>
                    <div className="profile-info-wrapper">
                      <div className="profile-info">
                        <div className="profile-name">
                          <span
                            className="name"
                            onClick={() =>
                              getSelectedUserDetails(
                                comment?.userId?._id,
                                dispatch,
                                setSelectedMenu
                              )
                            }
                          >
                            {comment?.userId?._id === User?._id
                              ? "You"
                              : getSelfUser(comment?.userId)}
                          </span>
                          <span className="commented-time">
                            {convertToAgoFormat(comment?.commentAt)}
                          </span>
                        </div>
                        <div className="commented-comment">
                          <p className="comment">{comment?.text}</p>
                        </div>
                      </div>
                      <CommentLikeAndCount
                        postData={postData}
                        comment={comment}
                        toggleComment={toggleComment}
                        handleReplyClick={handleReplyClick}
                        commentActions={commentActions}
                      />
                    </div>
                  </div>
                  <div className="commented-replys" key={index}>
                    {comment?.replies.map((reply, index, array) => (
                      <>
                        {reply && (
                          <div className="comment-user-profile">
                            <div className="profile-image">
                              <img
                                src={getUserProfilePic(reply?.userId.profile)}
                                alt="comment user profile"
                              />
                            </div>
                            <div className="profile-info-wrapper">
                              <div className="profile-info">
                                <div className="profile-name">
                                  <span
                                    className="name"
                                    onClick={() =>
                                      getSelectedUserDetails(
                                        reply?.userId?._id,
                                        dispatch,
                                        setSelectedMenu
                                      )
                                    }
                                  >
                                    {reply?.userId?._id === User?._id
                                      ? "You"
                                      : getSelfUser(reply?.userId)}
                                  </span>
                                  <span className="commented-time">
                                    {convertToAgoFormat(reply?.commentAt)}
                                  </span>
                                </div>
                                <div className="commented-comment">
                                  <p className="comment">
                                    <span
                                      className="replied-user-name"
                                      onClick={() =>
                                        getSelectedUserDetails(
                                          reply?.taguser?._id,
                                          dispatch,
                                          setSelectedMenu
                                        )
                                      }
                                    >
                                      @{reply?.taguser?.name}
                                    </span>
                                    {reply?.text}
                                  </p>
                                </div>
                                <RepliesLikeAndCount
                                  index={index}
                                  postData={postData}
                                  comment={comment}
                                  reply={reply}
                                  handleReplyClick={handleReplyClick}
                                  toggleCommentReply={toggleCommentReply}
                                  replyActions={replyActions}
                                  handleViewMoreReplies={handleViewMoreReplies}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                    {(totalPage === 0
                      ? comment?.replyCount > 1
                      : comment?.replyCount > 1 &&
                        totalPage > repliesPage[0]?.pageNumber &&
                        repliesPageCount !== 1) && (
                      <div
                        className="view-replys"
                        onClick={() =>
                          handleViewMoreReplies(comment?.commentId)
                        }
                      >
                        <span className="text">
                          View{" "}
                          {!totalPage
                            ? comment?.replyCount - 1
                            : repliesPageCount - 1}{" "}
                          more replies
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-comments">
                <div className="no-comment-image">
                  <svg width="45" height="45" viewBox="0 0 56 56" fill="none">
                    <g id="Iconsax/Linear/messagetext">
                      <g id="Group 34106">
                        <path
                          id="Vector"
                          d="M16.1191 21.3203H39.9828M16.1191 34.5779H32.8237"
                          stroke={
                            theme === "Light"
                              ? "#2a2a2a"
                              : theme === "Dark"
                              ? "#ffffff"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#2a2a2a"
                              : "#ffffff"
                          }
                          strokeWidth="2.25"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M28.0014 53.456C33.0358 53.456 37.9572 51.9631 42.1432 49.1661C46.3292 46.3691 49.5917 42.3937 51.5183 37.7425C53.4449 33.0912 53.949 27.9732 52.9668 23.0355C51.9847 18.0978 49.5604 13.5622 46.0005 10.0023C42.4406 6.44247 37.905 4.01816 32.9673 3.03599C28.0296 2.05382 22.9116 2.5579 18.2604 4.4845C13.6092 6.41109 9.63372 9.67366 6.83674 13.8596C4.03976 18.0456 2.54688 22.967 2.54688 28.0014C2.54688 32.2099 3.56506 36.1752 5.37516 39.6737L2.54688 53.456L16.3291 50.6277C19.8248 52.435 23.7958 53.456 28.0014 53.456Z"
                          stroke={
                            theme === "Light"
                              ? "#2a2a2a"
                              : theme === "Dark"
                              ? "#ffffff"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#2a2a2a"
                              : "#ffffff"
                          }
                          strokeWidth="2.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <span className="text">No comments yet</span>
              </div>
            )}
            {page > 1 && loading && <Spinner />}
          </div>
        </div>
        <footer className="footer-box">
          {toReply && (
            <div className="replying-to-wrapper">
              <div className="reply-to">
                <p className="reply-to-name">
                  Replying to
                  <a href="/" className="name">
                    {toTagUserName !== undefined ? toTagUserName : toReplyName}
                  </a>
                </p>
                <span
                  className="cancel-reply"
                  role="button"
                  onClick={() => handleCancelReply()}
                >
                  Cancel
                </span>
              </div>
            </div>
          )}
          <div className="comment-to-post">
            <div className="message-wrapper">
              <div className="input-wrapper">
                <div
                  placeholder="Add a comment..."
                  className="messageInput"
                  contentEditable
                  onInput={handleInputChange}
                  onKeyDown={handleKeyPress}
                  ref={fileInputRef}
                />
              </div>
            </div>
            <div
              role="button"
              className="send-message"
              onClick={writeComment ? commentAndRepliesDataAppend : null}
            >
              <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
                <title>Send</title>
                <rect
                  width="46"
                  height="46"
                  rx="23"
                  transform="matrix(-1 0 0 1 46 0)"
                  fill="#1891A2"
                />
                <path
                  d="M18.8886 31.6163L26.34 24.3652C27.22 23.5088 27.22 22.1075 26.34 21.2512L18.8886 14"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CommentView;
