import React, { useEffect, useRef, useState } from "react";
import { getTepars } from "../../Utils/helpers";
import { useSelector } from "react-redux";
import { post_video_thumb_url } from "../../ServerConfiguration/awsConfiguration";
import { IMAGE, VIDEO } from "../../Utils/enums";
import Hls from 'hls.js';

function MediaSliderVideo({
  index,
  post,
  Media,
  mediaIndexFinal,
  handlePlayPauseToggleClick,
  disableRightClick,
  setIsPaused,
}) {
  const videoRef = useRef(null);
  const sound = useSelector((state) => state.sound);

  const hlsRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const src = getTepars(Media, post);

  const handleLoadedMetadata = (event) => {
    setVideoDimensions({
      width: event.target.videoWidth,
      height: event.target.videoHeight,
    });
  };

  const handleLoadedData = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement || !src) return;

    // Function to initialize HLS
    const initializeHLS = () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }

      const hls = new Hls({
        enableWorker: true,
        startLevel: -1,
        maxFragLookUpTolerance: 0.5,
        maxBufferSize: 3 * 1000 * 1000, // 60MB
        maxBufferLength: 5, // 30 seconds
        maxMaxBufferLength: 600, // 10 minutes
        abrEwmaDefaultEstimate: 500000,
        manifestLoadingMaxRetry: 4,
        manifestLoadingRetryDelay: 1000,
        levelLoadingMaxRetry: 4,
        levelLoadingRetryDelay: 1000,
      });

      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(src);
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              hls.recoverMediaError();
              break;
            default:
              initializeHLS();
              break;
          }
        }
      });

      hlsRef.current = hls;
    };

    // Check if it's an M3U8 file
    const isHLS = src.includes('.m3u8');

    if (isHLS) {
      if (Hls.isSupported()) {
        // Use HLS.js
        initializeHLS();
      } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        // Native HLS support (Safari)
        videoElement.src = src;
      }
    } else {
      // Regular MP4 video
      videoElement.src = src;
    }

    // Play video if it's the current index
    if (index === mediaIndexFinal) {
      videoElement.play().catch(console.warn);
    }

    // Cleanup
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [src, index, mediaIndexFinal]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.readyState === 4) {
      setIsLoading(false);
    }
  }, []);


  // useEffect(() => {
  //   // console.log("src =>>>>>>", src);
  //   if (Hls.isSupported() && src?.endsWith(".m3u8")) {
  //     const hls = new Hls(
  //       {
  //         xhrSetup: (xhr) => {
  //           xhr.withCredentials = false;
  //         },
  //       }
  //     );
  //     hls.loadSource(src);
  //     hls.attachMedia(videoRef.current);
  //   } else if (videoRef.current?.canPlayType("application/vnd.apple.mpegurl")) {
  //     videoRef.current.src = src;
  //   }
  //   console.log("videoRef.current.src =>", videoRef.current.src);
  // }, [index]);

  const getObjectFit = () => {
    var allVideo = post?.media?.every((item) => item?.type === "Video")
      ? VIDEO
      : IMAGE;
    var allImage = post?.media?.every((item) => item?.type === "Image")
      ? IMAGE
      : VIDEO;
    if (post.media.length === 1 && post.media[0].type === VIDEO) {
      return videoDimensions.height > videoDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length === 1 && post.media[0].type === IMAGE) {
      return videoDimensions.height > videoDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length > 1 && allVideo === VIDEO) {
      return videoDimensions.height > videoDimensions.width
        ? "cover"
        : "contain";
    } else if (post.media.length > 1 && allImage === IMAGE) {
      return "contain";
    } else {
      return videoDimensions.height > videoDimensions.width
        ? "cover"
        : "contain";
    }
  };

  return (
    <>
      {/* {!isLoading && (
                <img
                    src={post_video_thumb_url + Media?.thumbnail}
                    alt="Thumbnail"
                    style={{ width: '100%', height: 'auto', display: 'block', position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
                />
            )} */}
      <video
        ref={videoRef}
        loop={true}
        muted={sound}
        className="view-video"
        poster={post_video_thumb_url + Media?.thumbnail}
        src={getTepars(Media, post)}
        // src={src}
        autoPlay={index === mediaIndexFinal}
        controls={false}
        name={`${post?._id}-${index}`}
        id={`${post?._id}-${index}`}
        onLoadedMetadata={handleLoadedMetadata}
        onLoadedData={handleLoadedData}
        onPlay={() => setIsPaused((prev) => ({ ...prev, [Media?._id]: false }))}
        onPause={() => setIsPaused((prev) => ({ ...prev, [Media?._id]: true }))}
        onClick={() => handlePlayPauseToggleClick(Media?._id, index)}
        onContextMenu={disableRightClick}
        style={{
          opacity: isLoading ? 0 : 1,
          transition: "opacity 150ms ease-in-out",
          objectFit: getObjectFit(),
        }}
      />
    </>
  );
}
export default MediaSliderVideo;
