import React, { useState, useRef, useEffect } from "react";
import Help from "./Help";
import About from "./Privacy/About";
import ThemePopup from "./ThemePopup";
import LogoutPopup from "./LogoutPopup";
import FocusTrapping from "./FocusTrapping";
import SavedSettings from "./SavedSettings";
import Notifications from "./Notifications";
import ChatWallpaper from "./ChatWallpaper";
import ArchiveSettings from "./ArchiveSettings";
import PrivacySettings from "./PrivacySettings";
import BlockedContacts from "./BlockedContacts";
import ProfilePhoto from "./Privacy/ProfilePhoto";
import SecuritySettings from "./SecuritySettings";
import MediaAutoDownload from "./MediaAutoDownload";
import KeyboardShortcuts from "./KeyboardShortcuts";
import RequestAccountInfo from "./RequestAccountInfo";
import ExceptContacts from "./Privacy/ExceptContacts";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import LastSeenAndOnline from "./Privacy/LastSeenAndOnline";
import DefaultMessageTimer from "./Privacy/DefaultMessageTimer";
import {
  setSelectedSetting,
  setSettingOptions,
  setLeftRightSide,
  setPrivacySettingSide,
} from "../../Redux/actions";
import "../../assets/css/settings.scss";
import StoryPrivacy from "./Privacy/StoryPrivacy";

function Settings({
  postContainerRef,
  setSelectedMenu,
  setSelectedTeparsType,
}) {
  const dispatch = useDispatch();

  const firstInteractiveElementRef = useRef(null);

  const selfUser = useSelector((state) => state.selfContact);
  const selectedSetting = useSelector((state) => state.selectedSetting);
  const leftRightSide = useSelector((state) => state.leftRightSide);

  useEffect(() => {
    if (firstInteractiveElementRef.current) {
      firstInteractiveElementRef.current.focus();
    }
  }, []);

  const handleSelectSetting = (setting) => {
    if (setting !== "logout" && setting !== "theme") {
      dispatch(setLeftRightSide("close-left"));
    }
    dispatch(setSelectedSetting(setting));
    setSelectedTeparsType(setting);
    // setTimeout(() => {
    //   // dispatch(setSelectedSetting(setting));
    //   // setSelectedTeparsType(setting);
    // }, 300); // Match animation duration
  };

  const handleBackButton = () => {
    dispatch(setLeftRightSide("close-right"));
    setTimeout(() => {
      dispatch(setSettingOptions(""));
      setSelectedTeparsType(null);
      setSelectedMenu("profile");
    }, 250);
  };
  // <----------------Trapping Focus----------------->

  // const captureRef = useRef(null);

  // useEffect(() => {
  //   // Programmatically focus the element with the ref attached
  //   captureRef.current.focus();
  // }, []);

  // const handleKeydown = (event) => {
  //   if (event.key.toLowerCase() !== "tab") {
  //     return;
  //   }

  //   // Get all tabbable elements within the capture container
  //   const tabbableElements = captureRef.current.querySelectorAll(
  //     'button, input, select, textarea, [href], [tabindex]:not([tabindex="-1"])'
  //   );

  //   const firstTabbable = tabbableElements[0];
  //   const lastTabbable = tabbableElements[tabbableElements.length - 1];
  //   const target = event.target;

  //   // Reverse tabbing (Shift + Tab)
  //   if (event.shiftKey) {
  //     if (target === captureRef.current || target === firstTabbable) {
  //       event.preventDefault();
  //       lastTabbable.focus();
  //     }
  //   }
  //   // Forward tabbing (Tab)
  //   else {
  //     if (target === lastTabbable) {
  //       event.preventDefault();
  //       firstTabbable.focus();
  //     }
  //   }
  // };

  const scrollToPost = (postId) => {
    const postElement = postContainerRef.current.querySelector(
      `#post-${postId}`
    );
    if (postElement) {
      postElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handlePostSelect = (postId) => {
    scrollToPost(postId);
  };

  return (
    <>
      <FocusTrapping onClose={handleBackButton}>
        <div
          className={`web-settings ${
            leftRightSide === "open-right"
              ? "open-right"
              : leftRightSide === "open-left"
              ? "open-left"
              : leftRightSide === "close-right"
              ? "close-right"
              : leftRightSide === "close-left"
              ? "close-left"
              : ""
          }`}
        >
          <div className="web-settings-wrapper">
            <header className="header">
              <div
                tabIndex="0"
                role="button"
                aria-label="Back"
                className="btn btn-back"
                onClick={() => handleBackButton()}
                ref={firstInteractiveElementRef}
              >
                <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                  <title>Back</title>
                  <g id="Group 18522">
                    <g id="Group 18522_2">
                      <path
                        id="Vector"
                        d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                        stroke="white"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <span className="text">Settings</span>
            </header>
            <div className="settings-details">
              <div
                tabIndex="0"
                role="listitem"
                className="profile-info-settings-wrapper"
                onClick={() => handleSelectSetting("updateProfile")}
              >
                <div className="profile-info-settings">
                  <div className="profile-image">
                    <img
                      src={getUserProfilePic(selfUser?.profile)}
                      alt="profile avatar"
                    />
                  </div>
                  <div className="profile-info">
                    <div className="info">
                      <span className="profile-name">{selfUser?.name}</span>
                      {selfUser?.description && (
                        <span className="profile-status">
                          {selfUser?.description}
                        </span>
                      )}
                    </div>
                    <div className="more-info" aria-label="more info">
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                      >
                        <title>More Info</title>
                        <path
                          id="Vector"
                          d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                          fill="#C5C5C5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                tabIndex="0"
                role="listitem"
                className="post-settings settings-listItem"
                onClick={() => handleSelectSetting("archived")}
              >
                <div className="settings-listItem-image">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <g id="outputsvg" clipPath="url(#clip0_1242_3683)">
                      <g id="l3mXhYMkS5Jb3ydVMyqSNFb">
                        <g id="Group">
                          <path
                            id="pogSOsYw4"
                            d="M11.45 25C7.55 24.325 2.5 19.975 4.65 19.15C4.9 19.05 5.4 19.425 6.05 20.125C8.275 22.55 10.975 23.65 14.35 23.45C17.2 23.275 18.9 22.55 20.9 20.7C22 19.675 22.75 18.675 23.3 17.55C24.075 15.95 24.125 15.75 24.125 13C24.125 10.25 24.075 10.025 23.325 8.50001C21.325 4.45001 17.5 2.25001 12.9 2.57501C8.6 2.87501 4.625 6.17501 3.6 10.35C3.45 11.025 3.45 11.05 3.975 10.75C4.6 10.425 5.25 10.775 5.25 11.4C5.25 11.825 2.775 14.25 2.325 14.25C1.95 14.25 0 11.425 0 10.875C0 10.35 0.7 10.1 1.25 10.45C1.75 10.75 2 10.6 2 10.025C2 9.17501 3.775 5.95001 4.9 4.75001C10.65 -1.39999 20.625 -0.0749885 24.55 7.37501C29.275 16.4 21.6 26.7 11.45 25Z"
                            fill="#ffffff"
                          />
                          <path
                            id="pyK86WFoO"
                            d="M15.4995 16.0004C14.1995 15.0254 12.9745 14.0504 12.8245 13.8254C12.3495 13.2004 12.3745 6.25045 12.8495 5.60045C13.1495 5.22545 13.2995 5.17545 13.6745 5.40045C14.0745 5.65045 14.1245 6.12545 14.1995 9.32545L14.2745 13.0004L16.5745 14.7754C18.1995 16.0254 18.8745 16.7254 18.8745 17.1004C18.8745 18.1254 17.9495 17.8254 15.4995 16.0004Z"
                            fill="#ffffff"
                          />
                        </g>
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1242_3683">
                        <rect width="26" height="26" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="settings-listItem-info">
                  <span className="settings-listItem-name">Archive</span>
                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                tabIndex="0"
                role="listitem"
                className="archive-settings settings-listItem"
                onClick={() => handleSelectSetting("saved")}
              >
                <div className="settings-listItem-image">
                  <svg width="21" height="25" viewBox="0 0 21 25" fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.082 3C4.93894 3 4 3.90589 4 5.00872V21.4415C4.00005 21.5411 4.0277 21.6389 4.08007 21.7247C4.13245 21.8105 4.20766 21.8813 4.29792 21.9297C4.38817 21.9781 4.49018 22.0023 4.5934 21.9998C4.69661 21.9974 4.79727 21.9684 4.88496 21.9158L10.9429 18.2902L17.0008 21.9158C17.0884 21.9684 17.1891 21.9974 17.2923 21.9998C17.3955 22.0023 17.4975 21.9781 17.5878 21.9297C17.6781 21.8813 17.7533 21.8105 17.8056 21.7247C17.858 21.6389 17.8857 21.5411 17.8857 21.4415V5.00872C17.8857 3.90589 16.9468 3 15.8037 3H6.082ZM6.082 4.11779H15.8037C16.3256 4.11779 16.7271 4.50518 16.7271 5.00872V20.4392L11.2464 17.16C11.1551 17.1058 11.05 17.0772 10.9429 17.0772C10.8357 17.0772 10.7306 17.1058 10.6393 17.16L5.15857 20.4392V5.00872C5.15857 4.50518 5.5601 4.11779 6.082 4.11779Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="settings-listItem-info">
                  <span className="settings-listItem-name">Saved</span>
                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div
              tabIndex="0"
              role="listitem"
              className="saved-settings settings-listItem"
              onClick={() => handleSelectSetting("notification")}
            >
              <div className="settings-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_7564_18498)">
                    <path
                      d="M4.79922 9.60182C4.79856 8.10585 5.2639 6.64682 6.13056 5.42747C6.99723 4.20811 8.22218 3.28899 9.63522 2.79782C9.5805 2.45499 9.60081 2.10437 9.69474 1.77015C9.78868 1.43593 9.954 1.12607 10.1793 0.861951C10.4046 0.597827 10.6845 0.385729 10.9998 0.240289C11.315 0.0948494 11.6581 0.0195312 12.0052 0.0195312C12.3524 0.0195312 12.6954 0.0948494 13.0107 0.240289C13.3259 0.385729 13.6058 0.597827 13.8311 0.861951C14.0564 1.12607 14.2218 1.43593 14.3157 1.77015C14.4096 2.10437 14.4299 2.45499 14.3752 2.79782C15.786 3.29101 17.0084 4.21099 17.8728 5.43017C18.7372 6.64935 19.2007 8.1073 19.1992 9.60182V16.8018L22.7992 19.2018V20.4018H1.19922V19.2018L4.79922 16.8018V9.60182ZM14.3992 21.6018C14.3992 22.2383 14.1464 22.8488 13.6963 23.2989C13.2462 23.749 12.6357 24.0018 11.9992 24.0018C11.3627 24.0018 10.7523 23.749 10.3022 23.2989C9.85208 22.8488 9.59922 22.2383 9.59922 21.6018H14.3992Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7564_18498">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="settings-listItem-info">
                <span className="settings-listItem-name">Notification</span>
                <div className="more">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <title>More Info</title>
                    <path
                      id="Vector"
                      d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                      fill="#C5C5C5"
                    />
                  </svg>
                </div>
              </div>
            </div> */}
              <div
                tabIndex="0"
                role="listitem"
                className="privacy-settings settings-listItem"
                onClick={() => {
                  handleSelectSetting("privacy");
                  dispatch(setPrivacySettingSide("open-right"));
                }}
              >
                <div className="settings-listItem-image">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M7.6 6.39844C7.6 3.96839 9.56995 1.99844 12 1.99844C14.4301 1.99844 16.4 3.96838 16.4 6.39844V8.19844H7.6V6.39844Z"
                      stroke="white"
                      stroke-width="1.2"
                    />
                    <rect
                      x="4.6"
                      y="8.6"
                      width="14.8"
                      height="12.8"
                      rx="1.4"
                      stroke="white"
                      stroke-width="1.2"
                    />
                    <circle
                      cx="12"
                      cy="15"
                      r="2.4"
                      stroke="white"
                      stroke-width="1.2"
                    />
                  </svg>
                </div>
                <div className="settings-listItem-info">
                  <span className="settings-listItem-name">Privacy</span>
                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div
              tabIndex="0"
              role="listitem"
              className="privacy-settings settings-listItem"
              onClick={() => handleSelectSetting("security")}
            >
              <div className="settings-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M11.9996 3.60156L3.59961 6.65611V11.2379C3.59961 15.4761 7.18361 19.4394 11.9996 20.4016C16.8156 19.4394 20.3996 15.4761 20.3996 11.2379V6.65611L11.9996 3.60156ZM11.9996 11.9939H18.5329C18.0383 15.1401 15.4716 17.9427 11.9996 18.8208V12.0016H5.46628V7.64884L11.9996 5.27393V11.9939Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="settings-listItem-info">
                <span className="settings-listItem-name">Security</span>
                <div className="more">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <title>More Info</title>
                    <path
                      id="Vector"
                      d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                      fill="#C5C5C5"
                    />
                  </svg>
                </div>
              </div>
            </div> */}
              <div
                tabIndex="0"
                role="listitem"
                className="security-settings settings-listItem"
                onClick={() => handleSelectSetting("theme")}
              >
                <div className="settings-listItem-image">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.9006 10.5053C9.45671 10.5053 7.01278 10.5053 4.56885 10.5053C3.90513 10.5053 3.3456 10.5053 3.31473 9.55602C3.26456 7.91216 3.05233 6.27217 3.00216 4.62831C2.952 2.98444 3.77394 2.01586 5.49498 2.01201C9.81687 2.01201 14.1388 1.98499 18.4607 2.01201C20.2512 2.0313 21.0306 2.93813 20.9882 4.79423C20.9882 5.48882 20.857 6.17954 20.7952 6.87413C20.4827 10.4937 20.4827 10.4937 16.7859 10.4937L11.9006 10.5053Z"
                      fill="#FDFDFE"
                    />
                    <path
                      d="M11.8377 11.978C14.2803 11.978 16.7191 12.0128 19.1694 11.978C20.1534 11.978 20.4351 12.2983 20.4158 13.3016C20.3618 16.4581 19.8023 17.1604 16.6998 17.0408C14.8553 16.9636 13.9176 17.3264 14.1954 19.4526C14.4038 21.0347 13.5317 22.0187 11.9071 21.9994C10.2825 21.9801 9.59181 20.9344 9.80018 19.4796C10.1282 17.1643 9.00527 16.8826 7.14917 17.0524C5.54003 17.2029 3.94633 17.1141 3.67621 14.876C3.33664 11.9896 3.29032 11.9935 6.19988 11.9935L11.8377 11.978Z"
                      fill="#F8FBFC"
                    />
                  </svg>
                </div>
                <div className="settings-listItem-info">
                  <span className="settings-listItem-name">Theme</span>
                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              {/* <div
              tabIndex="0"
              role="listitem"
              className="theme-settings settings-listItem"
              onClick={() => handleSelectSetting("chatwallpapers")}
            >
              <div className="settings-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M4.22222 22C3.61111 22 3.08778 21.7822 2.65222 21.3467C2.21667 20.9111 1.99926 20.3881 2 19.7778V13.1111H4.22222V19.7778H10.8889V22H4.22222ZM13.1111 22V19.7778H19.7778V13.1111H22V19.7778C22 20.3889 21.7822 20.9122 21.3467 21.3478C20.9111 21.7833 20.3881 22.0007 19.7778 22H13.1111ZM5.33333 17.5556L8.66667 13.1111L11.1667 16.4444L14.5 12L18.6667 17.5556H5.33333ZM2 10.8889V4.22222C2 3.61111 2.21778 3.08778 2.65334 2.65222C3.08889 2.21667 3.61185 1.99926 4.22222 2H10.8889V4.22222H4.22222V10.8889H2ZM19.7778 10.8889V4.22222H13.1111V2H19.7778C20.3889 2 20.9122 2.21778 21.3478 2.65334C21.7833 3.08889 22.0007 3.61185 22 4.22222V10.8889H19.7778ZM15.8889 9.77778C15.4074 9.77778 15.0093 9.62037 14.6944 9.30556C14.3796 8.99074 14.2222 8.59259 14.2222 8.11111C14.2222 7.62963 14.3796 7.23148 14.6944 6.91667C15.0093 6.60185 15.4074 6.44445 15.8889 6.44445C16.3704 6.44445 16.7685 6.60185 17.0833 6.91667C17.3981 7.23148 17.5556 7.62963 17.5556 8.11111C17.5556 8.59259 17.3981 8.99074 17.0833 9.30556C16.7685 9.62037 16.3704 9.77778 15.8889 9.77778Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="settings-listItem-info">
                <span className="settings-listItem-name">Chat Wallpapers</span>
                <div className="more">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <title>More Info</title>
                    <path
                      id="Vector"
                      d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                      fill="#C5C5C5"
                    />
                  </svg>
                </div>
              </div>
            </div> */}
              {/* <div
              tabIndex="0"
              role="listitem"
              className="chat-settings settings-listItem"
              onClick={() => handleSelectSetting("mediaautodownload")}
            >
              <div className="settings-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M16.59 9H15V4C15 3.45 14.55 3 14 3H10C9.45 3 9 3.45 9 4V9H7.41C6.52 9 6.07 10.08 6.7 10.71L11.29 15.3C11.68 15.69 12.31 15.69 12.7 15.3L17.29 10.71C17.92 10.08 17.48 9 16.59 9ZM5 19C5 19.55 5.45 20 6 20H18C18.55 20 19 19.55 19 19C19 18.45 18.55 18 18 18H6C5.45 18 5 18.45 5 19Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="settings-listItem-info">
                <span className="settings-listItem-name">
                  Media auto-download
                </span>
                <div className="more">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <title>More Info</title>
                    <path
                      id="Vector"
                      d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                      fill="#C5C5C5"
                    />
                  </svg>
                </div>
              </div>
            </div> */}
              {/* <div
              tabIndex="0"
              role="listitem"
              className="mediaDownload-settings settings-listItem"
              onClick={() => handleSelectSetting("requestaccountinfo")}
            >
              <div className="settings-listItem-image">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M18 22C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8.20711C20 8.0745 19.9473 7.94732 19.8536 7.85355L14.1464 2.14645C14.0527 2.05268 13.9255 2 13.7929 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18ZM13 5.20711C13 4.76165 13.5386 4.53857 13.8536 4.85355L17.1464 8.14644C17.4614 8.46143 17.2383 9 16.7929 9H13.5C13.2239 9 13 8.77614 13 8.5V5.20711ZM7 12.5C7 12.2239 7.22386 12 7.5 12H16.5C16.7761 12 17 12.2239 17 12.5V13.5C17 13.7761 16.7761 14 16.5 14H7.5C7.22386 14 7 13.7761 7 13.5V12.5ZM7 16.5C7 16.2239 7.22386 16 7.5 16H16.5C16.7761 16 17 16.2239 17 16.5V17.5C17 17.7761 16.7761 18 16.5 18H7.5C7.22386 18 7 17.7761 7 17.5V16.5Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="settings-listItem-info">
                <span className="settings-listItem-name">
                  Request account info
                </span>
                <div className="more">
                  <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <title>More Info</title>
                    <path
                      id="Vector"
                      d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                      fill="#C5C5C5"
                    />
                  </svg>
                </div>
              </div>
            </div> */}

              {/* <div
              tabIndex="0"
              role="listitem"
              className="requestAccountInfo-settings settings-listItem"
              onClick={() => handleSelectSetting("keyboardshortcuts")}
            >
              <div className="settings-listItem-image">
                <img src={Keyboard} alt="monitor" />
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M10.8506 12.6506H13.1506L12.0006 9.00063L10.8506 12.6506ZM20.0006 8.69062V6.00063C20.0006 4.90063 19.1006 4.00063 18.0006 4.00063H15.3106L13.4106 2.10062C12.6306 1.32063 11.3606 1.32063 10.5806 2.10062L8.69062 4.00063H6.00063C4.90063 4.00063 4.00063 4.90063 4.00063 6.00063V8.69062L2.10062 10.5906C1.32063 11.3706 1.32063 12.6406 2.10062 13.4206L4.00063 15.3206V18.0006C4.00063 19.1006 4.90063 20.0006 6.00063 20.0006H8.69062L10.5906 21.9006C11.3706 22.6806 12.6406 22.6806 13.4206 21.9006L15.3206 20.0006H18.0006C19.1006 20.0006 20.0006 19.1006 20.0006 18.0006V15.3106L21.9006 13.4106C22.6806 12.6306 22.6806 11.3606 21.9006 10.5806L20.0006 8.69062ZM14.0906 15.4006L13.6006 14.0006H10.4006L9.91062 15.4006C9.78062 15.7606 9.45062 16.0006 9.07062 16.0006C8.92761 16.0014 8.78651 15.9677 8.65935 15.9022C8.53218 15.8368 8.4227 15.7416 8.34022 15.6247C8.25774 15.5079 8.20471 15.3729 8.18563 15.2311C8.16655 15.0894 8.18198 14.9451 8.23062 14.8106L10.6706 7.95062C10.8706 7.38062 11.4006 7.00063 12.0006 7.00063C12.6006 7.00063 13.1306 7.38063 13.3406 7.94063L15.7806 14.8006C15.8293 14.9351 15.8447 15.0794 15.8256 15.2211C15.8065 15.3629 15.7535 15.4979 15.671 15.6147C15.5886 15.7316 15.4791 15.8268 15.3519 15.8922C15.2247 15.9577 15.0836 15.9914 14.9406 15.9906C14.7546 15.9956 14.5718 15.941 14.4189 15.8349C14.2661 15.7288 14.151 15.5767 14.0906 15.4006Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="settings-listItem-info">
                <span className="settings-listItem-name">
                  Keyboard shortcuts
                </span>
                <div className="more">
                  <img src={more} alt="more" />
                </div>
              </div>
            </div> */}

              <div
                tabIndex="0"
                role="listitem"
                className="keyboard-settings settings-listItem"
                onClick={() => handleSelectSetting("help")}
              >
                <div className="settings-listItem-image">
                  <svg width="10" height="15" viewBox="0 0 10 15" fill="none">
                    <path
                      d="M1.25 4.40671C1.25 4.40671 1.30625 3.11765 2.56016 2.08405C3.30469 1.47038 4.19883 1.29265 5 1.28171C5.73164 1.27272 6.38555 1.39655 6.77656 1.58718C7.44492 1.91452 8.75 2.7114 8.75 4.40671C8.75 6.19069 7.61016 6.99929 6.31445 7.8903C5.01875 8.78132 4.6875 9.65515 4.6875 10.6567"
                      stroke="white"
                      stroke-width="1.5625"
                      strokeMiterlimit="10"
                      stroke-linecap="round"
                    />
                    <path
                      d="M4.6875 14.875C5.37786 14.875 5.9375 14.3154 5.9375 13.625C5.9375 12.9346 5.37786 12.375 4.6875 12.375C3.99714 12.375 3.4375 12.9346 3.4375 13.625C3.4375 14.3154 3.99714 14.875 4.6875 14.875Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="settings-listItem-info">
                  <span className="settings-listItem-name">Help</span>
                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                tabIndex="0"
                role="listitem"
                className="logout-settings settings-listItem"
                onClick={() => handleSelectSetting("logout")}
              >
                <div className="settings-listItem-image">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M14.2911 6.71L16.5911 9L7.00108 9C6.73586 9 6.48151 9.10536 6.29397 9.29289C6.10644 9.48043 6.00108 9.73478 6.00108 10C6.00108 10.2652 6.10644 10.5196 6.29397 10.7071C6.48151 10.8946 6.73586 11 7.00108 11L16.5911 11L14.2911 13.29C14.1974 13.383 14.123 13.4936 14.0722 13.6154C14.0214 13.7373 13.9953 13.868 13.9953 14C13.9953 14.132 14.0214 14.2627 14.0722 14.3846C14.123 14.5064 14.1974 14.617 14.2911 14.71C14.384 14.8037 14.4946 14.8781 14.6165 14.9289C14.7384 14.9797 14.8691 15.0058 15.0011 15.0058C15.1331 15.0058 15.2638 14.9797 15.3857 14.9289C15.5075 14.8781 15.6181 14.8037 15.7111 14.71L19.7111 10.71C19.8021 10.6149 19.8735 10.5028 19.9211 10.38C20.0211 10.1365 20.0211 9.86346 19.9211 9.62C19.8735 9.49725 19.8021 9.3851 19.7111 9.29L15.7111 5.29C15.6178 5.19676 15.5072 5.1228 15.3853 5.07234C15.2635 5.02188 15.1329 4.99591 15.0011 4.99591C14.8692 4.99591 14.7387 5.02188 14.6168 5.07234C14.495 5.1228 14.3843 5.19676 14.2911 5.29C14.1978 5.38324 14.1239 5.49393 14.0734 5.61575C14.023 5.73757 13.997 5.86814 13.997 6C13.997 6.13186 14.023 6.26243 14.0734 6.38425C14.1239 6.50607 14.1978 6.61676 14.2911 6.71ZM10.0011 19C10.0011 18.7348 9.89572 18.4804 9.70819 18.2929C9.52065 18.1054 9.2663 18 9.00108 18L3.00108 18C2.73586 18 2.48151 17.8946 2.29397 17.7071C2.10644 17.5196 2.00108 17.2652 2.00108 17L2.00108 3C2.00108 2.73478 2.10644 2.48043 2.29397 2.29289C2.48151 2.10536 2.73586 2 3.00108 2L9.00108 2C9.2663 2 9.52065 1.89464 9.70819 1.70711C9.89572 1.51957 10.0011 1.26522 10.0011 1C10.0011 0.734783 9.89572 0.480429 9.70819 0.292893C9.52065 0.105356 9.2663 -4.69014e-07 9.00108 -4.80607e-07L3.00108 -7.42876e-07C2.20543 -7.77655e-07 1.44237 0.31607 0.879759 0.878679C0.31715 1.44129 0.00107946 2.20435 0.00107943 3L0.00107882 17C0.00107878 17.7956 0.317149 18.5587 0.879758 19.1213C1.44237 19.6839 2.20543 20 3.00108 20L9.00108 20C9.2663 20 9.52065 19.8946 9.70819 19.7071C9.89572 19.5196 10.0011 19.2652 10.0011 19Z"
                      fill="#DA3030"
                    />
                  </svg>
                </div>
                <div className="settings-listItem-info">
                  <span className="settings-listItem-name">Log Out</span>
                  <div className="more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More Info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FocusTrapping>

      {selectedSetting === "archived" && (
        <ArchiveSettings
          setSelectedMenu={setSelectedMenu}
          handlePostSelect={(postId) => handlePostSelect(postId)}
        />
      )}
      {selectedSetting === "saved" && (
        <SavedSettings
          setSelectedMenu={setSelectedMenu}
          handlePostSelect={(postId) => handlePostSelect(postId)}
        />
      )}
      {selectedSetting === "help" && <Help />}
      {selectedSetting === "theme" && <ThemePopup />}
      {selectedSetting === "logout" && <LogoutPopup />}
      {selectedSetting === "privacy" && <PrivacySettings />}
      {selectedSetting === "security" && <SecuritySettings />}
      {selectedSetting === "notification" && <Notifications />}
      {selectedSetting === "chatwallpapers" && <ChatWallpaper />}
      {selectedSetting === "mediaautodownload" && <MediaAutoDownload />}
      {selectedSetting === "keyboardshortcuts" && <KeyboardShortcuts />}
      {selectedSetting === "requestaccountinfo" && <RequestAccountInfo />}

      {/* Settings > Privacy > Options */}
      {selectedSetting === "about" && <About />}
      {selectedSetting === "profilephoto" && <ProfilePhoto />}
      {selectedSetting === "blockedcontacts" && <BlockedContacts />}
      {selectedSetting === "lastseenandonline" && <LastSeenAndOnline />}
      {selectedSetting === "disappearingmsg" && <DefaultMessageTimer />}
      {selectedSetting === "storyprivacy" && <StoryPrivacy />}

      {/* Settings > Privacy > Options > Except Contact */}
      {selectedSetting === "exceptcontact" && <ExceptContacts />}
    </>
  );
}

export default Settings;
