import React, { useState, useEffect, useRef } from "react";
import Spinner from "../Common/Spinner";
import Groups from "./Groups";
import { useDispatch } from "react-redux";
import { ERROR } from "../../Utils/enums";
import axiosRequest from "../../Middleware/api";
import { handleToast } from "../../Redux/actions";
import {
  getUserProfilePic,
  getGroupProfilePic,
  getSponsorProfilePic,
} from "../../Utils/helpers";
import "../../assets/css/whyPost.scss";

function WhyPost({ onClose, userId }) {
  const dispatch = useDispatch();

  const popupRef = useRef(null);
  const containerRef = useRef(null);

  const [whySeen, setWhySeen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toggleGroups, settoggleGroups] = useState(false);

  const fetchTepars = () => {
    setLoading(true);
    axiosRequest
      .get(`post/whyseen/${userId}`)
      .then((res) => {
        setWhySeen(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        setLoading(false);
        onClose(false);
      });
  };

  useEffect(() => {
    fetchTepars();
  }, []);

  const handleClickOutside = (event) => {
    if (containerRef.current === event.target) {
      onClose(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      onClose(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="whyPost" ref={containerRef}>
      <div ref={popupRef} className="dialog">
        {!toggleGroups &&
          (loading ? (
            <div className="whyPost-spinner">
              <Spinner />
            </div>
          ) : (
            <>
              <h4 className="whyPost-heading">Why you're seeing this post</h4>
              <p className="whyPost-info">
                Posts are shown in feed based on privacy selection settings
                across the Tepnot.
              </p>
              <div className="whyPost-reasons">
                {whySeen?.ContactUser?.userId && (
                  <div className="reason reason-contact">
                    <div className="reason-image-wrapper">
                      <div className="reason-image">
                        <img
                          src={getUserProfilePic(
                            whySeen.ContactUser.userId.profile
                          )}
                          alt="profile avatar"
                        />
                      </div>
                    </div>
                    <div className="reason-info">
                      <span className="heading">
                        {`${whySeen.ContactUser.userId.name}
                      ${whySeen.ContactUser.message}`}
                      </span>
                    </div>
                  </div>
                )}
                {/* {whySeen?.groupList?.group_List && (
                  <div
                    className="reason reason-group"
                    onClick={() => settoggleGroups(true)}
                  >
                    <div className="reason-image-wrapper">
                      {whySeen?.groupList?.group_List
                        ?.slice(0, 3)
                        ?.map((group) => (
                          <div className="reason-image" key={group._id}>
                            <img
                              src={getGroupProfilePic(group.groupProfile)}
                              alt="profile avatar"
                            />
                          </div>
                        ))}
                      {whySeen?.groupList?.group_List?.length > 3 && (
                        <div className="group-addIcon reason-image">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 35 35"
                            fill="none"
                          >
                            <circle
                              cx="17.5"
                              cy="17.5"
                              r="17.5"
                              fill="#D9D9D9"
                            />
                            <mask
                              id="mask0_6602_283"
                              style={{ maskType: "alpha" }}
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="35"
                              height="35"
                            >
                              <circle
                                cx="17.5"
                                cy="17.5"
                                r="16.685"
                                fill="#D9D9D9"
                                stroke="white"
                                strokeWidth="1.63"
                              />
                            </mask>
                            <g mask="url(#mask0_6602_283)">
                              <path
                                d="M16.4118 8V15.4118H9V18.5882H16.4118V26H19.5882V18.5882H27V15.4118H19.5882V8H16.4118Z"
                                fill="#9FA3BC"
                              />
                            </g>
                          </svg>
                        </div>
                      )}
                    </div>
                    <div className="reason-info">
                      <span className="heading">
                        {whySeen.groupList.message}
                      </span>
                      <p className="info">
                        {whySeen.groupList.group_List
                          ?.slice(0, 3)
                          ?.map((group, index, array) => (
                            <span key={group._id}>
                              {group.groupName}
                              {index < array?.length - 1 && ", "}{" "}
                            </span>
                          ))}
                      </p>
                    </div>
                  </div>
                )} */}
                {whySeen?.groupList?.group_List &&
                whySeen?.groupList?.group_List[0]?.groupType === "Circle" ? (
                  <div className="reason">
                    <div className="reason-image-wrapper">
                      {whySeen.groupList.group_List.map((group) => (
                        <div className="reason-image" key={group._id}>
                          <img
                            src={getSponsorProfilePic(group.groupProfile)}
                            alt="profile avatar"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="reason-info">
                      <span className="heading">
                        {whySeen.groupList.message}
                      </span>
                      <p className="info">
                        {whySeen.groupList.group_List.map((group) => (
                          <span key={group._id}>{group.groupName}</span>
                        ))}
                      </p>
                    </div>
                  </div>
                ) : (
                  whySeen?.groupList?.group_List && (
                    <div
                      className="reason reason-group"
                      onClick={() => settoggleGroups(true)}
                    >
                      <div className="reason-image-wrapper">
                        {whySeen?.groupList?.group_List
                          ?.slice(0, 3)
                          ?.map((group) => (
                            <div className="reason-image" key={group._id}>
                              <img
                                src={getGroupProfilePic(group.groupProfile)}
                                alt="profile avatar"
                              />
                            </div>
                          ))}
                        {whySeen?.groupList?.group_List?.length > 3 && (
                          <div className="group-addIcon reason-image">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 35 35"
                              fill="none"
                            >
                              <circle
                                cx="17.5"
                                cy="17.5"
                                r="17.5"
                                fill="#D9D9D9"
                              />
                              <mask
                                id="mask0_6602_283"
                                style={{ maskType: "alpha" }}
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="35"
                                height="35"
                              >
                                <circle
                                  cx="17.5"
                                  cy="17.5"
                                  r="16.685"
                                  fill="#D9D9D9"
                                  stroke="white"
                                  strokeWidth="1.63"
                                />
                              </mask>
                              <g mask="url(#mask0_6602_283)">
                                <path
                                  d="M16.4118 8V15.4118H9V18.5882H16.4118V26H19.5882V18.5882H27V15.4118H19.5882V8H16.4118Z"
                                  fill="#9FA3BC"
                                />
                              </g>
                            </svg>
                          </div>
                        )}
                      </div>
                      <div className="reason-info">
                        <span className="heading">
                          {whySeen.groupList.message}
                        </span>
                        <p className="info">
                          {whySeen.groupList.group_List
                            ?.slice(0, 3)
                            ?.map((group, index, array) => (
                              <span key={group._id}>
                                {group.groupName}
                                {index < array?.length - 1 && ", "}{" "}
                              </span>
                            ))}
                        </p>
                      </div>
                    </div>
                  )
                )}
                {whySeen?.message && (
                  <div className="reason reason-message">
                    <div className="reason-info">
                      <span className="heading">{whySeen.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          ))}
      </div>

      {toggleGroups && (
        <Groups
          groups={whySeen.groupList.group_List}
          onClose={() => settoggleGroups(false)}
        />
      )}
    </div>
  );
}

export default WhyPost;
