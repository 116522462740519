import React, { useState, useRef } from "react";
import MediaSlider from "./MediaSlider";
import ComingSoon from "../Common/ComingSoon";
import axiosRequest from "../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import { ERROR, COMMENT_ERROR, LIKED, UNLIKE } from "../../Utils/enums";
import like from "../../assets/image/SVG_Images/like.svg";
import unlike from "../../assets/image/SVG_Images/unlike.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import unlikeGrey from "../../assets/image/SVG_Images/unlikeGrey.svg";
import {
  getSelfUser,
  getUserProfilePic,
  getSponsorProfilePic,
  convertToAgoFormat,
  getSelectedUserDetails,
} from "../../Utils/helpers";
import {
  setTepars,
  handleToast,
  setPostDetails,
  setSelfContact,
  setTogglePostAction,
} from "../../Redux/actions";
import "react-loading-skeleton/dist/skeleton.css";

function TeparsPost({
  post,
  setPostMenu,
  setSelectedPost,
  setPostLikes,
  whySeenPost,
  repostPopup,
  handleRepostModal,
  setSelectPostID,
  setSelectedMenu,
  selectedTeparsType,
}) {
  const dispatch = useDispatch();

  const replyRef = useRef(null);

  const UserID = localStorage.getItem("user_id");

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const selfUser = useSelector((state) => state.selfContact);
  const teparsLoad = useSelector((state) => state.teparsLoad);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);

  const isAdminPost = post?.userId?.userType === "Admin";
  const isCircleGroupMember = post?.group?.[0]?.GroupId?.userRole === "Member";
  const isNormalOrNormalGroup =
    !post?.group || post?.group[0]?.GroupId?.groupType === "Normal";
  const isCircleGroup = post?.group?.[0]?.GroupId?.groupType === "Circle";
  const isSelfPost = post?.userId?._id === UserID;

  const commentToggle = (post) => {
    if (selectedTeparsType === "archived") {
      dispatch(handleToast(COMMENT_ERROR));
    } else {
      dispatch(setPostDetails(post));
      dispatch(setTogglePostAction("ViewPost"));
    }
  };

  const likeToggle = (post) => {
    if (
      !isAdminPost &&
      selectedTeparsType !== "archived" &&
      !isCircleGroupMember
    ) {
      setPostLikes(true);
      setSelectPostID(post?._id);
    }
  };

  const toggleLike = (postId) => {
    axiosRequest
      .put(`post/like/${postId}`)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps + 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps - 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          let newpost = post;
          const newObj = {
            userId: {
              _id: selfUser?._id,
              name: "You",
              profile: selfUser?.profile,
              phone: selfUser?.phone,
              countryCode: selfUser?.countryCode,
              contactStatus: selfUser?.isUserVerified,
            },
          };
          if (post?.selfLike) {
            newpost.TotalLike -= 1;
            newpost.selfLike = false;
            newpost.likeUser = newpost?.likeUser?.filter(
              (item) => item?.userId?._id !== UserID
            );
          } else {
            newpost.TotalLike += 1;
            newpost.selfLike = true;
            newpost.likeUser = [...(newpost?.likeUser || []), newObj];
          }
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newpost : Post
          );
          dispatch(setTepars(findPost));
          if (newpost?.selfLike) {
            setShowAnimation(true);
            setTimeout(() => {
              setShowAnimation(false);
            }, 1000);
          }
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const toggleReadMore = () => {
    setShowFullMessage((prevState) => !prevState);
  };

  const openGoogleMaps = () => {
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${post?.location?.latitude},${post?.location?.longitude}`;
    window.open(googleMapsURL, "_blank");
  };

  const renderLikeInfo = () => {
    if (
      isAdminPost ||
      (post?.group && post?.group[0]?.GroupId?.userRole && isCircleGroupMember)
    ) {
      if (post?.TotalLike > 0) {
        return (
          <p className="users-liked-info">
            <span>{post?.TotalLike}</span> Teps
          </p>
        );
      }
    } else if (post?.TotalLike === 1) {
      if (
        post?.likeUser[0]?.userId?.contactStatus ||
        post?.likeUser[0]?.userId._id === UserID
      ) {
        return (
          <p className="users-liked-info">
            <span>{post?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      } else {
        return (
          <p className="users-liked-info">
            <span>~{post?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      }
    } else if (post?.TotalLike > 0) {
      const userLiked = post?.likeUser?.some(
        (likes) => likes?.userId?._id === UserID
      );
      const otherLikes = post?.TotalLike - 1;

      if (userLiked) {
        return (
          <p className="users-liked-info">
            <span>You</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {otherLikes > 1 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      }
      if (
        post?.likeUser[0]?.userId?.contactStatus ||
        post?.likeUser[0]?.userId._id === UserID
      ) {
        return (
          <p className="users-liked-info">
            <span>{post?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {post?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      } else {
        return (
          <p className="users-liked-info">
            <span>~{post?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {post?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      }
    }
    return null;
  };

  const renderDescription = (description) => {
    if (!description) return null;

    // URL regex that matches URLs not immediately preceded by a character
    const urlRegex = /(?<!\S)(https?:\/\/[^\s]+)/g;

    // Function to render description with links
    const renderDescriptionWithLinks = (text) => {
      const parts = text.split(urlRegex);
      return parts.map((part, index) => {
        if (urlRegex.test(part)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              className="hastags"
              onClick={(e) => e.stopPropagation()}
            >
              {part}
            </a>
          );
        }
        return part;
      });
    };

    const lines = description.split("\n");
    const firstLine = lines[0];

    if (firstLine.length > 140) {
      return (
        <>
          {showFullMessage
            ? renderDescriptionWithLinks(description)
            : renderDescriptionWithLinks(firstLine.slice(0, 140))}
          {!showFullMessage && (
            <span onClick={toggleReadMore} className="read-more">
              ...Read More
            </span>
          )}
        </>
      );
    } else if (lines.length > 2) {
      return (
        <>
          {showFullMessage ? (
            lines.map((line, index) => (
              <React.Fragment key={index}>
                {renderDescriptionWithLinks(line)}
                {index < lines.length - 1 && <br />}
              </React.Fragment>
            ))
          ) : (
            <>
              {renderDescriptionWithLinks(lines[0])}
              <br />
              {renderDescriptionWithLinks(lines[1])}
              <span onClick={toggleReadMore} className="read-more">
                ...Read More
              </span>
            </>
          )}
        </>
      );
    } else {
      return renderDescriptionWithLinks(description);
    }
  };

  // const renderDescription = (description) => {
  //   if (!description) return null;

  //   const lines = description.split("\n");
  //   const firstLine = lines[0];

  //   if (firstLine.length > 140) {
  //     return (
  //       <>
  //         {showFullMessage ? description : firstLine.slice(0, 140)}
  //         {!showFullMessage && (
  //           <span onClick={toggleReadMore} className="read-more">
  //             ...Read More
  //           </span>
  //         )}
  //       </>
  //     );
  //   } else if (lines.length > 2) {
  //     return (
  //       <>
  //         {showFullMessage ? (
  //           lines.map((line, index) => (
  //             <React.Fragment key={index}>
  //               {line}
  //               {index < lines.length - 1 && <br />}
  //             </React.Fragment>
  //           ))
  //         ) : (
  //           <>
  //             {lines[0]}
  //             <br />
  //             {lines[1]}
  //             <span onClick={toggleReadMore} className="read-more">
  //               ...Read More
  //             </span>
  //           </>
  //         )}
  //       </>
  //     );
  //   } else {
  //     return description;
  //   }
  // };

  return (
    <>
      <div className="post-wrapper">
        {post?.RepostPostId && (
          <div className="reposted-notification">
            <div className="reposted-notification-wrapper">
              <div className="repost-image">
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none">
                  <title>Repost</title>
                  <g id="Iconsax/Linear/messagetext">
                    <g id="Group 1261154220">
                      <g id="Group">
                        <path
                          id="Vector"
                          d="M19.9523 11.9214V9.19735C19.9523 8.11367 19.5218 7.07437 18.7555 6.3081C17.9893 5.54182 16.95 5.11133 15.8663 5.11133H4.97021"
                          stroke={
                            theme === "Light"
                              ? "#686868"
                              : theme === "Dark"
                              ? "#999999"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#686868"
                              : "#999999"
                          }
                          strokeWidth="1.37293"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M9.0537 9.19158L4.96631 5.10556L9.0537 1.01953"
                          stroke={
                            theme === "Light"
                              ? "#686868"
                              : theme === "Dark"
                              ? "#999999"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#686868"
                              : "#999999"
                          }
                          strokeWidth="1.37293"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <g id="Group_2">
                        <path
                          id="Vector_3"
                          d="M4.96566 12.0005V14.7245C4.96566 15.8082 5.39615 16.8475 6.16243 17.6138C6.92871 18.3801 7.96801 18.8105 9.05169 18.8105H19.9478"
                          stroke={
                            theme === "Light"
                              ? "#686868"
                              : theme === "Dark"
                              ? "#999999"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#686868"
                              : "#999999"
                          }
                          strokeWidth="1.37293"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="Vector_4"
                          d="M15.8643 14.7303L19.9517 18.8163L15.8643 22.9023"
                          stroke={
                            theme === "Light"
                              ? "#686868"
                              : theme === "Dark"
                              ? "#999999"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#686868"
                              : "#999999"
                          }
                          strokeWidth="1.37293"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              {post?.RepostPostId?.circleData ? (
                <span className="text">
                  {isSelfPost ? "You reposted" : "Reposted"}
                  <span
                    className="reposted-user-name"
                    onClick={() => setShowReplyPopup(true)}
                  >
                    @
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.36996 2.03975V3.12534L2.0749 3.23084C1.62999 3.26664 1.20993 3.45059 0.881885 3.75328C0.55384 4.05596 0.336756 4.45989 0.265342 4.90049C0.233998 5.09773 0.204182 5.29497 0.17819 5.49298C0.172001 5.54085 0.18118 5.58945 0.204401 5.63177C0.227622 5.67409 0.263685 5.70794 0.307389 5.72844L0.366255 5.75596C4.5167 7.72072 9.48669 7.72072 13.6364 5.75596L13.6952 5.72844C13.7388 5.70782 13.7747 5.67392 13.7978 5.63161C13.8209 5.5893 13.8299 5.54077 13.8237 5.49298C13.7978 5.29507 13.769 5.09756 13.7373 4.90049C13.6659 4.45989 13.4488 4.05596 13.1207 3.75328C12.7927 3.45059 12.3726 3.26664 11.9277 3.23084L10.6327 3.1261V2.04052C10.6327 1.72008 10.5178 1.41026 10.3088 1.1674C10.0997 0.924544 9.81045 0.764783 9.49356 0.717176L8.56088 0.577273C7.52695 0.422159 6.47567 0.422159 5.44174 0.577273L4.50906 0.717176C4.1923 0.764764 3.90314 0.924414 3.69411 1.16711C3.48508 1.40981 3.37006 1.71945 3.36996 2.03975ZM8.3904 1.71102C7.46949 1.5729 6.53313 1.5729 5.61222 1.71102L4.67954 1.85092C4.63429 1.85769 4.59297 1.88048 4.56308 1.91513C4.5332 1.94978 4.51675 1.994 4.5167 2.03975V3.04506C6.17175 2.95025 7.83087 2.95025 9.48592 3.04506V2.03975C9.48587 1.994 9.46942 1.94978 9.43953 1.91513C9.40965 1.88048 9.36833 1.85769 9.32308 1.85092L8.3904 1.71102Z"
                        fill="var(--color-primary-d)"
                      />
                      <path
                        d="M13.9706 7.09372C13.969 7.06901 13.9615 7.04503 13.9487 7.02385C13.9359 7.00267 13.9181 6.98493 13.8969 6.97214C13.8757 6.95936 13.8517 6.95191 13.827 6.95045C13.8022 6.94899 13.7775 6.95356 13.755 6.96376C9.49596 8.84977 4.50381 8.84977 0.244804 6.96376C0.222239 6.95356 0.197534 6.94899 0.172814 6.95045C0.148093 6.95191 0.124098 6.95936 0.10289 6.97214C0.0816821 6.98493 0.0638969 7.00267 0.0510637 7.02385C0.0382304 7.04503 0.0307331 7.06901 0.0292166 7.09372C-0.0489134 8.55734 0.0297676 10.0251 0.263917 11.472C0.335331 11.9126 0.552415 12.3165 0.88046 12.6192C1.2085 12.9219 1.62856 13.1058 2.07348 13.1416L3.50461 13.2578C5.83097 13.4452 8.16804 13.4452 10.4952 13.2578L11.9263 13.1424C12.3713 13.1066 12.7915 12.9225 13.1195 12.6197C13.4476 12.3168 13.6646 11.9127 13.7359 11.472C13.9698 10.0233 14.0493 8.55544 13.9706 7.09449V7.09372Z"
                        fill="var(--color-primary-d)"
                      />
                    </svg>
                    {post?.RepostPostId?.circleData?.groupName}
                  </span>
                  circle post
                </span>
              ) : isSelfPost ? (
                <span className="text">
                  You reposted
                  <span
                    className="reposted-user-name"
                    onClick={() =>
                      getSelectedUserDetails(
                        post?.RepostPostId?.userId?._id,
                        dispatch,
                        setSelectedMenu
                      )
                    }
                  >
                    @{post?.RepostPostId?.userId?.name}
                  </span>
                  post
                </span>
              ) : (
                <span className="text">Reposted</span>
              )}
            </div>
          </div>
        )}
        <article id={`post-${post?._id}`} className="post">
          <div className="header">
            <div className="profile">
              {teparsLoad ? (
                <Skeleton width={60} height={60} circle={true} />
              ) : isNormalOrNormalGroup ? (
                <div
                  className="profile-image"
                  tabIndex="0"
                  onClick={() => {
                    getSelectedUserDetails(
                      post?.userId?._id,
                      dispatch,
                      setSelectedMenu
                    );
                  }}
                >
                  <img
                    className="profile-avatar"
                    src={getUserProfilePic(post?.userId?.profile)}
                    alt="Profile Avatar"
                  />
                </div>
              ) : (
                <div
                  className="profile-image"
                  tabIndex="0"
                  onClick={() => setShowReplyPopup(true)}
                >
                  <img
                    className="profile-avatar"
                    src={getSponsorProfilePic(
                      post?.group[0]?.GroupId?.groupProfile
                    )}
                    alt="Profile Avatar"
                  />
                </div>
              )}
              <div className="profile-info">
                {isNormalOrNormalGroup ? (
                  <div className="profile-info-wrapper">
                    <div className="profile-info-name">
                      <span
                        className="name"
                        tabIndex="0"
                        onClick={() =>
                          getSelectedUserDetails(
                            post?.userId?._id,
                            dispatch,
                            setSelectedMenu
                          )
                        }
                      >
                        {isSelfPost
                          ? post?.userId?.webName
                            ? post?.userId?.webName + " (You)"
                            : post?.userId?.name + " (You)"
                          : getSelfUser(post.userId) || <Skeleton />}
                      </span>
                      {post?.location?.name && (
                        <>
                          <span> is in </span>
                          <span
                            className="location-name"
                            onClick={() => openGoogleMaps()}
                          >
                            <svg
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                              fill="none"
                              className="location-icon"
                            >
                              <path
                                d="M6.5 0.9375C4.25725 0.9375 2.4375 2.82744 2.4375 5.15625C2.4375 8.90625 6.5 14.0625 6.5 14.0625C6.5 14.0625 10.5625 8.90625 10.5625 5.15625C10.5625 2.82744 8.74275 0.9375 6.5 0.9375ZM6.5 7.5C6.17861 7.5 5.86443 7.39003 5.5972 7.18401C5.32997 6.97798 5.12169 6.68514 4.9987 6.34253C4.8757 5.99992 4.84352 5.62292 4.90622 5.25921C4.96892 4.89549 5.12369 4.5614 5.35095 4.29917C5.57821 4.03695 5.86776 3.85837 6.18298 3.78603C6.4982 3.71368 6.82493 3.75081 7.12186 3.89273C7.41879 4.03464 7.67258 4.27496 7.85114 4.58331C8.0297 4.89165 8.125 5.25416 8.125 5.625C8.12453 6.12211 7.95317 6.59871 7.64853 6.95023C7.34388 7.30174 6.93083 7.49946 6.5 7.5Z"
                                stroke="#1891A2"
                              />
                            </svg>
                            {post?.location?.name}
                          </span>
                        </>
                      )}
                    </div>

                    <div className="profile-details">
                      <p className="profile-details-time">
                        {convertToAgoFormat(post?.createAt)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="profile-info-wrapper">
                    <div className="profile-details">
                      <div className="circle-image">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.36996 2.03975V3.12534L2.0749 3.23084C1.62999 3.26664 1.20993 3.45059 0.881885 3.75328C0.55384 4.05596 0.336756 4.45989 0.265342 4.90049C0.233998 5.09773 0.204182 5.29497 0.17819 5.49298C0.172001 5.54085 0.18118 5.58945 0.204401 5.63177C0.227622 5.67409 0.263685 5.70794 0.307389 5.72844L0.366255 5.75596C4.5167 7.72072 9.48669 7.72072 13.6364 5.75596L13.6952 5.72844C13.7388 5.70782 13.7747 5.67392 13.7978 5.63161C13.8209 5.5893 13.8299 5.54077 13.8237 5.49298C13.7978 5.29507 13.769 5.09756 13.7373 4.90049C13.6659 4.45989 13.4488 4.05596 13.1207 3.75328C12.7927 3.45059 12.3726 3.26664 11.9277 3.23084L10.6327 3.1261V2.04052C10.6327 1.72008 10.5178 1.41026 10.3088 1.1674C10.0997 0.924544 9.81045 0.764783 9.49356 0.717176L8.56088 0.577273C7.52695 0.422159 6.47567 0.422159 5.44174 0.577273L4.50906 0.717176C4.1923 0.764764 3.90314 0.924414 3.69411 1.16711C3.48508 1.40981 3.37006 1.71945 3.36996 2.03975ZM8.3904 1.71102C7.46949 1.5729 6.53313 1.5729 5.61222 1.71102L4.67954 1.85092C4.63429 1.85769 4.59297 1.88048 4.56308 1.91513C4.5332 1.94978 4.51675 1.994 4.5167 2.03975V3.04506C6.17175 2.95025 7.83087 2.95025 9.48592 3.04506V2.03975C9.48587 1.994 9.46942 1.94978 9.43953 1.91513C9.40965 1.88048 9.36833 1.85769 9.32308 1.85092L8.3904 1.71102Z"
                            fill={
                              theme === "Light"
                                ? "#2a2a2a"
                                : theme === "Dark"
                                ? "#ffffff"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#2a2a2a"
                                : "#ffffff"
                            }
                          />
                          <path
                            d="M13.9706 7.09372C13.969 7.06901 13.9615 7.04503 13.9487 7.02385C13.9359 7.00267 13.9181 6.98493 13.8969 6.97214C13.8757 6.95936 13.8517 6.95191 13.827 6.95045C13.8022 6.94899 13.7775 6.95356 13.755 6.96376C9.49596 8.84977 4.50381 8.84977 0.244804 6.96376C0.222239 6.95356 0.197534 6.94899 0.172814 6.95045C0.148093 6.95191 0.124098 6.95936 0.10289 6.97214C0.0816821 6.98493 0.0638969 7.00267 0.0510637 7.02385C0.0382304 7.04503 0.0307331 7.06901 0.0292166 7.09372C-0.0489134 8.55734 0.0297676 10.0251 0.263917 11.472C0.335331 11.9126 0.552415 12.3165 0.88046 12.6192C1.2085 12.9219 1.62856 13.1058 2.07348 13.1416L3.50461 13.2578C5.83097 13.4452 8.16804 13.4452 10.4952 13.2578L11.9263 13.1424C12.3713 13.1066 12.7915 12.9225 13.1195 12.6197C13.4476 12.3168 13.6646 11.9127 13.7359 11.472C13.9698 10.0233 14.0493 8.55544 13.9706 7.09449V7.09372Z"
                            fill={
                              theme === "Light"
                                ? "#2a2a2a"
                                : theme === "Dark"
                                ? "#ffffff"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#2a2a2a"
                                : "#ffffff"
                            }
                          />
                        </svg>
                      </div>
                      <span
                        className="name"
                        onClick={() => setShowReplyPopup(true)}
                      >
                        {post?.group[0]?.GroupId?.groupName}
                      </span>
                    </div>
                    <div className="circle-member">
                      <span
                        className="name"
                        tabIndex="0"
                        onClick={() =>
                          getSelectedUserDetails(
                            post?.userId?._id,
                            dispatch,
                            setSelectedMenu
                          )
                        }
                      >
                        {isSelfPost
                          ? post?.userId?.webName
                            ? post?.userId?.webName + " (You)"
                            : post?.userId?.name + " (You)"
                          : getSelfUser(post.userId) || <Skeleton />}
                      </span>
                      {post?.location?.name && (
                        <>
                          <span> is in </span>
                          <span
                            className="location-name"
                            onClick={() => openGoogleMaps()}
                          >
                            <svg
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                              fill="none"
                              className="location-icon"
                            >
                              <path
                                d="M6.5 0.9375C4.25725 0.9375 2.4375 2.82744 2.4375 5.15625C2.4375 8.90625 6.5 14.0625 6.5 14.0625C6.5 14.0625 10.5625 8.90625 10.5625 5.15625C10.5625 2.82744 8.74275 0.9375 6.5 0.9375ZM6.5 7.5C6.17861 7.5 5.86443 7.39003 5.5972 7.18401C5.32997 6.97798 5.12169 6.68514 4.9987 6.34253C4.8757 5.99992 4.84352 5.62292 4.90622 5.25921C4.96892 4.89549 5.12369 4.5614 5.35095 4.29917C5.57821 4.03695 5.86776 3.85837 6.18298 3.78603C6.4982 3.71368 6.82493 3.75081 7.12186 3.89273C7.41879 4.03464 7.67258 4.27496 7.85114 4.58331C8.0297 4.89165 8.125 5.25416 8.125 5.625C8.12453 6.12211 7.95317 6.59871 7.64853 6.95023C7.34388 7.30174 6.93083 7.49946 6.5 7.5Z"
                                stroke="#1891A2"
                              />
                            </svg>
                            {post?.location?.name}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {selectedTeparsType !== "archived" && (
                  <button
                    className="btn profile-cta"
                    onClick={() => {
                      setPostMenu(true);
                      setSelectedPost(post);
                    }}
                    title="More options"
                  >
                    <svg width="6" height="16" viewBox="0 0 6 24" fill="none">
                      <path
                        d="M3 0.75C1.625 0.75 0.5 1.875 0.5 3.25C0.5 4.625 1.625 5.75 3 5.75C4.375 5.75 5.5 4.625 5.5 3.25C5.5 1.875 4.375 0.75 3 0.75ZM3 18.25C1.625 18.25 0.5 19.375 0.5 20.75C0.5 22.125 1.625 23.25 3 23.25C4.375 23.25 5.5 22.125 5.5 20.75C5.5 19.375 4.375 18.25 3 18.25ZM3 9.5C1.625 9.5 0.5 10.625 0.5 12C0.5 13.375 1.625 14.5 3 14.5C4.375 14.5 5.5 13.375 5.5 12C5.5 10.625 4.375 9.5 3 9.5Z"
                        fill={
                          theme === "Light"
                            ? "#2a2a2a"
                            : theme === "Dark"
                            ? "#ffffff"
                            : window.matchMedia("(prefers-color-scheme: light)")
                                .matches
                            ? "#2a2a2a"
                            : "#ffffff"
                        }
                      />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="post-info-wrapper">
            {post?.description && (
              <div className="post-info">
                <p className="info">{renderDescription(post?.description)}</p>
              </div>
            )}
            {/* Media */}
            <MediaSlider
              post={post}
              showAnimation={showAnimation}
              setShowAnimation={setShowAnimation}
            />
            <div className="post-media-cta">
              <div className="media-cta">
                {/* Like */}
                <button
                  className="btn btn-like"
                  onClick={() => {
                    toggleLike(post?._id);
                  }}
                >
                  <img
                    src={
                      post?.selfLike
                        ? like
                        : theme === "Light" ||
                          (theme !== "Dark" &&
                            window.matchMedia("(prefers-color-scheme: light)")
                              .matches)
                        ? unlike
                        : unlikeGrey
                    }
                    className={post?.selfLike ? "animate" : ""}
                    title={post?.selfLike ? "Unteps" : "Teps"}
                    alt="Teps"
                  />
                </button>
                {/* Comment */}
                {!post?.turnOffComment && (
                  <button
                    className="btn btn-comment"
                    onClick={() => commentToggle(post)}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <title>Comment</title>
                      <g id="Iconsax/Linear/messagetext">
                        <g id="Group 34106">
                          <path
                            id="Vector"
                            d="M7.2793 9.35156H16.745M7.2793 14.6112H13.9053"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M11.9932 22.1033C13.9902 22.1033 15.9423 21.5111 17.6027 20.4014C19.2631 19.2918 20.5572 17.7146 21.3214 15.8693C22.0856 14.0241 22.2856 11.9936 21.896 10.0347C21.5064 8.07574 20.5448 6.27636 19.1327 4.86405C17.7207 3.45174 15.9216 2.48995 13.963 2.1003C12.0044 1.71064 9.97431 1.91063 8.12937 2.67496C6.28443 3.43929 4.70754 4.73365 3.59809 6.39435C2.48865 8.05504 1.89648 10.0075 1.89648 12.0048C1.89648 13.6744 2.30035 15.2476 3.01835 16.6355L1.89648 22.1033L7.36331 20.9813C8.74993 21.6983 10.325 22.1033 11.9932 22.1033Z"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                )}
                {/* Reply */}
                {post?.canReply && !isSelfPost && !isAdminPost && (
                  <button
                    ref={replyRef}
                    className="btn btn-reply"
                    onClick={() => setShowReplyPopup(true)}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <title>Reply</title>
                      <g id="Frame 121">
                        <path
                          id="Vector"
                          d="M20.9889 9.74951L14.9783 2.63363C14.8038 2.42713 14.5915 2.26216 14.3549 2.14917C14.1184 2.03618 13.8626 1.97765 13.604 1.97729C13.3454 1.97693 13.0895 2.03475 12.8526 2.14707C12.6157 2.2594 12.403 2.42379 12.2279 2.6298L6.19658 9.7289C5.0976 11.0227 5.94369 13.1127 7.568 13.1149L10.7896 13.1194C10.7825 18.1674 1.44786 21.6879 1.44786 21.6879C1.44786 21.6879 16.3734 23.2231 16.3874 13.1272L19.6099 13.1317C21.2333 13.134 22.0843 11.0463 20.9889 9.74951Z"
                          stroke={
                            theme === "Light"
                              ? "#686868"
                              : theme === "Dark"
                              ? "#999999"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#686868"
                              : "#999999"
                          }
                          strokeWidth="1.37293"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </button>
                )}
              </div>
              {/* Re-post */}
              <div className="repost">
                {post?.settingRepost &&
                  (UserID === post?.userId?._id ? (
                    <>
                      <button
                        className="btn btn-repost"
                        onClick={() => repostPopup(post)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <title>Repost</title>
                          <g id="Iconsax/Linear/messagetext">
                            <g id="Group 1261154220">
                              <g id="Group">
                                <path
                                  id="Vector"
                                  d="M19.9523 11.9214V9.19735C19.9523 8.11367 19.5218 7.07437 18.7555 6.3081C17.9893 5.54182 16.95 5.11133 15.8663 5.11133H4.97021"
                                  stroke={
                                    theme === "Light"
                                      ? "#686868"
                                      : theme === "Dark"
                                      ? "#999999"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#686868"
                                      : "#999999"
                                  }
                                  strokeWidth="1.37293"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  id="Vector_2"
                                  d="M9.0537 9.19158L4.96631 5.10556L9.0537 1.01953"
                                  stroke={
                                    theme === "Light"
                                      ? "#686868"
                                      : theme === "Dark"
                                      ? "#999999"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#686868"
                                      : "#999999"
                                  }
                                  strokeWidth="1.37293"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <g id="Group_2">
                                <path
                                  id="Vector_3"
                                  d="M4.96566 12.0005V14.7245C4.96566 15.8082 5.39615 16.8475 6.16243 17.6138C6.92871 18.3801 7.96801 18.8105 9.05169 18.8105H19.9478"
                                  stroke={
                                    theme === "Light"
                                      ? "#686868"
                                      : theme === "Dark"
                                      ? "#999999"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#686868"
                                      : "#999999"
                                  }
                                  strokeWidth="1.37293"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  id="Vector_4"
                                  d="M15.8643 14.7303L19.9517 18.8163L15.8643 22.9023"
                                  stroke={
                                    theme === "Light"
                                      ? "#686868"
                                      : theme === "Dark"
                                      ? "#999999"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#686868"
                                      : "#999999"
                                  }
                                  strokeWidth="1.37293"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </button>
                      <span className="repost-number">{post?.countRepost}</span>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-repost"
                        onClick={() => handleRepostModal(post)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <title>Repost</title>
                          <g id="Iconsax/Linear/messagetext">
                            <g id="Group 1261154220">
                              <g id="Group">
                                <path
                                  id="Vector"
                                  d="M19.9523 11.9214V9.19735C19.9523 8.11367 19.5218 7.07437 18.7555 6.3081C17.9893 5.54182 16.95 5.11133 15.8663 5.11133H4.97021"
                                  stroke={
                                    theme === "Light"
                                      ? "#686868"
                                      : theme === "Dark"
                                      ? "#999999"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#686868"
                                      : "#999999"
                                  }
                                  strokeWidth="1.37293"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  id="Vector_2"
                                  d="M9.0537 9.19158L4.96631 5.10556L9.0537 1.01953"
                                  stroke={
                                    theme === "Light"
                                      ? "#686868"
                                      : theme === "Dark"
                                      ? "#999999"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#686868"
                                      : "#999999"
                                  }
                                  strokeWidth="1.37293"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <g id="Group_2">
                                <path
                                  id="Vector_3"
                                  d="M4.96566 12.0005V14.7245C4.96566 15.8082 5.39615 16.8475 6.16243 17.6138C6.92871 18.3801 7.96801 18.8105 9.05169 18.8105H19.9478"
                                  stroke={
                                    theme === "Light"
                                      ? "#686868"
                                      : theme === "Dark"
                                      ? "#999999"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#686868"
                                      : "#999999"
                                  }
                                  strokeWidth="1.37293"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  id="Vector_4"
                                  d="M15.8643 14.7303L19.9517 18.8163L15.8643 22.9023"
                                  stroke={
                                    theme === "Light"
                                      ? "#686868"
                                      : theme === "Dark"
                                      ? "#999999"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#686868"
                                      : "#999999"
                                  }
                                  strokeWidth="1.37293"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </button>
                      <span className="repost-number">{post?.countRepost}</span>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div className="post-report">
            {post?.TotalLike > 0 && (
              <div
                className="users-liked"
                tabIndex="0"
                onClick={() => likeToggle(post)}
              >
                {!isAdminPost && (!post?.group || !isCircleGroupMember) && (
                  <div className="users-profiles-wrapper">
                    {post?.likeUser?.slice(0, 3).map((like, index) => (
                      <div className="users-profile" key={index}>
                        <img
                          src={getUserProfilePic(like?.userId?.profile)}
                          alt="profile"
                        />
                      </div>
                    ))}
                  </div>
                )}
                <span>{renderLikeInfo()}</span>
              </div>
            )}
            {post?.turnOffComment !== true &&
              selectedTeparsType !== "archived" &&
              (post?.comments >= 1 ? (
                <div
                  className="users-comments"
                  tabIndex="0"
                  onClick={() => commentToggle(post)}
                >
                  <span className="view-all">
                    View all {post?.comments} comments
                  </span>
                </div>
              ) : (
                <div className="users-comments" tabIndex="0">
                  <span className="view-all">No comments yet</span>
                </div>
              ))}
            {post?.turnOffComment !== true &&
              selectedTeparsType !== "archived" && (
                <div className="add-comment-wrapper" tabIndex="0">
                  <div className="comment-user-profile">
                    <img
                      src={getUserProfilePic(selfUser?.profile)}
                      alt="profile"
                    />
                  </div>
                  <div
                    className="add-comment"
                    onClick={() => commentToggle(post)}
                  >
                    <span className="comment">Add a comment...</span>
                  </div>
                </div>
              )}
            {isCircleGroup && (
              <div className="circle-post-time" tabIndex="0">
                <p className="text">{convertToAgoFormat(post?.createAt)}</p>
              </div>
            )}
            {!isSelfPost && !isAdminPost && (
              <div
                className="why-seen-post"
                tabIndex="0"
                onClick={() => whySeenPost(post)}
              >
                <p className="text">Why you're seeing this post</p>
              </div>
            )}
          </div>
        </article>
      </div>

      {showReplyPopup && (
        <ComingSoon onClose={() => setShowReplyPopup(false)} />
      )}
    </>
  );
}

export default TeparsPost;
