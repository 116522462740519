import { React, useEffect, useState } from "react";
import {
  GROUP,
  RE_POSTING,
  CONTACT_ALL,
  OFF_COMMENT,
  ONLY_WITH_ME,
  CAN_REPLY_POST,
  CAN_SHARE_POST,
  CONTACT_EXCEPT,
  ONLY_SHARE_WITH,
} from "../../Utils/enums";
import { useSelector } from "react-redux";
import { getUserProfilePic } from "../../Utils/helpers";
import "../../assets/css/tepars.scss";

function Privacy({
  setHandlePrivacyOptions,
  getDescription,
  setGetDescription,
  privacy,
  setPrivacy,
  getVisibility,
  getRePosting,
  setGetRePosting,
  getOffComment,
  setGetOffComment,
  getCanSharePost,
  setGetCanSharePost,
  getCanReplyPost,
  setGetCanReplyPost,
  getSelectedLocation,
  sharedGroups,
  sharedGroupsList,
  setDescriptionEdit,
}) {
  const UserID = localStorage.getItem("user_id");
  const User = useSelector((state) => state?.selfContact);
  const togglePost = useSelector((state) => state.togglePost);
  const postDetails = useSelector((state) => state.postDetails);

  const groupCounts = sharedGroupsList?.length;

  const [emojiPopup, setemojiPopup] = useState(false);
  const [value, setValue] = useState(
    postDetails?.description ? postDetails.description : getDescription
  );
  const [lastContactText, setLastContactText] = useState("My all contacts");
  const [prevPrivacy, setPrevPrivacy] = useState(privacy); // Store previous privacy state
  const isCirclePost = postDetails?.group?.[0]?.GroupId?.groupType === "Circle";
  const isSelfPost = postDetails?.userId?._id === UserID;
  const showPrivacy = isCirclePost && isSelfPost;
  const circleName = postDetails?.group?.[0]?.GroupId?.groupName;

  const toggleMultiple = (val) => {
    if (val === RE_POSTING) {
      setGetRePosting(!getRePosting);
    }
    if (val === OFF_COMMENT) {
      setGetOffComment(!getOffComment);
    }
    if (val === CAN_REPLY_POST) {
      setGetCanReplyPost(!getCanReplyPost);
    }
    if (val === CAN_SHARE_POST) {
      setGetCanSharePost(!getCanSharePost);
    }
  };

  const handleCheckmarkClick = (value) => {
    if (value === ONLY_WITH_ME) {
      setPrevPrivacy(privacy);
      setPrivacy(value);
    } else if (value === CONTACT_ALL) {
      setPrevPrivacy(privacy);
      setPrivacy(prevPrivacy === ONLY_WITH_ME ? CONTACT_ALL : privacy);
      setHandlePrivacyOptions("SeePost");
    }
  };

  // Update lastContactText whenever privacy changes
  useEffect(() => {
    if (privacy === CONTACT_EXCEPT) {
      setLastContactText("My contacts excepts");
    } else if (privacy === ONLY_SHARE_WITH) {
      setLastContactText("Only share with");
    } else if (privacy === CONTACT_ALL) {
      setLastContactText("My all contacts");
    }
  }, [privacy]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (togglePost === "EditPost") {
      setDescriptionEdit(true);
    }
  };

  // Handle contacts click
  const handleContactsClick = () => {
    if (privacy === ONLY_WITH_ME) {
      setPrivacy(CONTACT_ALL);
    }
    setHandlePrivacyOptions("SeePost");
  };

  useEffect(() => {
    var timer = setTimeout(() => {
      setGetDescription(value);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  return (
    <div className="privacy">
      <div className="privacy-wrapper">
        {/* Tepars Profile */}
        {!showPrivacy && (
          <div className="tepars-profile">
            <div className={`profile-details`}>
              <div className="profile-image">
                <img src={getUserProfilePic(User?.profile)} alt="profile" />
              </div>
              <div className="profile-info">
                <span className="name">
                  {User?.name}
                  {getSelectedLocation && " - In " + getSelectedLocation.name}
                </span>

                {togglePost === "RePost" ? (
                  ""
                ) : (
                  <div
                    className="btn-location"
                    onClick={() => setHandlePrivacyOptions("Location")}
                  >
                    <div className="location-icon">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M7 0.875C4.58473 0.875 2.625 2.63895 2.625 4.8125C2.625 8.3125 7 13.125 7 13.125C7 13.125 11.375 8.3125 11.375 4.8125C11.375 2.63895 9.41527 0.875 7 0.875ZM7 7C6.65388 7 6.31554 6.89736 6.02775 6.70507C5.73997 6.51278 5.51566 6.23947 5.38321 5.9197C5.25076 5.59993 5.2161 5.24806 5.28363 4.90859C5.35115 4.56912 5.51782 4.2573 5.76256 4.01256C6.0073 3.76782 6.31912 3.60115 6.65859 3.53363C6.99806 3.4661 7.34992 3.50076 7.6697 3.63321C7.98947 3.76566 8.26278 3.98997 8.45507 4.27775C8.64736 4.56554 8.75 4.90388 8.75 5.25C8.74949 5.71397 8.56496 6.1588 8.23688 6.48688C7.9088 6.81496 7.46397 6.99949 7 7Z"
                          stroke="#1891A2"
                        />
                      </svg>
                    </div>
                    <span className="text">Location</span>
                  </div>
                )}
              </div>
            </div>

            {((togglePost === "RePost" && value) ||
              togglePost === "EditPost" ||
              !togglePost) && (
              <div className="profile-caption">
                <textarea
                  name="caption"
                  cols="40"
                  rows="3"
                  placeholder="Write a caption..."
                  className="caption"
                  value={value}
                  onChange={(e) => handleChange(e)}
                  readOnly={
                    togglePost === "RePost" || postDetails?.RepostPostId
                  }
                  maxLength={2200}
                  // onKeyUp={() => {
                  //   document.querySelector("textarea").contentEditable = true;
                  // }}
                />

                {/* <div
              className="profile-emoji"
              onClick={() => setemojiPopup(!emojiPopup)}
            >
              💀
            </div> */}
              </div>
            )}
          </div>
        )}

        {emojiPopup ? (
          <div className="profile-emoji-popup" />
        ) : (
          <>
            {/* Who can see post? */}
            <div className="post-visible">
              <h3 className="title">Who can see your post?</h3>
              <p className="sub-info">
                {!showPrivacy
                  ? "Your post will be visible to Tepnot members and your groups."
                  : "Your post will be visible to " +
                    circleName +
                    " circle members."}
              </p>
              {!showPrivacy && (
                <div className="visible-to-contacts">
                  <div className="contact-select">
                    <div className="group-check">
                      <input
                        type="radio"
                        id="contact-check"
                        aria-label="Check this box"
                        checked={
                          privacy === CONTACT_ALL ||
                          privacy === CONTACT_EXCEPT ||
                          privacy === ONLY_SHARE_WITH
                        }
                      />
                      {/* <span
                      className="checkmark"
                      onClick={() => handleCheckmarkClick(CONTACT_ALL)}
                    /> */}
                      <div
                        className="checkmark"
                        role="radio"
                        tabIndex="0"
                        onClick={handleContactsClick}
                      >
                        <div className="offRadio radio-cmn"></div>
                        <div className="onRadio radio-cmn"></div>
                      </div>
                    </div>
                    <div
                      className="contact-select-info"
                      onClick={handleContactsClick}
                    >
                      <div className="wrapper" onClick={handleContactsClick}>
                        <span className="title">Contacts</span>
                        <p className="sub-text">{lastContactText}</p>
                      </div>
                      <div className="btn btn-more">
                        <svg
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                        >
                          <title>More info</title>
                          <path
                            id="Vector"
                            d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                            fill="#C5C5C5"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="onlyme-select">
                    <div className="group-check">
                      <input
                        type="radio"
                        id="contact-check2"
                        aria-label="Check this box"
                        checked={privacy === ONLY_WITH_ME}
                      />
                      {/* <span
                      className="checkmark"
                      onClick={() => handleCheckmarkClick(ONLY_WITH_ME)}
                    /> */}
                      <div
                        className="checkmark"
                        role="radio"
                        tabIndex="0"
                        onClick={() => handleCheckmarkClick(ONLY_WITH_ME)}
                      >
                        <div className="offRadio radio-cmn"></div>
                        <div className="onRadio radio-cmn"></div>
                      </div>
                    </div>
                    <div className="onlyme-select-info">
                      <div
                        className="wrapper"
                        onClick={() => handleCheckmarkClick(ONLY_WITH_ME)}
                      >
                        <span className="title">Only me</span>
                        <p className="sub-text">Only me</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/*Share to group */}
            {!showPrivacy && (
              <div className="share-group-wrapper">
                <div className="share-group">
                  <p className="text">Share your post on groups</p>
                </div>
                <div
                  className="group-select"
                  onClick={() => setHandlePrivacyOptions("ShareGroup")}
                >
                  <div className="group-check">
                    <input
                      type="radio"
                      id="contact-check3"
                      aria-label="Check this box"
                      checked={sharedGroups?.length > 0}
                    />
                    {/* <span
                    className="checkmark"
                    onClick={() => handleCheckmarkClick(GROUP)}
                  /> */}
                    <div
                      className="checkmark"
                      role="radio"
                      tabIndex="0"
                      onClick={() => handleCheckmarkClick(GROUP)}
                    >
                      <div className="offRadio radio-cmn"></div>
                      <div className="onRadio radio-cmn"></div>
                    </div>
                  </div>

                  <div
                    className="group-select-info"
                    onClick={() => handleCheckmarkClick(GROUP)}
                  >
                    <div className="wrapper">
                      <span className="title">Groups</span>
                      {/* <p className="sub-text">
                        {countGetGroup >= 1 && (
                          <>
                            <span>{`${groupsArray[0]?.GroupId.groupName}`}</span>
                            {groupsArray[1]?.GroupId.groupName !==
                              undefined && (
                              <span>{` and ${groupsArray[1]?.GroupId.groupName} `}</span>
                            )}
                          </>
                        )}
                        {countGetGroup >= 3 &&
                          `and ${countGetGroup - 2} others`}
                        {(countGetGroup === 0 || countGetGroup === undefined) &&
                          `Select your groups`}
                      </p> */}

                      <p className="sub-text">
                        {groupCounts >= 1 && (
                          <>
                            {`${sharedGroupsList[0]?.GroupId.groupName}`}
                            {sharedGroupsList[1]?.GroupId.groupName !==
                              undefined &&
                              ` and ${sharedGroupsList[1]?.GroupId.groupName}`}
                          </>
                        )}
                        {groupCounts >= 3 && ` and ${groupCounts - 2} others`}
                        {(groupCounts === 0 || groupCounts === undefined) &&
                          `Select your groups`}
                      </p>
                    </div>
                    <div className="btn btn-more">
                      <svg
                        width="10"
                        height="16"
                        viewBox="0 0 10 16"
                        fill="none"
                      >
                        <title>More info</title>
                        <path
                          id="Vector"
                          d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                          fill="#C5C5C5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* // visibility */}
            {/* <div
              className="visibility-group-wrapper"
              onClick={() => setHandlePrivacyOptions("PostVisibility")}
            >
              <div className="visibility-group">
                <p className="text">Post visibility</p>
              </div>
              <div className="visibility-select">
                <div className="visibility-select-info">
                  <div className="wrapper">
                    <span className="title">Post visibility</span>
                    <p className="sub-text">
                      Post will be visible only for {getVisibility} days
                    </p>
                  </div>
                  <div className="btn btn-more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More info</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div> */}

            {/* // Privacy Settings */}
            <div className="privacy-settings">
              {!showPrivacy && (
                <div className="privacy-settings-info">
                  <p className="text">
                    Changes to your privacy settings won’t affect post updates
                    that you've already uploaded.
                  </p>
                </div>
              )}

              <div className="privacy-cta">
                {/* Turn on Reposting */}
                <div className="reposting-settings">
                  <div className="reposting-info">
                    <span className="title">Turn on RePosting</span>
                    <p className="sub-text">Viewers can repost your post</p>
                  </div>
                  <div className="toggle-switch reposting-switch">
                    <div
                      className={`off ${getRePosting ? "toggled" : ""}`}
                      onClick={() => toggleMultiple(RE_POSTING)}
                      role="button"
                      tabIndex="0"
                      aria-label="Turn On Reposting"
                    >
                      <div className="on" />
                    </div>
                  </div>
                </div>

                {/* Turn off Comment */}
                <div className="comment-settings">
                  <div className="comment-info">
                    <span className="title">Turn off comment</span>
                    <p className="sub-text">
                      You can also change this on your post
                    </p>
                  </div>
                  <div className="toggle-switch comment-switch">
                    <div
                      className={`off ${getOffComment ? "toggled" : ""}`}
                      onClick={() => toggleMultiple(OFF_COMMENT)}
                      role="button"
                      tabIndex="0"
                      aria-label="Turn Off Comment"
                    >
                      <div className="on" />
                    </div>
                  </div>
                </div>

                {/* Can Reply Post */}
                <div className="replyPost-settings">
                  <div className="replyPost-info">
                    <span className="title">Can reply</span>
                    <p className="sub-text">Viewers can reply your post</p>
                  </div>
                  <div className="toggle-switch replyPost-switch">
                    <div
                      className={`off ${getCanReplyPost ? "toggled" : ""}`}
                      onClick={() => toggleMultiple(CAN_REPLY_POST)}
                      role="button"
                      tabIndex="0"
                      aria-label="Can Reply Post"
                    >
                      <div className="on" />
                    </div>
                  </div>
                </div>

                {/* Can Share Post */}
                {showPrivacy && (
                  <div className="sharePost-settings">
                    <div className="sharePost-info">
                      <span className="title">Can share</span>
                      <p className="sub-text">Viewers can share your post</p>
                    </div>
                    <div className="toggle-switch replyPost-switch">
                      <div
                        className={`off ${getCanSharePost ? "toggled" : ""}`}
                        onClick={() => toggleMultiple(CAN_SHARE_POST)}
                        role="button"
                        tabIndex="0"
                        aria-label="Can Share Post"
                      >
                        <div className="on" />
                      </div>
                    </div>
                  </div>
                )}
                {!showPrivacy && (
                  <div className="privacy-settings-info note">
                    <p className="text">
                      Note: You can not add or edit post caption while repost
                      others post due to Tepnot policy guidelines.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Privacy;
