import { React, useState, useEffect } from "react";
import { ERROR } from "../../../Utils/enums";
import axiosRequest from "../../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import {
  handleToast,
  setSettings,
  setSelectedSetting,
} from "../../../Redux/actions";
import "../../../assets/css/storyPrivacy.scss";

function StoryPrivacy() {
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);

  const [isMounted, setIsMounted] = useState(true);

  const handleBackButton = () => {
    setIsMounted(false);
    dispatch(setSelectedSetting("privacy"));
  };

  useEffect(() => {
    const element = document.querySelector(".storyPrivacy");

    const handleAnimationEnd = () => {
      if (!isMounted) {
      }
    };

    if (isMounted) {
      element.classList.add("open");
      element.classList.remove("close");
      element.removeEventListener("animationend", handleAnimationEnd);
    } else {
      element.classList.add("close");
      element.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      element.classList.remove("open", "close");
      element.removeEventListener("animationend", handleAnimationEnd);
    };
  }, [isMounted]);

  const PrivacySettings = (key, value) => {
    const data = { [key]: value };
    axiosRequest
      .put("/user/setting/privacy", data)
      .then((response) => {
        dispatch(setSettings(response?.data?.data));
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  return (
    <div className="storyPrivacy">
      <div className="storyPrivacy-wrapper">
        <header className="header">
          <div className="back">
            <div
              className="btn btn-back"
              aria-label="Back"
              role="button"
              tabIndex="0"
              onClick={() => handleBackButton()}
            >
              <svg width="16" height="16" viewBox="0 0 26 26" fill="none">
                <title>Back</title>
                <g id="Group 18522">
                  <g id="Group 18522_2">
                    <path
                      id="Vector"
                      d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                      stroke="white"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span className="text">Story privacy</span>
          </div>
        </header>

        <div className="story-visible">
          <h3 className="title">Who can see my stories</h3>

          <div className="visible-to-contacts">
            <div className="contact-select all-contacts">
              <div className="group-check">
                <input
                  type="radio"
                  id="contact-check"
                  aria-label="Check this box"
                />
                {/* <span
              className="checkmark"
              onClick={() => handleCheckmarkClick(CONTACT_ALL)}
            ></span> */}
                <div className="checkmark" role="radio" tabIndex="0">
                  <div className="offRadio radio-cmn"></div>
                  <div className="onRadio radio-cmn"></div>
                </div>
              </div>
              <div className="contact-select-info">
                <div className="wrapper">
                  <span className="title">My all contacts</span>
                  <p className="sub-text">Anyone on your contacts</p>
                </div>
              </div>
            </div>

            <div className="contact-select my-contacts">
              <div className="group-check">
                <input
                  type="radio"
                  id="contact-check"
                  aria-label="Check this box"
                />
                {/* <span
              className="checkmark"
              onClick={() => handleCheckmarkClick(CONTACT_EXCEPT)}
            ></span> */}
                <div className="checkmark" role="radio" tabIndex="0">
                  <div className="offRadio radio-cmn"></div>
                  <div className="onRadio radio-cmn"></div>
                </div>
              </div>
              <div className="contact-select-info">
                <div className="wrapper">
                  <span className="title">My contact except...</span>
                  <p className="sub-text">Choose who to hide your post from</p>
                </div>
                <div className="excluded">
                  <span className="excluded-info">Excluded</span>
                  <div className="btn btn-more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>More info</title>
                      <title>See Excluded Contacts</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-select only-share">
              <div className="group-check">
                <input
                  type="radio"
                  id="contact-check"
                  aria-label="Check this box"
                />
                {/* <span
              className="checkmark"
              onClick={() => handleCheckmarkClick(ONLY_SHARE_WITH)}
            ></span> */}

                <div className="checkmark" role="radio" tabIndex="0">
                  <div className="offRadio radio-cmn"></div>
                  <div className="onRadio radio-cmn"></div>
                </div>
              </div>
              <div className="contact-select-info">
                <div className="wrapper">
                  <span className="title">Only share with</span>
                  <p className="sub-text">Seen only on share with</p>
                </div>
                <div className="excluded">
                  <span className="excluded-info">Included</span>
                  <div className="btn btn-more">
                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <title>See Excluded Contacts</title>
                      <path
                        id="Vector"
                        d="M9.9956 7.7099C9.9956 7.89425 9.96037 8.07307 9.88991 8.24635C9.81946 8.41964 9.72551 8.56343 9.60808 8.67773L3.12596 15.0378C2.86762 15.2912 2.53881 15.418 2.13955 15.418C1.74029 15.418 1.41149 15.2912 1.15314 15.0378C0.894797 14.7843 0.765625 14.4617 0.765625 14.0699C0.765625 13.6782 0.894797 13.3556 1.15314 13.1021L6.64885 7.7099L1.15314 2.31771C0.894797 2.06423 0.765624 1.74162 0.765624 1.34988C0.765624 0.958138 0.894797 0.63553 1.15314 0.38205C1.41149 0.128571 1.74029 0.00183006 2.13955 0.00183004C2.53881 0.00183002 2.86762 0.128571 3.12596 0.38205L9.60808 6.74207C9.749 6.88033 9.84905 7.03011 9.90823 7.19142C9.96742 7.35272 9.99654 7.52555 9.9956 7.7099Z"
                        fill="#C5C5C5"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="storyPrivacy-info">
            <p className="info">
              Changes to your privacy settings won't affect stories that you've
              already sent.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoryPrivacy;
